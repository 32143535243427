import {
  Alert,
  CircularProgress,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material'
import React from 'react'

export type ApAlertEvent = {
  message: string
}
export type ApAlertEventHandler = (event: ApAlertEvent) => void

export type ApAlertProps = {
  open?: boolean
  message: string
  type?: string
  onClose?: () => void
  autoHideDuration?: number | null
}

type AlertType = 'success' | 'info' | 'error' | 'warning' | 'loading'

export function ApAlert({
  open = false,
  message,
  type = 'success',
  onClose = () => undefined,
  autoHideDuration = 6000,
}: ApAlertProps): JSX.Element {
  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
  }

  const alertType = type as AlertType

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={onClose}
    >
      {alertType === 'loading' ? (
        <Alert
          onClose={onClose}
          icon={<CircularProgress size="1rem" sx={{ marginTop: '3px' }} />}
          severity={'info'}
        >
          {message}
        </Alert>
      ) : (
        <Alert onClose={onClose} severity={alertType}>
          {message}
        </Alert>
      )}
    </Snackbar>
  )
}

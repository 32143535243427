import axios from 'axios'

import config from '../../config.json'
import { ConfirmPassword, ConfirmResetPassword } from './types'

export interface AdminClientProps {
  baseUrl: string
}

export class AdminClient {
  private readonly baseUrl: string
  constructor(props: AdminClientProps) {
    this.baseUrl = props.baseUrl
  }

  async confirmUser(
    username: string,
    confirmPasswordFields: ConfirmPassword
  ): Promise<void> {
    const response = await axios({
      url: `https://admin.api.${this.baseUrl}/v1/user/${username}/confirm`,
      method: 'POST',
      data: confirmPasswordFields,
    })
    if (response.status !== 204) {
      throw new Error('Unable to confirm user')
    }
  }
  async resetUserPassword(username: string): Promise<void> {
    const response = await axios({
      url: `https://admin.api.${this.baseUrl}/v1/user/${username}/reset-password`,
      method: 'POST',
    })
    if (response.status !== 204) {
      throw new Error('Unable to reset user')
    }
  }

  async confirmResetUserPassword(
    username: string,
    confirmResetPasswordFields: ConfirmResetPassword
  ): Promise<void> {
    const response = await axios({
      url: `https://admin.api.${this.baseUrl}/v1/user/${username}/confirm-reset-password`,
      method: 'POST',
      data: confirmResetPasswordFields,
    })
    if (response.status !== 204) {
      throw new Error('Unable to confirm password reset')
    }
  }
}

export function createAdminClient() {
  return new AdminClient({ baseUrl: config.hostedZoneName })
}

import { LoadingButton } from '@mui/lab'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PortalClient } from '../../../client/portal-client'
import { colors } from '../../../theme/'
import { InputField, LoginHeader, RootGrid, StyledGridItem } from './styles'
import { ForgotPasswordFormFields, validate } from './validate'

export default function ForgotPasswordForm(): JSX.Element {
  const navigate = useNavigate()

  const [sendLinkPressed, setSendLinkPressed] = useState(false)

  const client = new PortalClient()

  const onSubmit = async ({ username }: ForgotPasswordFormFields) => {
    try {
      await client.sendResetPasswordEmail(username)
    } catch (err) {
      //TODO: prompt proper error message
    } finally {
      setSendLinkPressed(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
    } as ForgotPasswordFormFields,
    onSubmit,
    validate,
    validateOnChange: false,
    validateOnBlur: false,
  })

  return (
    <RootGrid container>
      <StyledGridItem item xs={12}>
        <img
          src="/ApplyProof_logo.svg"
          alt="applyproof-logo"
          width="196px"
          height="37px"
        />
        <LoginHeader variant="h4">
          {sendLinkPressed ? 'Check your Email' : 'Forgot Password'}
        </LoginHeader>
        <Typography variant="body2">
          {sendLinkPressed
            ? 'We have sent a password reset link to your email with instructions.'
            : 'Enter the email associated with your account and we will send you an email with a link to reset your password.'}
        </Typography>
      </StyledGridItem>
      <StyledGridItem item xs={12}>
        {sendLinkPressed ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: '40px', flexDirection: 'column' }}
              container
              justifyContent="flex-start"
            >
              <Typography variant="body2">
                {
                  "Didn't receive the email? Make sure to check your spam folder "
                }
                <Link href="/password/forgot">
                  or try another email address.
                </Link>
              </Typography>
              <Grid item xs={6}>
                <Button
                  style={{ marginTop: '40px', padding: '8px 16px' }}
                  variant="outlined"
                  onClick={() => setSendLinkPressed(false)}
                >
                  Resend Link
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label="Email"
                  id="username"
                  name="username"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                />
                {formik.errors.username ? (
                  <Typography
                    component="p"
                    variant="caption"
                    style={{ color: colors.accent.red.dark }}
                  >
                    {formik.errors.username}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  style={{ marginTop: '40px' }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={4}>
                <LoadingButton
                  style={{ marginTop: '40px' }}
                  type="submit"
                  loading={formik.isSubmitting}
                  loadingIndicator={
                    <CircularProgress color="primary" size={16} />
                  }
                >
                  Send Link
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </StyledGridItem>
    </RootGrid>
  )
}

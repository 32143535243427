import dayjs from 'dayjs'
import validator from 'email-validator'
import * as yup from 'yup'

import { config } from '../../../config'
import { StudentInfo } from '../../../models/student-info'

export type FormFields = StudentInfo

const expiryDate = dayjs(Date.now()).add(
  parseInt(config.maximumExpiryYears),
  'year'
)

const validateEmailArray = (values: string[] | undefined) => {
  if (values === undefined) return false
  for (let i = 0; i < values.length; i++) {
    if (!validator.validate(values[i])) return false
  }
  return true
}

export const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Enter first name'),
  lastName: yup.string().min(0).max(50, 'Too Long!').default(''),
  email: yup.string().email('Invalid email').required('Enter Student Email'),
  emailCc: yup
    .array()
    .test('valid-email', 'Invalid Email', validateEmailArray)
    .optional(),
  emailBcc: yup
    .array()
    .test('valid-email', 'Invalid Email', validateEmailArray)
    .optional(),
  studentId: yup
    .string()
    .matches(
      /^[A-Za-z0-9_-]+$/,
      'Student or Applicant ID must only contain alphanumeric characters, underscore and dash'
    )
    .max(32, 'Enter Student or Applicant ID with less than 32 characters')
    .required('Enter Student or Applicant ID'),
  dateOfBirth: yup
    .date()
    .min(dayjs('1900-01-01').toDate(), 'Date of Birth must be after 1900/01/01')
    .max(
      dayjs(Date.now()).subtract(10, 'year').toDate(),
      `Date of Birth must be before ${dayjs()
        .subtract(10, 'year')
        .format('YYYY/MM/DD')}`
    )
    .required('Enter Date of Birth')
    .typeError('Invalid date'),
  expiryDate: yup
    .date()
    .min(dayjs(Date.now()).toDate(), 'Expiry Date must be after today')
    .max(
      expiryDate.toDate(),
      `Expiry Date must be before ${expiryDate.format('YYYY/MM/DD')}`
    )
    .optional()
    .typeError('Invalid expiry date'),
  emailRecipients: yup
    .string()
    .min(3, 'Must contain atleast one email address')
    .max(999999999, '')
    .optional(),
  documentAttachedCount: yup
    .number()
    .min(1, 'You must upload a document to ApplyProof')
    .required('You must upload a document to ApplyProof'),
  reviewedDocuments: yup
    .bool()
    .isTrue('Please check the box after you’ve reviewed the document')
    .required('Please check the box after you’ve reviewed the document'),
})

/**
 * Handle onChange events for formik on
 * <AutoComplete /> control
 *
 * @param formik The formik instance
 * @param fieldName The name of the field
 */
export function autoCompleteChangeHandler(formik: any, fieldName: string) {
  return (
    event: React.SyntheticEvent<Element, Event>,
    value: (string | string[])[]
  ): void => {
    if (Array.isArray(value)) {
      formik.setFieldValue(fieldName, value)
    } else {
      formik.setFieldValue(fieldName, [value])
    }
  }
}

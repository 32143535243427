import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import React from 'react'

import { ConfirmButton } from './styles'

type InvalidateDialogProps = {
  open: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

export default function InvalidateDialog({
  open,
  onConfirm,
  onCancel,
}: InvalidateDialogProps): JSX.Element {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <img
          alt="invalidate document icon"
          width={50}
          height={62}
          src="/Invalidate_document_icon.svg"
        />
        <DialogContentText id="alert-dialog-description">
          Invalidating will revoke access to the document on ApplyProof
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <ConfirmButton onClick={onConfirm} autoFocus>
          Yes, Invalidate
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
}

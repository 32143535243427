import { styled, Typography, Box } from '@mui/material'

export const EmailStatusBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'end',
})
export const Email = styled(Typography)({
  fontSize: 16,
  wordWrap: 'break-word',
})

export const EmailStatusIcon = styled('img')({
  marginLeft: '10px',
  height: 20,
  width: 20,
})

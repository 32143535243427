import { styled, Container, Grid } from '@mui/material'

import { theme } from '../../../theme/'

export const RootContainer = styled(Container)({
  [theme.breakpoints.up('sm')]: {
    paddingTop: '20vh',
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: 0,
    padding: '3rem',
  },
})

export const ResetPasswordFormBox = styled(Grid)({
  marginBottom: 64,
  [theme.breakpoints.up('sm')]: {
    minWidth: 530,
  },
  [theme.breakpoints.down('xs')]: {
    margin: '3rem',
  },
})

export const IllustrationBox = styled(Grid)({
  padding: '24px',
  textAlign: 'center',
})

export const Illustration = styled('img')({
  width: 489,
  height: 548,
  maxWidth: '100%',
})

import { ArrowRightAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { useUser } from '../../../client/auth'
import {
  createSettingsClient,
  DocumentType,
  DocumentTypes,
  getApDocumentTypeLabels,
} from '../../../client/settings-client'
import ApTile from '../ap-tile'
import {
  RootBox,
  ApDivider,
  DocumentTypeTitleBox,
  DocumentTypeFormBox,
  ApplyProofMethodBox,
  NextButtonBox,
} from './styles'

type DocumentTypeAndLabel = {
  title: string
  id: string
}

export default function UploadPrePage(): JSX.Element {
  const user = useUser()
  const navigate = useNavigate()
  const [documentType, setDocumentType] = useState<
    DocumentTypeAndLabel | undefined
  >(undefined)
  const [refreshInterval, setRefreshInterval] = useState(100)
  // TODO: replace with a new TileRadioGroup component
  const [selectedTile, setSelectedTile] = useState('manual')

  if (user === null) {
    navigate('/', { replace: true })
  }

  const client = createSettingsClient()
  const key = user ? '/api/documentTypes' : null
  const { data, error } = useSWR(
    key,
    async () => {
      if (user) {
        try {
          const documentTypes: DocumentTypes = await client.getDocumentTypes()
          const formattedTypes = documentTypes.documentTypes.map(
            (docType: DocumentType) => {
              const webType: DocumentTypeAndLabel = {
                id: docType,
                title: `${getApDocumentTypeLabels(docType)} (${docType})`,
              }
              return webType
            }
          )
          if (documentType === undefined) {
            setDocumentType(formattedTypes[0])
          }
          return formattedTypes
        } catch (err) {
          console.debug('Unable to get document types')
          throw err
        }
      } else {
        throw new Error('no user')
      }
    },
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: refreshInterval,
      dedupingInterval: 0,
    }
  )

  const manualClicked = () => {
    if (selectedTile !== 'manual') {
      setSelectedTile('manual')
    } else {
      setSelectedTile('')
    }
  }

  const nextClicked = () => {
    if (documentType !== undefined) {
      console.log(documentType)
      navigate(`/upload/${documentType.id}`)
    }
  }

  const onDocumentTypeChanged = (
    _e: React.SyntheticEvent<Element, Event>,
    value: DocumentTypeAndLabel | undefined | null
  ) => {
    if (value) {
      setDocumentType(value)
    }
  }

  const isLoading = Boolean(!data && !error)
  if (
    data !== undefined &&
    documentType !== undefined &&
    refreshInterval !== 0
  ) {
    setRefreshInterval(0)
  }
  return (
    <RootBox>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        style={{ minHeight: 'calc(90vh - 116px)' }}
      >
        <Grid item sm={10} md={5} lg={4}>
          <Box>
            <Typography variant="h1" component="h1">
              ApplyProof Document
            </Typography>
            <ApDivider />
            <DocumentTypeTitleBox>
              <Typography variant="h3" component="h3">
                Select Document Type
              </Typography>
            </DocumentTypeTitleBox>
            <DocumentTypeFormBox>
              {!isLoading && documentType ? (
                <Autocomplete
                  placeholder="Document Type"
                  value={documentType}
                  onChange={onDocumentTypeChanged}
                  options={data || []}
                  loading={isLoading}
                  getOptionLabel={(option: DocumentTypeAndLabel) =>
                    option.title
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  style={{ width: 350 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Document Type"
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <Skeleton
                  style={{ marginTop: 16, width: 350 }}
                  variant="text"
                  animation="wave"
                />
              )}
            </DocumentTypeFormBox>
            <ApplyProofMethodBox>
              <Typography variant="h3" component="h3">
                Select ApplyProof Method
              </Typography>
            </ApplyProofMethodBox>
            <Box>
              <ApTile
                highlight={selectedTile === 'manual'}
                icon={
                  <img
                    alt="add student icon"
                    src="/add_student_icon.svg"
                    width={38}
                    height={40}
                  />
                }
                title="Enter student info manually"
                description="ApplyProof a document for a single student and have the document emailed to selected recipients."
                onClick={manualClicked}
              />
            </Box>
            <NextButtonBox>
              <Button
                type="submit"
                endIcon={<ArrowRightAlt />}
                size="large"
                onClick={nextClicked}
              >
                Next
              </Button>
            </NextButtonBox>
          </Box>
        </Grid>
      </Grid>
    </RootBox>
  )
}

import { Button } from '@mui/material'

import React from 'react'
import { FileError } from '../../errors'
import { EventTracker } from '../../lib/metrics'
import {
  getStatusCounts,
  read,
  verify,
  writeAndDownload,
} from '../../services/ircc-workflow'
import ApAlert from '../ap-alert'
import { ApAlertProps } from '../ap-alert/apAlert'
import { FileUploadModal } from './file-upload-modal'

export default function GenerateIRCCReportButton(): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const [alert, setAlert] = React.useState<ApAlertProps>({
    open: false,
    message: 'Generating IRCC Matches...',
    type: 'loading',
    autoHideDuration: null,
  })

  const handleAlertClose = () => {
    setAlert({
      open: false,
      message: alert.message,
      type: alert.type,
      autoHideDuration: null,
    })
  }

  const handleOpen = () => {
    EventTracker.trackGenerateIrccMatchesButtonClicked()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleGenerateResults = async (file: File) => {
    const startTime = new Date()
    setOpen(false)
    setAlert({
      open: true,
      message: 'Generating IRCC Matches...',
      type: 'loading',
      autoHideDuration: null,
    })

    try {
      const data = await file.arrayBuffer()
      const workbook = await read(data)
      const countStatusRequest = getStatusCounts(workbook.worksheet)
      const verifiedFile = await verify(workbook)

      const countStatusResponse = getStatusCounts(verifiedFile.worksheet)
      await writeAndDownload(verifiedFile.workbook, file.name)

      EventTracker.trackIrccMatchesSuccess(
        startTime,
        countStatusRequest,
        countStatusResponse
      )
      setAlert({
        message:
          'Success: IRCC match results are ready and can be found in your browser downloads.',
        type: 'success',
        autoHideDuration: 6000,
        open: true,
      })
    } catch (err) {
      if (err instanceof FileError) {
        setAlert({
          open: true,
          message: 'Failed to generate IRCC Matches. ' + err.message,
          type: 'error',
          autoHideDuration: 10000,
        })
      } else {
        setAlert({
          open: true,
          message: 'Failed to generate IRCC Matches.',
          type: 'error',
          autoHideDuration: 10000,
        })
      }

      EventTracker.trackIrccMatchesFailed((err as Error).message)
    }
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={
          <img src="/Document_check_icon.svg" alt="Document check icon" />
        }
      >
        Generate IRCC Matches
      </Button>
      <FileUploadModal
        open={open}
        handleClose={handleClose}
        handleGenerateResults={handleGenerateResults}
      />
      <ApAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
        onClose={handleAlertClose}
        autoHideDuration={alert.autoHideDuration}
      />
    </>
  )
}

import React, { useEffect } from 'react'

import ResetPasswordPage from '../../components/reset-password/reset-password-page'
import { ResetPasswordBox } from './styles'

export default function RootResetPasswordPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Reset Password'
  }, [])
  return (
    <ResetPasswordBox>
      <ResetPasswordPage confirmSignup={false} />
    </ResetPasswordBox>
  )
}

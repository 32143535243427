import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { useUser } from '../../../client/auth'
import { TOTAL_UPLOAD_MAX_SIZE } from '../../../client/origination-client'
import { PdfExtractionDefinition } from '../../../client/portal-client'
import {
  ApDocumentTypes,
  DocumentType,
  createSettingsClient,
} from '../../../client/settings-client'
import { DocumentUpload } from '../../../models/document-upload'
import {
  PdfExtractionValues,
  PdfValueExtractor,
} from '../../../services/pdf-extract'
import DocumentViewer from '../../document-viewer'
import DocumentDropzone from '../document-dropzone'
import DocumentTabs from '../document-tabs'
import { DocumentTabsBox, DocumentBox } from './styles'

export type DocumentUploadPreview = {
  documentType: DocumentType
  dropzoneError: boolean
  onDocumentsChanged: (documents: Array<DocumentUpload>) => void
  onDocumentValuesExtracted?: (values: PdfExtractionValues) => void | undefined
}

export default function DocumentUploadPreview({
  documentType,
  dropzoneError,
  onDocumentsChanged,
  onDocumentValuesExtracted,
}: DocumentUploadPreview): JSX.Element {
  const navigate = useNavigate()
  const user = useUser()
  if (user === null) {
    navigate('/', { replace: true })
  }
  const [documents] = useState<DocumentUpload[]>([])
  const [definitions, setDefinitions] = useState<
    Array<PdfExtractionDefinition> | undefined
  >(undefined)
  const [visibleDocument, setVisibleDocument] = useState<DocumentUpload>()
  const [fileTooLargeDialogOpen, setFileTooLargeDialogOpen] = useState(false)
  const [uploadTooLargeDialogOpen, setUploadTooLargeDialogOpen] =
    useState(false)

  const client = createSettingsClient()

  const key =
    user && onDocumentValuesExtracted
      ? `/pdf-extract-definitions?documentType=${documentType}`
      : null
  useSWR(
    key,
    async () => {
      if (user) {
        const extractionDefinitions = await client.getPdfExtractionDefinitions(
          documentType
        )
        if (extractionDefinitions.length > 0) {
          setDefinitions(extractionDefinitions)
        } else {
          throw new Error('No extraction defintions')
        }
      } else {
        throw new Error('no user')
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  )

  const handleAddDocument = async (document: DocumentUpload) => {
    const totalUploadSize =
      documents.length > 0
        ? documents
            .map((d) => d.size)
            .reduce((total, documentSize) => total + documentSize)
        : 0
    /**
     * Compare total size of files before uploading
     */
    if (totalUploadSize + document.size >= TOTAL_UPLOAD_MAX_SIZE) {
      setFileTooLargeDialogOpen(true)
    } else {
      documents.push(document)
      setVisibleDocument(document)

      if (definitions && document.objectUrl && onDocumentValuesExtracted) {
        const pdfValueExtractor = new PdfValueExtractor()
        const values = await pdfValueExtractor.extract(
          document.objectUrl,
          definitions
        )
        onDocumentValuesExtracted(values)
      }

      onDocumentsChanged(documents)
    }
  }

  const handleDocumentTabClick = (document: DocumentUpload) => {
    setVisibleDocument(document)
  }

  const handleCloseUploadTooLargeDialog = () => {
    setUploadTooLargeDialogOpen(false)
  }

  const handleCloseFileTooLargeDialog = () => {
    setFileTooLargeDialogOpen(false)
  }

  const handleRemoveDocument = (document: DocumentUpload) => {
    const indexOf = documents.findIndex((v) => v.name === document.name)
    if (indexOf >= 0) {
      documents.splice(indexOf, 1)
    }

    if (documents.length === 0) {
      setVisibleDocument(undefined)
    } else if (indexOf > 0) {
      setVisibleDocument(documents[indexOf - 1])
    } else {
      setVisibleDocument(documents[0])
    }

    onDocumentsChanged(documents)
  }

  return (
    <>
      <DocumentBox>
        {visibleDocument ? (
          <DocumentViewer document={visibleDocument} />
        ) : (
          <DocumentDropzone
            error={dropzoneError}
            onDocumentUploaded={handleAddDocument}
          />
        )}
      </DocumentBox>
      <DocumentTabsBox>
        <DocumentTabs
          selectedDocument={visibleDocument}
          documents={documents}
          onDocumentAdded={handleAddDocument}
          onDocumentClicked={handleDocumentTabClick}
          onDocumentRemoved={handleRemoveDocument}
        />
      </DocumentTabsBox>
      <Dialog
        open={fileTooLargeDialogOpen}
        onClose={() => handleCloseFileTooLargeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Your file is too large
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cannot upload files larger than 5MB
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseFileTooLargeDialog()}
            color="primary"
            autoFocus
            data-cy="dismiss"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={uploadTooLargeDialogOpen}
        onClose={() => handleCloseUploadTooLargeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Your file is too large
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can upload files up to a maximum of 5MB.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseUploadTooLargeDialog()}
            color="primary"
            autoFocus
            data-cy="dismiss"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import { styled, Box, Typography, BoxProps } from '@mui/material'

import { colors } from '../../theme/color'

type ColoredButtonProps = BoxProps & {
  buttonColor: 'blue' | 'green' | 'yellow'
}
export const RootBox = styled(Box)({
  cursor: 'pointer',
  display: 'inline-block',
  minWidth: 150,
  textAlign: 'start',
})

export const Content = styled(Box)({
  userSelect: 'none',
})

export const Title = styled(Typography)({
  fontWeight: 'inherit',
  color: 'inherit',
  fontSize: 14,
})

export const Subtitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 'inherit',
  userSelect: 'all',
  color: colors.text.noclue,
})

export const ColoredButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'buttonColor',
})<ColoredButtonProps>(({ buttonColor }) => ({
  fontWeight: 600,
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  outline: 0,
  border: 1,
  width: '100%',
  color:
    buttonColor === 'green'
      ? colors.accent.green.light
      : buttonColor === 'yellow'
      ? colors.accent.yellow.dark
      : colors.primary.light,
  borderColor:
    buttonColor === 'green'
      ? colors.accent.green.light
      : buttonColor === 'yellow'
      ? colors.accent.yellow.dark
      : colors.primary.light,
  borderStyle: 'solid',
  borderRadius: 4,
  padding: '8px 1.15rem',
  '&:focus': {
    // TODO: Display indication of focus
  },
}))

import { Box, Divider, styled } from '@mui/material'

import { colors } from '../../../theme/color'

export const RootBox = styled(Box)({
  height: 'calc(100vh - 64px)',
  fallbacks: [
    '-moz-calc(100vh - 64px)',
    '-webkit-calc(100vh - 64px)',
    '-o-calc(100vh - 64px)',
  ],
  paddingTop: 116,
  backgroundColor: colors.background.gray,
  overflowY: 'auto',
})

export const ApDivider = styled(Divider)({
  marginTop: 24,
})

export const DocumentTypeTitleBox = styled(Box)({
  marginTop: 24,
  marginBottom: 32,
})

export const DocumentTypeFormBox = styled(Box)({
  marginTop: '1.5rem',
})

export const ApplyProofMethodBox = styled(Box)({
  marginTop: 62,
  marginBottom: 32,
})

export const NextButtonBox = styled(Box)({
  marginTop: 30,
  textAlign: 'end',
})

import { Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  RootBox,
  LogoGrid,
  Content,
  Title,
  Body,
  SendAnotherLinkButton,
} from './styles'

export default function ErrorPage(): JSX.Element {
  const navigate = useNavigate()

  return (
    <>
      <RootBox
        sx={{
          boxShadow: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '10vh',
        }}
      >
        <Grid container>
          <LogoGrid item xs={4}>
            <img
              alt="applyproof logo"
              width={196}
              height={37}
              src="/ApplyProof_logo.svg"
            />
          </LogoGrid>
          <Grid item xs={8}></Grid>
        </Grid>
      </RootBox>
      <Content
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <img
            alt="Error"
            width={525}
            height={399}
            src="/Reset_Password_Expired.svg"
          />
        </Grid>
        <Grid item xs={12}>
          <Title>404 Page not found</Title>
        </Grid>
        {/* <Grid item xs={12}>
          <Body>This page doesn't exist!</Body>
        </Grid> */}
        <Grid xs={12}></Grid>
        <Grid xs={12}>
          <SendAnotherLinkButton
            onClick={() => {
              navigate('/')
            }}
          >
            Return to homepage
          </SendAnotherLinkButton>
        </Grid>
      </Content>
    </>
  )
}

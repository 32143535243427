import React, { useEffect } from 'react'

import ErrorPage from '../../components/error-page'

export default function RootErrorPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - 404'
  }, [])
  return (
    <>
      <ErrorPage />
    </>
  )
}

import { styled } from '@mui/material'
import { Grid, Typography, Box, TextField } from '@mui/material'

import { colors } from '../../../theme'

export const RootGrid = styled(Grid)({
  borderRadius: 8,
  padding: '1.8rem 2.0rem 1.0rem',
  backgroundColor: colors.background.white,
  boxShadow: `0px 3px 20px ${colors.boxShadow.default}`,
  color: colors.text.paragraph,
  border: 1,
  borderStyle: 'solid',
  borderColor: colors.border.solid,
})

export const StyledGridItem = styled(Grid)({
  padding: '24px',
})

export const LoginHeader = styled(Typography)({
  marginTop: 48,
  marginBottom: 8,
})

export const ForgetPasswordBox = styled(Box)({
  textAlign: 'end',
})

export const TextInputField = styled(TextField)({
  marginBottom: '0.6rem',
})

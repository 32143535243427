import { styled, Chip } from '@mui/material'

export const ExpiryChip = styled(Chip)({
  backgroundColor: 'white',
  '&& > svg': {
    marginLeft: 0,
  },
  '.MuiChip-icon': {
    marginLeft: 0,
  },
  '.MuiChip-label': {
    fontWeight: 'normal',
  },
  alignItems: 'normal',
})

export const ExpiryDateIcon = styled('img')({
  height: 18,
  width: 18,
})

import * as yup from 'yup'

export type FormFields = {
  emailEnabled: boolean
  emailSubject: string
  emailCc: Array<string>
  emailBcc: Array<string>
  emailBody: string
}

export const validationSchema = yup.object().shape({
  emailEnabled: yup.boolean().required(),
  emailSubject: yup
    .string()
    .min(2, 'Too Short!')
    .max(256, 'Too Long!')
    .required('Email Subject is required'),
  emailCc: yup
    .array(yup.string().email('Invalid email').required())
    .max(128, 'Only up to 128 CC recipents allowed')
    .required(),
  emailBcc: yup
    .array(yup.string().email('Invalid email').required())
    .max(128, 'Only up to 128 BCC recipents allowed')
    .required(),
  emailBody: yup.string().required(),
})

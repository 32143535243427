import { IconButton } from '@mui/material'
import React from 'react'

import { colors } from '../../../theme'
import { HoverToolTip, HelperText, InfoIcon } from './styles'

type ApToolTipProps = {
  helperText?: string
}
export default function ApToolTip({ helperText }: ApToolTipProps): JSX.Element {
  return (
    <HoverToolTip
      arrow
      placement="right"
      title={
        <React.Fragment>
          <HelperText>{helperText}</HelperText>
        </React.Fragment>
      }
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': {
            '&::before': {
              backgroundColor: colors.background.white,
            },
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: colors.background.white,
            maxWidth: '300px',
            boxShadow: ' 0 4px 14px rgba(0, 0, 0, 0.1)',
          },
        },
      }}
    >
      <IconButton>
        <InfoIcon />
      </IconButton>
    </HoverToolTip>
  )
}

/**
 * This file contains utility functions to compute additional fields on Metadata objects.
 * isExpired
 * emailTo
 * emailCc
 * emailBcc
 * primaryRecipientEmailStatus
 */
import {
  DeliveryType,
  EmailActionStatus,
  GetMetadataResponse,
  GetMetadataResponseWithEmailStatuses,
  Metadata,
  SimplifiedEmailStatus,
} from '../origination-client'

function extractEmailsByDeliveryType(
  actionStatuses: GetMetadataResponse['actionStatuses'],
  deliveryType: DeliveryType
): Array<SimplifiedEmailStatus> {
  const emails: Array<EmailActionStatus> = Object.values(actionStatuses).filter(
    (e) => e.actionType === 'EMAIL'
  ) as Array<EmailActionStatus>

  const emailsByDeliveryType: Array<SimplifiedEmailStatus> = emails
    .filter((e) => e.deliveryType === deliveryType)
    .map((e) => {
      return {
        email: e.email,
        status: e.status,
      }
    })

  return emailsByDeliveryType
}

export function addEmailStatusToMetadata(
  metadata: Metadata
): GetMetadataResponseWithEmailStatuses {
  const actionStatuses = Object.values(metadata.actionStatuses)
  const primaryRecipientEmailActionStatus = actionStatuses.find(
    (e) =>
      e.actionType === 'EMAIL' && e.email === metadata.primaryRecipient.email
  )
    ?.status as GetMetadataResponseWithEmailStatuses['primaryRecipientEmailStatus']

  const now = new Date()
  const expiry = new Date(metadata.expiry)
  const isExpired = expiry < now

  const result: GetMetadataResponseWithEmailStatuses = {
    ...metadata,
    isExpired: isExpired,
    primaryRecipientEmailStatus: primaryRecipientEmailActionStatus,
    emailTo: extractEmailsByDeliveryType(metadata.actionStatuses, 'to'),
    emailCc: extractEmailsByDeliveryType(metadata.actionStatuses, 'cc'),
    emailBcc: extractEmailsByDeliveryType(metadata.actionStatuses, 'bcc'),
  }
  return result
}

export type ApDocumentTypes = 'POA' | 'POFS' | 'POLP' | 'POP' | 'PAL'

export type DocumentType = ApDocumentTypes | string

export const getApDocumentTypeLabels = (docType: string): DocumentType => {
  switch (docType) {
    case 'POA':
      return 'Proof of Acceptance'
    case 'PAL':
      return 'Provincial Attestation Letter'
    case 'POFS':
      return 'Proof of Financial Support'
    case 'POLP':
      return 'Proof of Language Proficiency'
    case 'POP':
      return 'Proof of Payment'
    case 'POE':
      return 'Proof of Enrollment'
    case 'CLOA':
      return 'Conditional Letter of Acceptance'
    case 'POTS':
      return 'Proof of Travel Support'
    case 'POQP':
      return 'Proof of Quarantine Plan'
    default:
      return docType
  }
}
export type Email = string

/**
 * All objects stored in Settings table should extend this type.
 */
export type SettingsBaseObject = {
  pkey: string
}

export type EmailSettings = SettingsBaseObject & {
  enabled: boolean
  subject: string
  body: string
  cc: Array<Email>
  bcc: Array<Email>
}

export type LegacyEmailSettings = {
  emailEnabled: boolean
  emailSubject: string
  emailCc: Array<string>
  emailBcc: Array<string>
  emailBody: string
}

export type GetEmailSettingsParams = {
  accountId: string
}

export type GetEmailSettingsResponse = EmailSettings

export type UpdateEmailSettingsBody = Omit<EmailSettings, 'pkey'>

export type UpdateEmailSettingsParams = {
  accountId: string
  params: UpdateEmailSettingsBody
}

export type UpdateEmailSettingsResponse = EmailSettings

export type DocumentTypes = SettingsBaseObject & {
  documentTypes: Array<DocumentType>
}

export type GetDocumentTypesParams = {
  accountId: string
}

export type GetDocumentTypesResponse = DocumentTypes

export type UpdateDocumentTypesBody = Omit<DocumentTypes, 'pkey'>

export type UpdateDocumentTypesParams = {
  accountId: string
  params: UpdateDocumentTypesBody
}

export type UpdateDocumentTypesResponse = DocumentTypes

export type PartitionKeySuffix = 'documentTypes' | 'emailSettings'

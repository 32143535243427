import { ArrowRightAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box,
} from '@mui/material'
import { fontStyle } from '@mui/system'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useEffect, useRef, Fragment, useState } from 'react'
import { Simulate } from 'react-dom/test-utils'
import { useNavigate } from 'react-router-dom'

import { useUser } from '../../../client/auth'
import { LegacyEmailSettings } from '../../../client/settings-client'
import { StudentInfo } from '../../../models/student-info'
import { PdfExtractionValues } from '../../../services/pdf-extract'
import { colors } from '../../../theme'
import ApToolTip from '../ap-tool-tip'
import { autoCompleteChangeHandler } from '../formik-autocomplete/change-handler'
import {
  FormErrorText,
  ReviewDocumentsCheckbox,
  DocumentAttachedError,
  Content,
  SubmitButtonBox,
  ActionsBox,
  ActionsDivider,
  HeaderDivider,
  HeaderBox,
  DisabledEmailAlert,
  FooterBox,
  EmailInfoTypography,
} from './styles'
import { FormFields, validationSchema } from './validate'

export type StudentDetailsEmail = {
  emailCc?: Array<string> | undefined
  emailBcc?: Array<string> | undefined
}

export type StudentDetailsFormProps = {
  email?: StudentDetailsEmail
  emailSettings?: LegacyEmailSettings
  documentAttachedCount: number
  onSubmit: (studentInfo: StudentInfo) => void
  onAttemptSubmitWithoutDocuments: () => void
  extractionValues?: PdfExtractionValues | undefined
}

export default function UploadForm({
  email,
  emailSettings,
  documentAttachedCount,
  onSubmit: onUpperSubmit,
  onAttemptSubmitWithoutDocuments,
  extractionValues,
}: StudentDetailsFormProps): JSX.Element {
  const navigate = useNavigate()
  const user = useUser()
  const [emailDisabledNotification, setEmailDisabledNotification] =
    useState<boolean>(false)

  const documentAttachedCountRef = useRef<HTMLInputElement>(null)

  if (user === null) {
    navigate('/', { replace: true })
  }

  const onSubmit = async (formFields: FormFields): Promise<void> => {
    onUpperSubmit(formFields)
  }

  const initialValues: FormFields = {
    studentId: '',
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: null,
    expiryDate: dayjs(Date.now()).add(2, 'years').toDate(),
    emailCc: email?.emailCc ?? [],
    emailBcc: email?.emailBcc ?? [],
    reviewedDocuments: false,
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    if (formik.errors.documentAttachedCount) {
      onAttemptSubmitWithoutDocuments()
    }
  }, [formik.errors])

  useEffect(() => {
    if (extractionValues) {
      if (extractionValues.firstName) {
        formik.setFieldValue('firstName', extractionValues.firstName)
      }
      if (extractionValues.lastName) {
        formik.setFieldValue('lastName', extractionValues.lastName)
      }
      if (extractionValues.studentId) {
        formik.setFieldValue('studentId', extractionValues.studentId)
      }
      if (extractionValues.email) {
        formik.setFieldValue('email', extractionValues.email)
      }
      if (extractionValues.dateOfBirth) {
        const dateOfBirth = dayjs(extractionValues.dateOfBirth, {
          utc: false,
        }).toDate()
        formik.setFieldValue('dateOfBirth', dateOfBirth)
      }
      if (extractionValues.expiryDate) {
        const expiryDate = dayjs(extractionValues.expiryDate, {
          utc: false,
        }).toDate()
        formik.setFieldValue('expiryDate', expiryDate)
      }
      if (extractionValues.emailCc) {
        formik.setFieldValue(
          'emailCc',
          formik.values.emailCc
            .filter((s: any) => s !== extractionValues.emailCc)
            .concat(extractionValues.emailCc.split(','))
        )
      }
      if (extractionValues.emailBcc) {
        formik.setFieldValue(
          'emailBcc',
          formik.values.emailBcc
            .filter((s: any) => s !== extractionValues.emailBcc)
            .concat([extractionValues.emailBcc])
        )
      }
    }
  }, [extractionValues])

  const handleBackClick = () => {
    navigate('/upload', { replace: true })
  }

  useEffect(() => {
    if (emailSettings) {
      if (!formik.touched.emailCc) {
        formik.setFieldValue('emailCc', emailSettings.emailCc)
      }
      if (!formik.touched.emailBcc) {
        formik.setFieldValue('emailBcc', emailSettings.emailBcc)
      }
      if (emailSettings.emailEnabled) {
        setEmailDisabledNotification(false)
      } else {
        setEmailDisabledNotification(true)
      }
    }
  }, [emailSettings])

  useEffect(() => {
    /*
     * This is the recommended workaround by formik. To trigger
     * an onChange on a hidden text input.
     */
    const current = documentAttachedCountRef.current
    if (current) {
      Simulate.change(current)
    }
  }, [documentAttachedCount])

  const headerBox = (
    <HeaderBox>
      <Typography component="h1" variant="h2">
        ApplyProof Document
      </Typography>
      <HeaderDivider />
    </HeaderBox>
  )

  const disabledEmailNotification = emailDisabledNotification ? (
    <DisabledEmailAlert
      variant="outlined"
      icon={false}
      severity="info"
      onClose={() => {
        setEmailDisabledNotification(false)
      }}
    >
      Your documents are currently not set up to send an email notification to
      the student when a document has been applyproofed. To make changes, go to
      settings.
    </DisabledEmailAlert>
  ) : undefined

  const formInput = (
    <Fragment>
      <input
        ref={documentAttachedCountRef}
        type="hidden"
        id="documentAttachedCount"
        name="documentAttachedCount"
        value={documentAttachedCount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Grid container spacing={0}>
        <Grid item lg={12}>
          <Typography component="h2" variant="h4" style={{ marginTop: 42 }}>
            Enter Student Info
            <ApToolTip
              helperText="Providing us with student info allows us to create a profile for
              the student and link their information to the document"
            />
          </Typography>
        </Grid>
        <Grid item lg={6} style={{ paddingRight: 16 }}>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.studentId)}
            component="fieldset"
          >
            <TextField
              id="studentId"
              name="studentId"
              type="text"
              label="ID number"
              fullWidth
              error={Boolean(formik.errors.studentId)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.studentId}
              inputProps={{
                maxLength: 32,
              }}
            />
            <FormErrorText>{formik.errors.studentId || ' '}</FormErrorText>
          </FormControl>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={8} sm={8} lg={6} style={{ paddingRight: 16 }}>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.firstName)}
            component="fieldset"
          >
            <TextField
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              fullWidth
              error={Boolean(formik.errors.firstName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              style={{ marginTop: 28 }}
            />
            <FormErrorText>{formik.errors.firstName || ' '}</FormErrorText>
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={8} lg={6}>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.lastName)}
            component="fieldset"
          >
            <TextField
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              fullWidth
              error={Boolean(formik.errors.lastName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              style={{ marginTop: 28 }}
            />
            <FormErrorText>{formik.errors.lastName || ' '}</FormErrorText>
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={8} lg={12}>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.email)}
            component="fieldset"
          >
            <TextField
              id="email"
              name="email"
              type="text"
              label="Email"
              fullWidth
              error={Boolean(formik.errors.email)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              style={{ marginTop: 28 }}
            />
            <FormErrorText>{formik.errors.email || ' '}</FormErrorText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            required
            error={Boolean(formik.errors.dateOfBirth)}
            component="fieldset"
          >
            <Typography
              component="h5"
              variant="subtitle1"
              style={{ marginTop: 48 }}
            >
              {`Student's Date of Birth`}
              <ApToolTip helperText="Once the document is applyproofed, the date of birth will serve as the student’s passcode when accessing their document." />
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of birth"
                value={formik.values.dateOfBirth}
                onChange={(val) => formik.setFieldValue('dateOfBirth', val)}
                renderInput={(params) => (
                  <TextField {...params} helperText="yyyy/mm/dd" />
                )}
                inputFormat="YYYY/MM/DD"
                mask="____/__/__"
              />
            </LocalizationProvider>
            <FormErrorText>{formik.errors.dateOfBirth || ' '}</FormErrorText>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography component="h2" variant="h5" style={{ marginTop: 56 }}>
            Document Settings
          </Typography>
          <Typography component="p" variant="body1" style={{ marginTop: 16 }}>
            Give the document an expiry date and choose additional recipients
            that you’d like to receive this document. (This is optional)
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl
            required
            error={Boolean(formik.errors.expiryDate)}
            component="fieldset"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ marginTop: 24, marginBottom: 24 }}
            >
              Document Expiry Date{' '}
              <ApToolTip helperText="By default, the document automatically expires two years from today. You may also give the document another preferred expiry date." />
              {/* <Typography component="span" variant="body2">
                (optional)
              </Typography> */}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expiry Date"
                value={formik.values.expiryDate}
                onChange={(val) => formik.setFieldValue('expiryDate', val)}
                renderInput={(params) => (
                  <TextField {...params} helperText="yyyy/mm/dd" />
                )}
                inputFormat="YYYY/MM/DD"
                mask="____/__/__"
              />
            </LocalizationProvider>
            <FormErrorText style={{ marginTop: 8 }}>
              {formik.errors.expiryDate
                ? (formik.errors.expiryDate as string)
                : ' '}
            </FormErrorText>
          </FormControl>
        </Grid>

        {emailSettings && emailSettings.emailEnabled ? (
          <>
            <Grid item xs={12}>
              <Typography component="h4" variant="h4" style={{ marginTop: 40 }}>
                Email Recipients
                <ApToolTip helperText="The email to field is auto-filled based on your entry above. You may add CC and BCC recipients below and preview the email before it is sent." />
              </Typography>
              <TextField
                id="email"
                name="email"
                type="text"
                label="Email To"
                fullWidth
                error={Boolean(formik.errors.email)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormErrorText> </FormErrorText>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                fullWidth
                error={Boolean(formik.errors.emailCc)}
                component="fieldset"
              >
                <Autocomplete
                  style={{ marginTop: 20 }}
                  multiple
                  disableClearable
                  freeSolo
                  options={[]}
                  id="emailCc"
                  fullWidth
                  onChange={autoCompleteChangeHandler(formik, 'emailCc')}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailCc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(formik.errors.emailCc)}
                      variant="outlined"
                      label="Enter Email CC"
                    />
                  )}
                />
                <FormErrorText>{formik.errors.emailCc}</FormErrorText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                fullWidth
                error={Boolean(formik.errors.emailBcc)}
                component="fieldset"
              >
                <Autocomplete
                  style={{ marginTop: 20 }}
                  multiple
                  disableClearable
                  freeSolo
                  options={[]}
                  id="emailBcc"
                  fullWidth
                  onChange={autoCompleteChangeHandler(formik, 'emailBcc')}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailBcc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(formik.errors.emailBcc)}
                      variant="outlined"
                      label="Enter Email BCC"
                    />
                  )}
                />
                <FormErrorText>{formik.errors.emailBcc}</FormErrorText>
              </FormControl>
            </Grid>
          </>
        ) : undefined}

        <Grid item lg={12}></Grid>
        <Grid item lg={4}></Grid>
        <Grid item lg={12}>
          <DocumentAttachedError>
            {formik.errors.documentAttachedCount}
          </DocumentAttachedError>
        </Grid>
      </Grid>
    </Fragment>
  )

  const actions = (
    <ActionsBox id="ActionsBox">
      <ReviewDocumentsCheckbox
        required
        error={Boolean(formik.errors.reviewedDocuments)}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="reviewedDocuments"
              name="reviewedDocuments"
              checked={formik.values.reviewedDocuments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label="I have reviewed all the information."
        />
        <FormErrorText>{formik.errors.reviewedDocuments}</FormErrorText>
      </ReviewDocumentsCheckbox>
      <SubmitButtonBox>
        <Button type="submit" endIcon={<ArrowRightAlt />} size="large">
          ApplyProof Document
        </Button>
      </SubmitButtonBox>
    </ActionsBox>
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Box style={{ maxHeight: 'calc(100vh - 164px)', overflow: 'auto' }}>
          <Grid item>{headerBox}</Grid>
          <Grid item>{disabledEmailNotification}</Grid>
          <Grid item>
            <Content>{formInput}</Content>
          </Grid>
        </Box>
        <FooterBox>
          <Grid item>
            <ActionsDivider />
          </Grid>

          <Grid item>{actions}</Grid>
        </FooterBox>
      </Grid>
    </form>
  )
}

import React from 'react'

import { GetMetadataResponseWithEmailStatuses } from '../../client/origination-client'
import { getApDocumentTypeLabels } from '../../client/settings-client'
import { DocTypeChip, DocumentIcon } from './styles'

type DocumentTypeChipProps = {
  documentType: GetMetadataResponseWithEmailStatuses['documentType']
}
export default function DocumentTypeChip({
  documentType,
}: DocumentTypeChipProps): JSX.Element {
  return (
    <>
      <DocTypeChip
        icon={<DocumentIcon src="/document_icon.svg" alt="Document icon" />}
        label={getApDocumentTypeLabels(documentType)}
        variant="filled"
      />
    </>
  )
}

import React, { useEffect } from 'react'

import ResetPasswordExpiredPage from '../../components/reset-password/reset-password-expired-page'

export default function RootResetPasswordExpiredPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Expired Password'
  }, [])
  return (
    <>
      <ResetPasswordExpiredPage />
    </>
  )
}

import { useEffect, useState } from 'react'

import { CognitoAuth } from '.'
import { ApplyProofUser } from './auth'

export function useUser(): ApplyProofUser | null | undefined {
  const [user, setUser] = useState<ApplyProofUser | null | undefined>(undefined)

  useEffect(() => {
    async function doEffect() {
      try {
        const auth = new CognitoAuth()
        await auth.refreshSessionIfExpired()

        const session = auth.currentSession()
        const hasValidScopes = await auth.isAuthorized()
        const canGenerateIrccReport = await auth.canGenerateIrccReport()
        if (session && session.current.isValid() && hasValidScopes) {
          setUser({
            username: session.username,
            accessToken: session.current.getAccessToken().getJwtToken(),
            idToken: session.current.getIdToken().getJwtToken(),
            canGenerateIrccReport: canGenerateIrccReport,
          })
        } else {
          setUser(null)
        }
      } catch (err) {
        setUser(null)
        console.error(err)
      }
    }

    doEffect()
  }, [])
  return user
}

import { MoreVert } from '@mui/icons-material'
import { Button, Box, Grid, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'

import { GetMetadataResponseWithEmailStatuses } from '../../../client/origination-client'
import { SearchApiResult } from '../../../client/portal-client'
import { ApAlertEventHandler } from '../../ap-alert/apAlert'
import ApCopyTile from '../../ap-copy-tile'
import DocumentTypeChip from '../../document-type-chip'
import EmailStatusChip from '../../email-status-chip'
import ExpiryDateChip from '../../expiry-date-chip'
import InvalidatedChip from '../../invalidated-chip'
import {
  RootPaper,
  ContentDivider,
  PassCodeTile,
  DocumentCodeTile,
  StudentId,
  Name,
  LoadingIndicator,
  LoadingBox,
  DocumentDrawerButton,
  Header,
  ViewButtonBox,
  DocumentCodeTileBox,
} from './styles'

dayjs.extend(LocalizedFormat)

export type DocumentCardProps = {
  loading?: boolean
  result: GetMetadataResponseWithEmailStatuses
  outline?: boolean
  onActionClick?: (result: SearchApiResult) => void | undefined
  onCopyAlert?: ApAlertEventHandler
  onInvalidate: (result: SearchApiResult) => Promise<void>
}

export function DocumentCard({
  result,
  outline,
  onActionClick,
  onCopyAlert = () => undefined,
  loading = false,
}: DocumentCardProps): JSX.Element {
  const handleCopy = (message: string) => {
    onCopyAlert({ message })
  }

  const documentStatus: JSX.Element = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <DocumentTypeChip documentType={result.documentType} />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <InvalidatedChip
          createdAt={dayjs(result.createdAt).toDate()}
          invalidated={result.invalidated}
          status={result.documentStatus}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <ExpiryDateChip expiryDate={result.expiry} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3}
        xl={6}
        style={{ alignSelf: 'flex-end' }}
      >
        <ViewButtonBox>
          <Button
            variant="outlined"
            href={`/search/${result.duid}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </Button>
        </ViewButtonBox>
      </Grid>
    </Grid>
  )

  return (
    <RootPaper elevation={outline ? 1 : 0} outline={outline ?? false}>
      <DocumentDrawerButton>
        <IconButton
          onClick={() => (onActionClick ? onActionClick(result) : undefined)}
          color="primary"
          style={{
            height: '1rem',
            width: '1rem',
            marginLeft: '-8px',
            marginRight: 4,
          }}
        >
          <MoreVert />
        </IconButton>
      </DocumentDrawerButton>
      {loading ? (
        <LoadingBox>
          <LoadingIndicator size={32} />
        </LoadingBox>
      ) : undefined}
      <Grid container spacing={2} direction={'row'}>
        <Grid item xs={6} md={4} lg={2}>
          <Header variant="body1">Name</Header>
          <Name variant="h3">
            {result.primaryRecipient.firstName +
              ' ' +
              result.primaryRecipient.lastName}
          </Name>
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Header variant="body1">ID</Header>
          <StudentId variant="body1">{result.primaryRecipient.id}</StudentId>
        </Grid>
        <Grid item xs={12} md={6} lg={'auto'}>
          <Header variant="body1">Email</Header>
          <EmailStatusChip
            primaryRecipient={result.primaryRecipient.email}
            primaryRecipientEmailStatus={result.primaryRecipientEmailStatus}
            emailTo={result.emailTo}
            emailBcc={result.emailBcc}
            emailCc={result.emailCc}
          />
        </Grid>
        <Grid item xs={12} md={12} lg>
          <DocumentCodeTileBox>
            <ApCopyTile
              title={'APPLYPROOF CODE'}
              value={result.duid}
              onCopy={handleCopy}
              styling={DocumentCodeTile}
            />
            <ApCopyTile
              title={'PASSCODE'}
              value={result.passcode}
              color="green"
              onCopy={handleCopy}
              styling={PassCodeTile}
            />
          </DocumentCodeTileBox>
        </Grid>
      </Grid>
      <ContentDivider />
      {documentStatus}
    </RootPaper>
  )
}

import { Grid } from '@mui/material'
import React from 'react'

import {
  Content,
  DocFailedAlert,
  DocFailedTypography,
  DocFailedBox,
  AlertBox,
  DocFailedInfoBox,
  DocFailedAlertTitle,
  ErrorIcon,
} from './styles'

export default function DocumentFailedViewer(): JSX.Element {
  return (
    <DocFailedBox>
      <AlertBox>
        <DocFailedAlert variant="outlined" icon={false} severity="error">
          <DocFailedAlertTitle>Re-upload your document</DocFailedAlertTitle>
          Your document failed to upload possibly due to a corrupted document.
          Please make sure the document you are uploading is in PDF format and
          try again. If the document continues to fail, please contact
          support@applyproof.com for further assistance.
        </DocFailedAlert>
      </AlertBox>

      <DocFailedInfoBox>
        <Content
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <ErrorIcon alt="Error" src="/Error_icon.svg" />
          </Grid>
          <Grid item xs={12}>
            <DocFailedTypography variant="body1">
              Document failed to upload. <br /> Please try again
            </DocFailedTypography>
          </Grid>
        </Content>
      </DocFailedInfoBox>
    </DocFailedBox>
  )
}

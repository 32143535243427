import { Box } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'

import { ApSelect, ApChip } from './styles'

type DropdownItem = {
  label: string
  value: string
}

type SearchFilterDropdownProps<T> = {
  label: string
  value: T
  handleChange: (event: any) => void
  dropdownItems: Array<DropdownItem>
  handleRemove: () => void
  styling: React.CSSProperties
}

export default function SearchFilterDropdown({
  label,
  value,
  handleChange,
  dropdownItems,
  handleRemove,
  styling,
}: SearchFilterDropdownProps<string | undefined>): JSX.Element {
  const dropDown = (
    <FormControl>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <ApSelect
        select
        id={`${label}-name`}
        value={value ?? ''}
        onChange={handleChange}
        sx={styling}
      >
        {dropdownItems.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </ApSelect>
    </FormControl>
  )

  const getChipLabel = () => {
    const selectedItem = dropdownItems.filter((item) => item.value === value)[0]
    if (!selectedItem) {
      return label
    }
    return selectedItem.label
  }

  const selectedValue = (
    <>
      <ApChip label={`${getChipLabel()}`} onDelete={handleRemove} />
    </>
  )

  const content = value ? selectedValue : dropDown

  return <Box sx={{ paddingRight: 2 }}>{content}</Box>
}

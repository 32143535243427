import { createTheme, ThemeOptions } from '@mui/material'

import { colors } from './color'

export * from './color'

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primary.light,
      light: colors.primary.light,
      dark: colors.primary.dark,
    },
    secondary: {
      main: colors.secondary.light,
      light: colors.primary.light,
      dark: colors.secondary.dark,
    },
    // error: {
    //   main: 'red',
    // },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Open Sans',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    allVariants: {},
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 32,
      marginBottom: 16,
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 24,
      marginBottom: 16,
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 20,
      marginBottom: 16,
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 16,
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 16,
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      marginBottom: 16,
      lineHeight: 1,
      color: colors.font.default.header,
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '16px',
    },
    subtitle2: {
      marginBottom: '16px',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontWeight: 'normal',
      fontSize: '16px',
      color: colors.font.default.body,
    },
    body2: {
      fontFamily: 'Open Sans',
      fontWeight: 'normal',
      fontSize: '14px',
      color: colors.font.default.body,
    },
    button: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '16px',
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: 1,
      margin: 0,
      color: colors.font.default.body,
    },
    overline: {},
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        autoComplete: true,
        autoHighlight: true,
        autoSelect: true,
        blurOnSelect: true,
        clearOnEscape: false,
        disableClearable: true,
        disableListWrap: true,
        openOnFocus: true,
      },
      styleOverrides: {
        inputRoot: {
          '&.MuiInputBase-root': {
            lineHeight: '1rem',
          },
          '&[class*="MuiOutlinedInput-root"]': {
            padding: 9,
            paddingBottom: 2,
          },
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '4px 16px',
          fontWeight: 400,
          fontFamily: 'Open Sans',
          fontSize: 14,
          color: '#000f33',
          borderRadius: 18,
        },
        // label: {
        //   textTransform: 'none',
        // },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        grouped: {
          margin: 4,
          border: 'none',
        },
        groupedHorizontal: {
          '&:not(:first-of-type)': {
            marginLeft: 8,
            borderLeft: '1px solid #c4c9d7',
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
          },
          '&:not(:last-of-type)': {
            borderTopRightRadius: 18,
            borderBottomRightRadius: 18,
          },
          border: '1px solid #c4c9d7',
          '&.Mui-selected': {
            border: '1px solid #0254F0',
            color: '#0254f0',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontVariantCaps: 'normal',
          minWidth: 112,
        },
        sizeLarge: {
          minHeight: 48,
        },
        contained: {
          border: 0,
          borderRadius: 4,
          backgroundColor: colors.primary.light,
          '&:hover': {
            backgroundColor: colors.primary.A400,
          },
          '&$disabled': {
            backgroundColor: colors.secondary.dark,
          },
          '&:focus': {
            border: colors.secondary,
            color: 'white',
          },
        },
        containedSizeMedium: {
          color: 'white',
        },
        outlined: {},
        text: {},
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: 12,
          fontWeight: 600,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover $notchedOutline': {
            borderColor: colors.secondary.A300,
          },
          '&$focused $notchedOutline': {
            borderColor: colors.primary.light,
          },
        },
        input: {
          padding: '12.5px 14px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: 48,
        },
        input: {
          padding: '12.5px 14px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
    },
  },
}

export const theme = createTheme(themeOptions)

import { styled, Box, Button, BoxProps } from '@mui/material'

import { colors } from '../../../theme/color'

type OverlayBoxProps = BoxProps & { highlight: boolean }

export const RootBox = styled(Box)({
  width: '56px',
  height: '65px',
  marginRight: '24px',
  cursor: 'pointer',
  position: 'relative',
})

export const CloseButton = styled(Button)({
  position: 'absolute',
  top: '0',
  right: '0',
  marginTop: '-9px',
  marginRight: '-9px',
  borderRadius: '99px',
  width: '18px',
  height: '18px',
  padding: 0,
  fontSize: '0.5rem',
  minWidth: '8px',
  zIndex: 999,
})

export const OverlayBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<OverlayBoxProps>(({ highlight }) => ({
  position: 'absolute',
  height: 'inherit',
  width: 'inherit',
  backgroundColor: highlight ? colors.translucent.blue : 'transparent',
  border: '2px',
  borderStyle: 'solid',
  borderColor: highlight ? colors.primary.A400 : colors.secondary.A400,
  borderRadius: '4px !important',
  zIndex: 10,
}))

export const DocumentBox = styled(Box)({
  overflow: 'hidden',
  zIndex: -1,
})

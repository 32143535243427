import { styled, Box, Typography, Grid, Alert, AlertTitle } from '@mui/material'

import { colors } from '../../theme'

export const Content = styled(Grid)({
  marginTop: 40,
  minHeight: '100%',
})

export const DocFailedAlert = styled(Alert)({
  backgroundColor: colors.background.translucentRed,
  color: colors.font.default.body,
  width: '80%',
  fontSize: 12,
})

export const DocFailedAlertTitle = styled(AlertTitle)({
  color: colors.text.black,
})

export const DocFailedTypography = styled(Typography)({
  color: colors.primary.A50,
  textAlign: 'center',
  display: 'block',
})

export const DocFailedBox = styled(Box)({
  minHeight: '70vh',
})

export const AlertBox = styled(Box)({
  paddingTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const DocFailedInfoBox = styled(Box)({
  height: 'calc(100vh - 250px)',
})

export const ErrorIcon = styled('img')({
  height: '40px',
  width: '40px',
})

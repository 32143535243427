import { LoadingButton } from '@mui/lab'
import { CircularProgress, Grid, Typography, Link } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CognitoAuth } from '../../../client/auth'
import {
  NewPasswordChallengeParams,
  NewPasswordRequiredResult,
} from '../../../client/auth/auth'
import { config } from '../../../config'
import { UnauthorizedError } from '../../../errors'
import { colors } from '../../../theme'
import {
  RootGrid,
  LoginHeader,
  ForgetPasswordBox,
  TextInputField,
  StyledGridItem,
} from './styles'
import { LoginFormFields, validate } from './validate'

export default function LoginForm(): JSX.Element {
  const navigate = useNavigate()
  const [auth] = useState(new CognitoAuth())
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [newPasswordChallenge, setNewPasswordChallenge] = useState(
    {} as NewPasswordChallengeParams
  )
  const [scopesRequired, setScopesRequired] = useState(false)

  const [error, setError] = useState({
    newPassword: false,
    login: false,
    scope: false,
  })

  useEffect(() => {
    console.debug('Unable to log in ')
  }, [error, scopesRequired, newPasswordRequired])

  const onSubmit = async ({
    username,
    password,
    newPassword,
  }: LoginFormFields) => {
    try {
      setError({
        newPassword: false,
        login: false,
        scope: false,
      })

      if (!newPasswordRequired) {
        const result: NewPasswordRequiredResult = await auth.authenticate({
          username,
          password,
        })

        if (result.newPasswordRequired) {
          setNewPasswordRequired(true)
          setNewPasswordChallenge(result.userAttributes)
        } else {
          try {
            const hasValidScopes = await auth.isAuthorized()
            if (hasValidScopes) {
              navigate('../upload', { replace: true })
            } else {
              throw new Error('Missing originatorHub scopes')
            }
          } catch (err) {
            if (err instanceof Error) {
              throw new UnauthorizedError(err.message)
            } else {
              throw new UnauthorizedError('Missing scopes')
            }
          }
        }
      } else {
        if (newPassword) {
          await auth.completeNewPasswordChallenge({
            username,
            password,
            newPassword,
          })
          try {
            const hasValidScopes = await auth.isAuthorized()
            if (hasValidScopes) {
              navigate('upload')
            } else {
              throw new Error('Missing originatorHub scopes')
            }
          } catch (err) {
            if (err instanceof Error) {
              throw new UnauthorizedError(err.message)
            } else {
              throw new UnauthorizedError('Missing scopes')
            }
          }
        } else {
          setError({
            newPassword: true,
            login: false,
            scope: false,
          })
          throw new Error('New Password Required')
        }
      }
    } catch (err) {
      if (newPasswordRequired) {
        setError({
          newPassword: true,
          login: false,
          scope: false,
        })
      } else if (err instanceof UnauthorizedError) {
        setError({
          newPassword: false,
          login: false,
          scope: true,
        })
      } else {
        setError({
          newPassword: false,
          login: true,
          scope: false,
        })
      }
      console.error(err)
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    } as LoginFormFields,
    validate,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })
  return (
    <RootGrid container>
      <StyledGridItem item xs={12}>
        <img
          src="/ApplyProof_logo.svg"
          alt="applyproof-logo"
          width="196"
          height="37"
        />
        <LoginHeader variant="h4">Sign In</LoginHeader>
        <Typography variant="body1">
          Continue to your Document Dashboard
        </Typography>
      </StyledGridItem>
      <StyledGridItem item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInputField
                fullWidth
                label="Email"
                id="username"
                name="username"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.username}
              />
              {formik.errors.username ? (
                <Typography
                  component="p"
                  variant="caption"
                  style={{ color: colors.accent.red.dark }}
                >
                  {formik.errors.username}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextInputField
                fullWidth
                label="Password"
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password ? (
                <Typography
                  component="p"
                  variant="caption"
                  style={{ color: colors.accent.red.dark }}
                >
                  {formik.errors.password}
                </Typography>
              ) : null}
              <ForgetPasswordBox>
                <Link href="/password/forgot" variant="caption">
                  Forgot Password?
                </Link>
              </ForgetPasswordBox>
              {newPasswordRequired ? (
                <React.Fragment>
                  <Typography
                    style={{ marginTop: 32, marginBottom: 24 }}
                    variant="body1"
                    component="p"
                  >
                    Looks like it&apos;s your first time logging in. Enter your
                    new password.
                  </Typography>
                  <TextInputField
                    fullWidth
                    label="New Password"
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.newPassword}
                  />
                  <TextInputField
                    style={{ marginTop: 24 }}
                    fullWidth
                    label="Re-enter Password"
                    id="repeatPassword"
                    name="repeatPassword"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.repeatPassword}
                  />
                  {formik.errors.repeatPassword ? (
                    <Typography
                      component="p"
                      variant="caption"
                      style={{ color: colors.accent.red.dark }}
                    >
                      {formik.errors.repeatPassword}
                    </Typography>
                  ) : null}
                </React.Fragment>
              ) : undefined}
              <LoadingButton
                variant="contained"
                style={{ marginTop: 40 }}
                type="submit"
                loading={formik.isSubmitting}
                loadingIndicator={
                  <CircularProgress color="primary" size={16} />
                }
              >
                Sign In
              </LoadingButton>
              {error.newPassword ? (
                <React.Fragment>
                  <Typography
                    style={{
                      marginTop: 8,
                      lineHeight: '1rem',
                      color: colors.accent.red.dark,
                    }}
                    variant="body1"
                    component="p"
                  >
                    Failure to set New Password. Please ensure your new password
                    is at least 10 characters long and contains at least one of
                    each:
                  </Typography>
                  <ul>
                    <li>numbers</li>
                    <li>special character</li>
                    <li>uppercase letters</li>
                    <li>lowercase letters</li>
                  </ul>
                </React.Fragment>
              ) : undefined}
              {error.login ? (
                <React.Fragment>
                  <Typography
                    style={{
                      marginTop: 8,
                      lineHeight: '1rem',
                      color: colors.accent.red.dark,
                    }}
                    variant="caption"
                    component="p"
                  >
                    Invalid username or password. You can use the forgot
                    password link above to reset your password or contact
                    support@applyproof.com to request a reset
                  </Typography>
                </React.Fragment>
              ) : undefined}
              {error.scope ? (
                <React.Fragment>
                  <Typography
                    style={{
                      marginTop: 8,
                      lineHeight: '1rem',
                      color: colors.accent.red.dark,
                    }}
                    variant="caption"
                    component="p"
                  >
                    You do not have permission to log-in. Please contact
                    support@applyproof.com
                  </Typography>
                </React.Fragment>
              ) : undefined}
              <Typography style={{ marginTop: 8 }}>
                <Link
                  href={`${config.applyproofUrl}/schools`}
                  target="_blank"
                  variant="caption"
                >
                  Don’t have an account?
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </StyledGridItem>
      <StyledGridItem item xs={12}>
        <Typography style={{ fontSize: 14 }} variant="body2" component="p">
          By signing in you agree to ApplyProof’s{' '}
          <Link href={`${config.applyproofUrl}/resources/privacy-overview`}>
            Privacy Overview
          </Link>{' '}
          and{' '}
          <Link href={`${config.applyproofUrl}/terms-and-conditions`}>
            Terms &amp; Conditions
          </Link>
        </Typography>
      </StyledGridItem>
    </RootGrid>
  )
}

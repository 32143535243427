import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { styled, Tooltip, Typography } from '@mui/material'

import { colors } from '../../../theme'

export const HoverToolTip = styled(Tooltip)({
  borderStyle: 'none',
  marginBottom: 2,
})

export const HelperText = styled(Typography)({
  fontSize: '10px',
})

export const InfoIcon = styled(InfoOutlinedIcon)({
  color: colors.primary.A400,
})

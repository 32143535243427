import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import * as React from 'react'

import { colors } from '../../../theme'

// This implementation is due to a special case involving how styled components scopes its CSS
// see: https://mui.com/material-ui/guides/interoperability/#portals
export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.login.tooltip,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: colors.text.white,
    backgroundColor: colors.login.tooltip,
    maxWidth: 400,
  },
}))

import { MenuProps, styled, Chip, TextField } from '@mui/material'

import { colors } from '../../../theme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const ApSelect = styled(TextField)({
  outline: 'none',
  fontSize: '14px',
  padding: 0,
  marginTop: '10px',
  '& .MuiInputBase-input': {
    border: '1px solid #ced4da',
    borderRadius: 20,
    outline: 'none',
    paddingTop: '5px',
    paddingBottom: '4px',
  },
  '& .MuiOutlinedInput-root': {
    '& > fieldset': {
      border: `0px`,
    },
    border: '0px solid #ced4da',
    borderRadius: 20,
    outline: 'none',
    padding: '0',
    backgroundColor: colors.background.white,
    '&.Mui-focused': {
      color: colors.primary.A400,
      border: `1px solid ${colors.primary.A400}`,
    },
  },
})

export const ApChip = styled(Chip)({
  backgroundColor: colors.translucent.blue,
  border: `1px solid ${colors.primary.A400}`,
  marginTop: '10px',
  '& .MuiChip-label': {
    fontSize: 14,
    color: colors.primary.A400,
    fontStyle: 'normal',
  },
  '& .MuiChip-root': {
    fontStyle: 'normal',
  },
})

import { Box, Button, Divider, Grid, Typography, Link } from '@mui/material'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import { useUser } from '../../../client/auth'
import {
  createOriginationClient,
  GetMetadataResponseWithEmailStatuses,
} from '../../../client/origination-client'
import { getApDocumentTypeLabels } from '../../../client/settings-client'
import { config } from '../../../config'
import ApAlert from '../../ap-alert'
import ApCopyTile from '../../ap-copy-tile/apCopyTile'
import DocumentViewer from '../../document-viewer'
import EmailRecipients from '../../email-recipients'
import ExpiryDateChip from '../../expiry-date-chip'
import InvalidatedChip from '../../invalidated-chip'
import InvalidateDialog from '../invalidate-dialog'
import RevalidateDialog from '../revalidate-dialog'
import {
  getDocumentStatusDescription,
  getDocumentStatusTitle,
  getDocumentStatusIconSrc,
} from './helper'
import {
  DocumentTitle,
  Text3Typography,
  StudentName,
  ActionsBox,
  DocumentViewerBox,
  InnerContent,
  ContentGrid,
  ApCopyTileStyles,
  ValidationButton,
} from './styles'
import { documentStatusTypes } from './types'

dayjs.extend(LocalizedFormat)

export type ConfirmationPageProps = {
  metadata: GetMetadataResponseWithEmailStatuses
  documentStatus: documentStatusTypes
}

export default function ConfirmationPage({
  metadata,
  documentStatus,
}: ConfirmationPageProps): JSX.Element {
  const user = useUser()
  const navigate = useNavigate()
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  })
  const [documentHref, setDocumentHref] = useState('')
  const [invalidateOpen, setInvalidateOpen] = useState(false)
  const [revalidateOpen, setRevalidateOpen] = useState(false)
  const [documentLoading, setDocumentLoading] = useState(true)

  if (user === null) {
    navigate('/', { replace: true })
  }

  const key = user ? `/api/file/${metadata.duid}` : null
  const metadataClient = createOriginationClient()
  const documentClient = createOriginationClient()
  const { data, error } = useSWR(
    key,
    async () => {
      if (user) {
        try {
          const { url } = await documentClient.getPresignedUrl(metadata.duid)
          setDocumentLoading(false)
          setDocumentHref(url)

          return { url }
        } catch (err) {
          setDocumentLoading(true)
        }
      } else {
        navigate('/', { replace: true })
      }
    },
    {
      revalidateOnFocus: false,
    }
  )

  const handleInvalidateClick = () => {
    setInvalidateOpen(true)
  }

  const handleRevalidateClick = () => {
    setRevalidateOpen(true)
  }

  const handleInvalidateConfirm = () => {
    if (user) {
      metadataClient
        .invalidateDocument(metadata.duid)
        .then((res) => {
          mutate(`/api/metadata/${metadata.duid}`)
          setAlert({
            open: true,
            message: 'Successfully invalidated document',
            type: 'success',
          })
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: 'Failure to invalidate document',
            type: 'error',
          })
        })
        .finally(() => {
          setInvalidateOpen(false)
        })
    } else {
      navigate('/', { replace: true })
    }
  }

  const handleRevalidateConfirm = () => {
    if (user) {
      metadataClient
        .revalidateDocument(metadata.duid)
        .then((res) => {
          mutate(`/api/metadata/${metadata.duid}`)
          setAlert({
            open: true,
            message: 'Successfully revalidated document',
            type: 'success',
          })
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: 'Failure to revalidate document',
            type: 'error',
          })
        })
        .finally(() => {
          setRevalidateOpen(false)
        })
    } else {
      navigate('/', { replace: true })
    }
  }

  const handleInvalidateClose = () => {
    setInvalidateOpen(false)
  }

  const handleRevalidateClose = () => {
    setRevalidateOpen(false)
  }

  const handleCopy = (message: string) => {
    setAlert({
      open: true,
      message: `Copied "${message}" to Clipboard!`,
      type: 'info',
    })
  }

  const handleAlertClose = () => {
    setAlert({
      open: false,
      message: alert.message,
      type: alert.type,
    })
  }

  const documentType = getApDocumentTypeLabels(metadata.documentType)
  const documentStatusIconSrc = getDocumentStatusIconSrc(documentStatus)
  const documentStatusTitle = getDocumentStatusTitle(documentStatus)
  const documentStatusDescription = getDocumentStatusDescription(
    documentStatus,
    documentType
  )

  const header = (
    <Box>
      <img
        alt="invalidate document icon"
        src={documentStatusIconSrc}
        width={62}
        height={76}
      />
      <DocumentTitle variant="h2">{documentStatusTitle}</DocumentTitle>
      <Typography component="p" variant="body1">
        {documentStatusDescription}
      </Typography>
      <Divider style={{ marginTop: 16 }} />
    </Box>
  )

  const content = (
    <InnerContent>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h3" style={{ marginTop: 32 }}>
            Student Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">Name</Typography>
          <StudentName variant="h4">
            {metadata.primaryRecipient.firstName}{' '}
            {metadata.primaryRecipient.lastName}
          </StudentName>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">ID</Typography>
          {metadata.primaryRecipient.id ? (
            <Text3Typography variant="body1">
              {metadata.primaryRecipient.id}
            </Text3Typography>
          ) : undefined}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption">Email</Typography>
          <Text3Typography variant="body1">
            {metadata.primaryRecipient.email}
          </Text3Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 40 }} />
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant="h3"
            style={{ marginTop: 32, marginBottom: 24 }}
          >
            Document Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ApCopyTile
            title="APPLYPROOF CODE"
            value={metadata.duid}
            color="blue"
            onCopy={handleCopy}
            styling={ApCopyTileStyles}
          />
          <ApCopyTile
            title="PASSCODE"
            value={metadata.passcode}
            color="green"
            onCopy={handleCopy}
            styling={ApCopyTileStyles}
          />
        </Grid>
        <Grid item xs={12}>
          {documentStatus !== 'PROCESSING' ? (
            <>
              <InvalidatedChip
                invalidated={metadata.invalidated}
                createdAt={dayjs(metadata.createdAt).toDate()}
                status={metadata.documentStatus}
              />
            </>
          ) : (
            <>
              <InvalidatedChip
                invalidated={metadata.invalidated}
                createdAt={dayjs(metadata.createdAt).toDate()}
                status={'PROCESSING'}
              />
            </>
          )}
          <ExpiryDateChip expiryDate={metadata.expiry} />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ marginTop: 32 }}>Document Link</Typography>
          <Typography component="p" variant="body1">
            <Link
              href={`${config.applyproofUrl}/${metadata.duid}`}
            >{`${config.applyproofUrl}/${metadata.duid}`}</Link>
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 40, marginBottom: 40 }} />
      <EmailRecipients searchResults={metadata} />
    </InnerContent>
  )

  const actions = (
    <Box>
      <Divider />
      <ActionsBox>
        {documentStatus === 'COMPLETED' ? (
          <ValidationButton>
            <Button
              disabled={!data?.url}
              onClick={handleInvalidateClick}
              variant="outlined"
            >
              Invalidate Document
            </Button>
          </ValidationButton>
        ) : undefined}

        {
          // enable in config when v2 revalidation flow is implemented
          config.enableRevalidateButton === true &&
          metadata.invalidated === true ? (
            <ValidationButton>
              <Button
                disabled={!data?.url}
                onClick={handleRevalidateClick}
                variant="outlined"
              >
                Revalidate Document
              </Button>
            </ValidationButton>
          ) : undefined
        }

        <Box>
          <Button href={`/upload/${metadata.documentType}`} variant="text">
            New Upload
          </Button>
        </Box>
      </ActionsBox>
    </Box>
  )

  return (
    <Box>
      <Grid container spacing={0}>
        <ContentGrid item xs={12} lg={5}>
          {header}
          {content}
          {actions}
        </ContentGrid>
        <Grid item xs={12} lg={7}>
          <DocumentViewerBox>
            <DocumentViewer
              documentStatus={documentStatus}
              url={documentHref}
            />
          </DocumentViewerBox>
        </Grid>
      </Grid>
      <ApAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
        onClose={handleAlertClose}
      />
      <InvalidateDialog
        open={invalidateOpen}
        onConfirm={handleInvalidateConfirm}
        onCancel={handleInvalidateClose}
      />
      <RevalidateDialog
        open={revalidateOpen}
        onConfirm={handleRevalidateConfirm}
        onCancel={handleRevalidateClose}
      />
    </Box>
  )
}

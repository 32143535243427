import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { DocumentUpload } from '../../../models/document-upload'
import { RootBox, DropZoneBox, Title } from './styles'

export type DocumentDropzoneProps = {
  error: boolean
  onDocumentUploaded: (document: DocumentUpload) => void
}

export default function DocumentDropzone({
  error,
  onDocumentUploaded,
}: DocumentDropzoneProps): JSX.Element {
  const [isHover, setIsHover] = useState(false)
  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (file: File) =>
      onDocumentUploaded(await DocumentUpload.fromFile(file))
    )
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
  })
  return (
    <RootBox {...getRootProps()}>
      <input {...getInputProps()} />
      <DropZoneBox
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        error={error}
        isDragActive={isDragActive}
        isHover={isHover}
      >
        <Box>
          {isDragActive || isHover ? (
            <img
              width={85}
              height={69}
              src="/Upload_spark_icon_hover.svg"
              alt="upload spark icon hover"
            />
          ) : (
            <img
              alt="upload spark icon"
              width={85}
              height={69}
              src="/Upload_spark_icon.svg"
            />
          )}
          <Title variant="body1" error={error}>
            Upload or drag and drop the student’s corresponding document here
          </Title>
          <Typography component="p" variant="body2">
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 600 }}
            >
              Only .pdf files are supported.
            </Typography>
            <br />
            Multiple document uploads will be merged into one. You will be able
            to review the document you uploaded before you submit it to
            ApplyProof.
          </Typography>
        </Box>
      </DropZoneBox>
    </RootBox>
  )
}

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  List,
  Button,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { AdminClient, createAdminClient } from '../../../client/admin-client'
import { colors } from '../../../theme'
import PasswordRequirementsTooltip from '../password-requirements-tooltip'
import PasswordStrengthItem from './PasswordStrengthItem'
import {
  RootGrid,
  LoginHeader,
  InputField,
  SubmitButton,
  passwordStrengthSatisfiedClass,
  passwordStrengthNotSatisfiedClass,
  passwordStrengthNotSatisfiedWhiteClass,
  StyledGridItem,
} from './styles'
import {
  validate,
  ResetPasswordFormFields,
  validatePasswordStrength,
  PasswordStrengthRequirements,
  validatePasswordStrengthMessages,
} from './validate'

type ResetPasswordData = {
  username: string
  expiry: string // Date ISO string
}

type ExtractParameterViaRegexParams = {
  url: string
  regex: RegExp
  parameterName: string
}

function extractParameterViaRegex({
  url,
  regex,
  parameterName,
}: ExtractParameterViaRegexParams): string {
  const match = regex.exec(url)
  if (match === null || match[1] === undefined) {
    throw new Error(`Failed to extract ${parameterName} from url.`)
  }
  const parameter = decodeURIComponent(match[1])
  return parameter
}

/**
 * This form gets re-used between the pages for confirming a new password after signup, and for
 * resetting a password after forgot password. The user receives a link in their inbox either like
 *
 * Password reset:
 * <baseUrl>/password/reset?expiry=${expiry}&username=${username}&confirmationCode=${confirmationCode}`
 *
 * Confirm new password
 * <baseUrl>/password/confirm-signup?expiry=${expiry}&username=${username}&temporaryPassword=${temporaryPassword}`;
 *
 * We need to be able to handle both cases below.
 */
export default function ResetPasswordForm({
  confirmSignup = false,
}: {
  confirmSignup: boolean
}): JSX.Element {
  const navigate = useNavigate()

  const [setPasswordPressed, setSetPasswordPressed] = useState(false)
  const [resetPasswordFields, setResetPasswordFields] =
    useState<ResetPasswordData>({
      username: '',
      expiry: '',
    })
  const [resetCode, setResetCode] = useState('')
  const [resetPasswordError, setResetPasswordError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [
    passwordStrengthRequirementsSatisfied,
    setPasswordStrengthRequirementsSatisfied,
  ] = useState<PasswordStrengthRequirements>({
    digit: false,
    lowercase: false,
    uppercase: false,
    specialCharacter: false,
    minLength: false,
  })
  const [showPasswordStrengthChecklist, setShowPasswordStrengthChecklist] =
    useState(false)

  /**
   * Some parameters in the URL can contain characters normally reserved for query
   * string parameters and anchors (?, =, &, #). This is not something we can control
   * since the links are filled in from Cognito. We therefore need to use regular
   * expressions to capture the format of query parameters in the URL.
   */
  useEffect(() => {
    try {
      const url = window.location.href

      const expiryRegex =
        /expiry=(.+)&username=(?:.+)&(temporaryPassword|confirmationCode)=(?:.+)/g
      const expiry = extractParameterViaRegex({
        url: url,
        regex: expiryRegex,
        parameterName: 'expiry',
      })

      const usernameRegex =
        /expiry=(?:.+)&username=(.+)&(temporaryPassword|confirmationCode)=(?:.+)/g
      const username = extractParameterViaRegex({
        url: url,
        regex: usernameRegex,
        parameterName: 'username',
      })

      const now = new Date()
      const expiryDate = new Date(expiry)
      if (now > expiryDate) {
        navigate('/password/expired')
      }

      setResetPasswordFields({ username: username, expiry: '' })

      if (confirmSignup) {
        const temporaryPasswordRegex =
          /expiry=(?:.+)&username=(?:.+)&temporaryPassword=(.+)/g
        const temporaryPassword = extractParameterViaRegex({
          url: url,
          regex: temporaryPasswordRegex,
          parameterName: 'temporaryPassword',
        })

        setResetCode(temporaryPassword)
      } else {
        const confirmationCodeRegex =
          /expiry=(?:.+)&username=(?:.+)&confirmationCode=(.+)/g
        const confirmationCode = extractParameterViaRegex({
          url: url,
          regex: confirmationCodeRegex,
          parameterName: 'confirmationCode',
        })

        setResetCode(confirmationCode)
      }
    } catch (err) {
      navigate('/password/expired')
    }
  }, [])

  const onSubmit = async ({
    password,
    confirmPassword, // unused
  }: ResetPasswordFormFields) => {
    const client = createAdminClient()
    try {
      if (confirmSignup) {
        await client.confirmUser(resetPasswordFields.username, {
          temporaryPassword: resetCode,
          newPassword: password,
        })
        setSetPasswordPressed(true)
      } else {
        await client.confirmResetUserPassword(resetPasswordFields.username, {
          confirmationCode: resetCode,
          newPassword: password,
        })
        setSetPasswordPressed(true)
      }
    } catch (err) {
      setResetPasswordError(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    } as ResetPasswordFormFields,
    validate,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  // update password strength fields
  useEffect(() => {
    setPasswordStrengthRequirementsSatisfied(
      validatePasswordStrength(formik.values.password)
    )
  }, [formik.values.password, formik.values.confirmPassword])

  const passwordVisibilityToggle = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )

  const passwordStrengthTooltip = (
    <List style={{ paddingBottom: 20 }}>
      <PasswordStrengthItem
        fieldSatisfied={passwordStrengthRequirementsSatisfied.minLength}
        fieldText={validatePasswordStrengthMessages.minLength}
        fieldSatisfiedStyles={passwordStrengthSatisfiedClass}
        fieldNotSatisfiedStyles={passwordStrengthNotSatisfiedClass}
      ></PasswordStrengthItem>
      <PasswordStrengthItem
        fieldSatisfied={
          passwordStrengthRequirementsSatisfied.lowercase &&
          passwordStrengthRequirementsSatisfied.uppercase &&
          passwordStrengthRequirementsSatisfied.digit &&
          passwordStrengthRequirementsSatisfied.specialCharacter
        }
        fieldText={'Should contain:'}
        fieldSatisfiedStyles={passwordStrengthSatisfiedClass}
        fieldNotSatisfiedStyles={passwordStrengthNotSatisfiedClass}
      ></PasswordStrengthItem>
      <PasswordStrengthItem
        fieldSatisfied={passwordStrengthRequirementsSatisfied.lowercase}
        fieldText={validatePasswordStrengthMessages.lowercase}
        fieldSatisfiedStyles={passwordStrengthSatisfiedClass}
        fieldNotSatisfiedStyles={passwordStrengthNotSatisfiedWhiteClass}
        paddingLeft={'20%'}
      ></PasswordStrengthItem>
      <PasswordStrengthItem
        fieldSatisfied={passwordStrengthRequirementsSatisfied.uppercase}
        fieldText={validatePasswordStrengthMessages.uppercase}
        fieldSatisfiedStyles={passwordStrengthSatisfiedClass}
        fieldNotSatisfiedStyles={passwordStrengthNotSatisfiedWhiteClass}
        paddingLeft={'20%'}
      ></PasswordStrengthItem>
      <PasswordStrengthItem
        fieldSatisfied={passwordStrengthRequirementsSatisfied.digit}
        fieldText={validatePasswordStrengthMessages.digit}
        fieldSatisfiedStyles={passwordStrengthSatisfiedClass}
        fieldNotSatisfiedStyles={passwordStrengthNotSatisfiedWhiteClass}
        paddingLeft={'20%'}
      ></PasswordStrengthItem>
      <PasswordStrengthItem
        fieldSatisfied={passwordStrengthRequirementsSatisfied.specialCharacter}
        fieldText={validatePasswordStrengthMessages.specialCharacter}
        fieldSatisfiedStyles={passwordStrengthSatisfiedClass}
        fieldNotSatisfiedStyles={passwordStrengthNotSatisfiedWhiteClass}
        paddingLeft={'20%'}
      ></PasswordStrengthItem>
    </List>
  )

  return (
    <RootGrid container>
      <StyledGridItem item xs={12}>
        <img
          alt="applyproof logo"
          src="/ApplyProof_logo.svg"
          width="196"
          height="37"
        />
        <LoginHeader variant="h4">
          {setPasswordPressed
            ? 'Congratulations! You successfully set a new password. You may now sign into your account'
            : 'Create new password'}
        </LoginHeader>
        <Typography variant="body1">
          {setPasswordPressed
            ? undefined
            : 'Enter your new password and confirm it to successfully sign in.'}
        </Typography>
      </StyledGridItem>
      <StyledGridItem item xs={12}>
        {setPasswordPressed ? (
          <Button onClick={() => navigate('/')}>Sign In</Button>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PasswordRequirementsTooltip
                  title={passwordStrengthTooltip}
                  open={showPasswordStrengthChecklist}
                >
                  <InputField
                    fullWidth
                    label="Password"
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    onFocus={() => setShowPasswordStrengthChecklist(true)}
                    onBlur={() => setShowPasswordStrengthChecklist(false)}
                    value={formik.values.password}
                    InputProps={{ endAdornment: passwordVisibilityToggle }}
                  />
                </PasswordRequirementsTooltip>
                {formik.errors.password ? (
                  <Typography
                    component="p"
                    variant="caption"
                    style={{ color: colors.accent.red.dark }}
                  >
                    {formik.errors.password}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label="Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  InputProps={{ endAdornment: passwordVisibilityToggle }}
                />
                {formik.errors.confirmPassword ? (
                  <Typography
                    component="p"
                    variant="caption"
                    style={{ color: colors.accent.red.dark }}
                  >
                    {formik.errors.confirmPassword}
                  </Typography>
                ) : null}
                <SubmitButton
                  type="submit"
                  loading={formik.isSubmitting}
                  loadingIndicator={
                    <CircularProgress color="primary" size={16} />
                  }
                >
                  Set Password
                </SubmitButton>
                {resetPasswordError ? (
                  <Typography
                    component="p"
                    style={{ color: colors.accent.red.dark }}
                  >
                    Error setting new password
                  </Typography>
                ) : undefined}
              </Grid>
            </Grid>
          </form>
        )}
      </StyledGridItem>
    </RootGrid>
  )
}

import { styled, Box, Typography, ButtonBase } from '@mui/material'

import { colors } from '../../../theme/color'

export const DocumentTabBox = styled(Box)({
  marginRight: '4px',
})

export const DocumentTabScrollBox = styled(Box)({
  paddingLeft: 24,
  borderTop: `1px solid ${colors.border.solid}`,
  display: 'flex',
  flex: '1',
  overflowX: 'auto',
  overflowY: 'hidden',
  alignItems: 'center',
  height: 100,
})

export const DocumentTabFlexBox = styled(Box)({
  display: 'flex',
  minHeight: 'min-content',
})

export const AddFileButton = styled(ButtonBase)({
  border: '2px',
  borderStyle: 'solid',
  borderColor: colors.secondary.A400,
  borderRadius: 4,
  height: '65px',
  width: '56px',
  display: 'flex',
  justifyItems: 'center',
  '&:hover': {
    borderColor: colors.primary.A400,
  },
})
export const AddSubtitle = styled(Typography)({
  marginBottom: 0,
  fontSize: 12,
})

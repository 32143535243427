import { styled, Box, CircularProgress } from '@mui/material'

export const LoadingBox = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'block',
  margin: 'auto',
})

export const LoadingIcon = styled(CircularProgress)({
  width: '32px',
  height: '32px',
})

export const RootBox = styled(Box)({
  height: 'inherit',
  width: 'inherit',
  overflow: 'hidden',
  position: 'relative',
  display: 'block',
})

import { InputAdornment } from '@mui/material'
import React from 'react'

import { SearchTextFieldIcon, SearchTextInput, SearchButton } from './styles'

type SearchTextFieldProps = {
  searchQuery: string
  handleChange: {
    (e: React.ChangeEvent<any>): void
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void
  }
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
}
export default function SearchTextField({
  searchQuery,
  handleChange,
  handleBlur,
}: SearchTextFieldProps): JSX.Element {
  return (
    <>
      <SearchTextInput
        autoFocus
        id="query"
        name="query"
        size="medium"
        placeholder="Search for ApplyProof Document"
        value={searchQuery}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          sx: {
            paddingTop: '1.15rem',
            paddingBottom: '1.15rem',
            paddingLeft: 5,
            backgroundColor: 'white',
            fontSize: 18,
          },
          startAdornment: (
            <SearchTextFieldIcon position="start">
              <img
                alt="search icon"
                src="/Search_icon.svg"
                width={24}
                height={24}
              />
            </SearchTextFieldIcon>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <SearchButton type="submit">Search</SearchButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

import { Box, Typography } from '@mui/material'
import React from 'react'

import { RootButtonBase, ContentBox, Title, IconFlexBox } from './styles'

export type ApTileProps = {
  highlight: boolean
  icon: JSX.Element
  title: string
  description: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export function ApTile({
  highlight,
  icon,
  title,
  description,
  onClick,
}: ApTileProps): JSX.Element {
  return (
    <RootButtonBase
      tabIndex={0}
      disableRipple
      disableTouchRipple
      onClick={onClick}
      highlight={highlight}
    >
      <ContentBox>
        <IconFlexBox>{icon}</IconFlexBox>
        <Box>
          <Box>
            <Title>{title}</Title>
          </Box>
          <Box>
            <Typography component="p" variant="body2">
              {description}
            </Typography>
          </Box>
        </Box>
      </ContentBox>
    </RootButtonBase>
  )
}

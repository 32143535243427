import {
  styled,
  Divider,
  Box,
  ButtonBase,
  Typography,
  TypographyProps,
} from '@mui/material'

import { colors } from '../../../theme/color'

type ButtonTextProps = TypographyProps & { selected: boolean }

export const ApIconButtonBase = styled(ButtonBase)(({ theme }) => ({
  margin: theme.spacing(1),
  marginBottom: 3,
  padding: '10px 10px 5px 10px',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: colors.background.lightblue,
  },
}))

export const IconBox = styled(Box)({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  marginBottom: 3,
})

export const ButtonText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<ButtonTextProps>(({ selected }) => ({
  fontSize: '18px',
  color: selected ? colors.primary.light : colors.text.paragraph,
}))

export const TextBox = styled(Box)({
  textAlign: 'center',
  marginBottom: 3,
  fontSize: 18,
})

export const Underline = styled(Divider)({
  backgroundColor: colors.primary.A400,
  height: '3px',
  width: '80%',
  position: 'absolute',
  bottom: 0,
})

import { styled, Box } from '@mui/material'

import { colors } from '../../../theme/color'

export const RootBox = styled(Box)({
  height: 'calc(100vh - 64px)',
  fallbacks: [
    '-moz-calc(100vh - 64px)',
    '-webkit-calc(100vh - 64px)',
    '-o-calc(100vh - 64px)',
  ],
  backgroundColor: colors.background.gray,
})

import { Box, styled, Typography, Drawer, Grid, Divider } from '@mui/material'

import { colors } from '../../../theme'

export const searchResultDrawerWidth = 580
export const ContentGrid = styled(Grid)({
  padding: 60,
  height: '100vh',
  width: searchResultDrawerWidth,
  maxWidth: searchResultDrawerWidth,
})

export const NameHeader = styled(Typography)({
  color: colors.primary.light,
})

export const ActionsBox = styled(Box)({
  textAlign: 'end',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '1.25rem 0 0 0',
  width: '100%',
})

export const DocumentIdTileStyle = {
  marginTop: '14px',
}

export const PasscodeTileStyle = {
  marginLeft: '14px',
  marginTop: '14px',
}

export const ResultsDrawer = styled(Drawer)({
  width: searchResultDrawerWidth,
  maxWidth: searchResultDrawerWidth,
  flexShrink: 0,
})

export const DrawerPaper = {
  width: searchResultDrawerWidth,
  maxWidth: searchResultDrawerWidth,
  boxSizing: 'border-box',
}

export const DocumentCodeTilesBox = styled(Box)({
  marginBottom: '10px',
})

export const DrawerDivider = styled(Divider)({
  marginTop: '20px',
})

import { GetMetadataResponseWithEmailStatuses } from '../../../client/origination-client'
import { documentStatusTypes } from './types'

export function getDocumentStatus(
  metadata: GetMetadataResponseWithEmailStatuses,
  waitTime = 10000
): documentStatusTypes {
  const currentTime = new Date()
  const documentCreatedAtTime = new Date(metadata.createdAt)

  if (metadata !== undefined) {
    if (metadata.invalidated === true) {
      return 'INVALIDATED'
    }

    if (metadata.documentStatus === 'COMPLETED') {
      return 'COMPLETED'
    }

    if (
      Math.abs(currentTime.getTime() - documentCreatedAtTime.getTime()) >
      waitTime
    ) {
      return 'FAILED'
    }
  }

  return 'PROCESSING'
}

export function getDocumentStatusDescription(
  documentStatus: documentStatusTypes,
  documentType: string
): string {
  switch (documentStatus) {
    case 'INVALIDATED':
      return `The ${documentType} has been successfully invalidated.`
    case 'FAILED':
      return `The ${documentType} has not been successfully uploaded to ApplyProof`
    case 'PROCESSING':
      return `The ${documentType} is currently being processed`
    default:
      return `The ${documentType} has been successfully uploaded to ApplyProof and has been processed.`
  }
}

export function getDocumentStatusTitle(documentStatus: string): string {
  switch (documentStatus) {
    case 'INVALIDATED':
      return 'Document Invalidated'
    case 'FAILED':
      return 'Document Upload Failed'
    case 'PROCESSING':
      return 'Document Processing'
    default:
      return 'Document Uploaded Successfully'
  }
}

export function getDocumentStatusIconSrc(documentStatus: string): string {
  switch (documentStatus) {
    case 'INVALIDATED':
      return '/Invalidate_document_icon.svg'
    case 'FAILED':
      return '/Failed_document_icon.svg'
    case 'PROCESSING':
      return '/Verify_icon.svg' //TODO: We need a Processing Icon from Product
    default:
      return '/Verify_icon.svg'
  }
}

import axios from 'axios'
import { publicIpv4 } from 'public-ip'

import { config } from '../../config'
import { getPercentageAsString } from '../../helpers/math'
import {
  CountStatus,
  ValidationStatus,
} from '../../services/ircc-workflow/types'
import { Label } from './constants'
import { getDistinctId } from './getDistinctId'
import { getSourceFromUa } from './getSourceFromUa'
import { DefaultEventProperties } from './types'

const events = [
  'Generate IRCC Matches Button Clicked',
  'IRCC Matches Success',
  'IRCC Matches Failed',
  'IRCC Generate Results Button Clicked',
  'IRCC Cancel Button Clicked',
  'IRCC File Uploaded',
  'IRCC File Removed',
  'IRCC File Upload Error',
] as const

type EventName = typeof events[number]

type TrackEventBody = {
  /** Name of the Event */
  eventName: string
  /** Properties associated with the event */
  eventProperties: Record<string, string>
  /** (Optional) Id token to identify the user triggering the event */
  idToken?: string
  /** (Optional) API headers to identify the user and source of the triggering event */
  apiHeaders?: Record<string, string>
  /** (Optional) If the event has an error */
  error?: string
}

export type EventTrackerParams = {
  /** Name of the Event */
  eventName: EventName
  /** Properties associated with the event */
  properties: Record<string, string>
  /** (Optional) If the event is an Error type */
  error?: Error
}

export class EventTracker {
  static metricsApiUrl: string | undefined
  static distinctId: string | undefined
  static accountId: string | undefined

  private static async trackEvent({
    eventName,
    properties,
    error,
  }: EventTrackerParams): Promise<void> {
    try {
      if (this.distinctId === undefined) {
        this.distinctId = await getDistinctId()
      }

      if (this.metricsApiUrl === undefined) {
        this.metricsApiUrl = config.metricsApiUrl
      }

      const ipV4 = await publicIpv4()
      const source = getSourceFromUa(navigator.userAgent)
      const distinctId = this.distinctId

      const defaultEventProperties: DefaultEventProperties = {
        distinct_id: distinctId,
        ipAddress: ipV4,
        source: JSON.stringify(source),
        origin: origin,
      }

      if (error) {
        defaultEventProperties.error = error.message
      }

      const trackEventBody: TrackEventBody = {
        eventName: eventName,
        eventProperties: {
          ...defaultEventProperties,
          ...properties,
          ip: ipV4,
          accountId: this.accountId ?? Label.Unknown,
        },
      }

      await axios({
        validateStatus: () => true,
        url: `${this.metricsApiUrl}/v1/event`,
        method: 'POST',
        data: trackEventBody,
      })
    } catch (err) {
      console.error(`Failed to track ${eventName} Event: ` + err)
    }
  }

  static getElapsedTimeInSeconds(startTime: Date): number {
    const currentTime = new Date()
    return (currentTime.getTime() - startTime.getTime()) / 1000
  }

  static trackGenerateIrccMatchesButtonClicked(): void {
    this.trackEvent({
      eventName: 'Generate IRCC Matches Button Clicked',
      properties: {
        accountId: this.accountId ?? 'Unknown',
      },
    })
  }

  static trackIrccGenerateResultsButtonClicked(): void {
    this.trackEvent({
      eventName: 'IRCC Generate Results Button Clicked',
      properties: {
        accountId: this.accountId ?? 'Unknown',
      },
    })
  }

  static trackIrccCancelButtonClicked(): void {
    this.trackEvent({
      eventName: 'IRCC Cancel Button Clicked',
      properties: {
        accountId: this.accountId ?? 'Unknown',
      },
    })
  }

  static trackIrccMatchesSuccess(
    startTime: Date,
    countTotalsInRequest: CountStatus,
    countTotalsInResponse: CountStatus
  ): void {
    const durationInSeconds = this.getElapsedTimeInSeconds(startTime).toString()

    const totalInRequest =
      countTotalsInRequest[ValidationStatus.VerifiedMatched] +
      countTotalsInRequest[ValidationStatus.VerifiedNoMatch] +
      countTotalsInRequest[ValidationStatus.NotStarted] +
      countTotalsInRequest[ValidationStatus.Cancelled]

    const percentCancelledInRequest = getPercentageAsString(
      countTotalsInRequest[ValidationStatus.Cancelled],
      totalInRequest
    )

    const percentMatchedInRequest = getPercentageAsString(
      countTotalsInRequest[ValidationStatus.VerifiedMatched],
      totalInRequest
    )

    const percentNotStartedInRequest = getPercentageAsString(
      countTotalsInRequest[ValidationStatus.NotStarted],
      totalInRequest
    )

    const totalInResponse =
      countTotalsInResponse[ValidationStatus.VerifiedMatched] +
      countTotalsInResponse[ValidationStatus.VerifiedNoMatch] +
      countTotalsInResponse[ValidationStatus.NotStarted] +
      countTotalsInResponse[ValidationStatus.Cancelled]

    const percentCancelledInResponse = getPercentageAsString(
      countTotalsInResponse[ValidationStatus.Cancelled],
      totalInResponse
    )

    const percentMatchedInReponse = getPercentageAsString(
      countTotalsInResponse[ValidationStatus.VerifiedMatched],
      totalInResponse
    )

    const percentNotMatchedInResponse = getPercentageAsString(
      countTotalsInResponse[ValidationStatus.VerifiedNoMatch],
      totalInResponse
    )

    const totalMatchedInRequest =
      countTotalsInRequest[ValidationStatus.VerifiedMatched].toString()
    const totalNoMatchInRequest =
      countTotalsInRequest[ValidationStatus.VerifiedNoMatch].toString()
    const totalCancelledInRequest =
      countTotalsInRequest[ValidationStatus.Cancelled].toString()
    const totalNotStartedInRequest =
      countTotalsInRequest[ValidationStatus.NotStarted].toString()

    const sumTotalsInRequest = totalInRequest.toString()

    const totalMatchedInResponse =
      countTotalsInResponse[ValidationStatus.VerifiedMatched].toString()
    const totalNoMatchInResponse =
      countTotalsInResponse[ValidationStatus.VerifiedNoMatch].toString()
    const totalCancelledInResponse =
      countTotalsInResponse[ValidationStatus.Cancelled].toString()
    const totalNotStartedInResponse =
      countTotalsInResponse[ValidationStatus.NotStarted].toString()

    const sumTotalsInResponse = totalInResponse.toString()

    this.trackEvent({
      eventName: 'IRCC Matches Success',
      properties: {
        durationInSeconds,
        percentNotStartedInRequest,
        percentCancelledInRequest,
        percentCancelledInResponse,
        percentMatchedInRequest,
        percentMatchedInReponse,
        percentNotMatchedInResponse,
        totalMatchedInRequest,
        totalNoMatchInRequest,
        totalCancelledInRequest,
        totalNotStartedInRequest,
        sumTotalsInRequest,
        totalMatchedInResponse,
        totalNoMatchInResponse,
        totalCancelledInResponse,
        totalNotStartedInResponse,
        sumTotalsInResponse,
      },
    })
  }

  static trackIrccMatchesFailed(errorMessage: string): void {
    this.trackEvent({
      eventName: 'IRCC Matches Failed',
      properties: {
        errorMessage,
      },
    })
  }

  static trackIrccFileError(fileExtension: string | undefined): void {
    this.trackEvent({
      eventName: 'IRCC File Upload Error',
      properties: {
        fileExtension: fileExtension ?? 'Unknown',
      },
    })
  }
  static trackIrccFileUploaded(): void {
    this.trackEvent({
      eventName: 'IRCC File Uploaded',
      properties: {},
    })
  }

  static trackIrccFileRemoved(): void {
    this.trackEvent({
      eventName: 'IRCC File Removed',
      properties: {},
    })
  }
}

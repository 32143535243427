import { CircleOutlined } from '@mui/icons-material'
import { styled } from '@mui/material'
import { Chip } from '@mui/material'

import { colors } from '../../theme'

export const ValidatedChip = styled(Chip)({
  backgroundColor: 'white',
  '&& > svg': {
    marginLeft: 0,
  },
  '.MuiChip-label': {
    fontWeight: 'normal',
  },
  alignItems: 'normal',
  color: colors.primary.light,
})

export const OpenCircleChipIcon = styled(CircleOutlined)({
  height: 16,
  width: 16,
  fill: colors.primary.light,
})

export class DocumentUpload {
  public objectUrl: string | undefined
  public fileUpload: File | undefined

  private constructor(
    public readonly name: string,
    public readonly buffer: string | ArrayBuffer,
    public size: number
  ) {}

  static fromFile(file: File): Promise<DocumentUpload> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => reject('file reading was aborted')
      reader.onerror = () => reject('file reading has failed')
      reader.onload = () => {
        if (reader.result) {
          const documentUpload = new DocumentUpload(
            file.name,
            reader.result,
            file.size
          )
          documentUpload.fileUpload = file
          documentUpload.objectUrl = URL.createObjectURL(file)
          resolve(documentUpload)
        } else {
          reject('no reader contents')
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }
}

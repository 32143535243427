// import * as yup from 'yup'

export type LoginFormFields = {
  username: string
  password: string
  newPassword?: string
  repeatPassword?: string
}

export const validate = (values: LoginFormFields): LoginFormFields => {
  const errors = {} as LoginFormFields
  if (!values.username) {
    errors.username = 'Enter Username'
  } else if (values.username.length > 256) {
    errors.username = 'Username must be 256 characters or less'
  }

  if (!values.password) {
    errors.password = 'Enter Password'
  } else if (values.password.length > 256) {
    errors.password = 'Password must be 256 characters or less'
  }

  if (values.newPassword !== undefined) {
    if (!values.newPassword) {
      errors.newPassword = 'Enter New Password'
    } else if (values.newPassword.length > 256) {
      errors.newPassword = 'New Password must be 256 characters or less'
    }
  }

  if (values.repeatPassword !== undefined) {
    if (!values.repeatPassword) {
      errors.repeatPassword = 'Re-enter Password'
    } else if (values.repeatPassword.length > 256) {
      errors.repeatPassword =
        'Re-entered Password must be 256 characters or less'
    }

    if (values.newPassword !== values.repeatPassword) {
      errors.repeatPassword = 'Re-entered password must match'
    }
  }

  return errors
}

import { styled, Box, Drawer, Button, AppBar } from '@mui/material'
import { Link } from 'react-router-dom'

import { theme } from '../../../theme'

const drawerWidth = '50vw'

export const RootBox = styled(Box)({
  flexGrow: 1,
})

export const StyledAppBar = styled(AppBar)({
  backgroundColor: 'white',
  boxShadow: `0px 4px 10px 0px #1B224B0D`,
  height: '64px',
})

export const NavBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
})

export const LogoNavLink = styled(Link)({
  textDecoration: 'none',
  paddingLeft: '50px',
})

export const NavLink = styled(Link)({
  textDecoration: 'none',
})

export const Spacer = styled(Box)({
  flexGrow: 1,
})

export const AppDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9) + 1,
  },
})

export const PaperStyle = {
  overflowX: 'hidden',
  width: (props: any) => props.theme.spacing(7) + 1,
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9) + 1,
  },
}

export const Content = styled('main')({
  flexGrow: 1,
})

export const SignOutButton = styled(Button)({
  marginTop: '10px',
})

import { Circle } from '@mui/icons-material'
import { styled } from '@mui/material'
import { Chip } from '@mui/material'

export const StatusChip = styled(Chip)({
  backgroundColor: 'white',
  '&& > svg': {
    marginLeft: 0,
  },
  '.MuiChip-label': {
    fontWeight: 'normal',
  },
  alignItems: 'normal',
})

export const FilledCircleChipIcon = styled(Circle)({
  height: 16,
  width: 16,
})

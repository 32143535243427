import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { DocumentUpload } from '../../../models/document-upload'
import { RootBox, LoadingBox, LoadingIcon } from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function DocumentPreview({
  document,
}: {
  document?: DocumentUpload
}): JSX.Element {
  const loading = (
    <LoadingBox>
      <LoadingIcon />
    </LoadingBox>
  )

  if (!document) {
    return loading
  }

  const [numPages, setNumPages] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages)
  }

  const onDocumentLoadError = () => {
    console.error('')
  }

  const scale = 0.085
  return (
    <RootBox>
      <Document
        loading={loading}
        file={document.buffer}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        <Page loading={loading} pageIndex={pageIndex} scale={scale} />
      </Document>
    </RootBox>
  )
}

import React, { useState } from 'react'

import { DocumentUpload } from '../../../models/document-upload'
import DocumentPreview from '../document-preview'
import { RootBox, CloseButton, OverlayBox, DocumentBox } from './styles'

export type DocumentTabProps = {
  document: DocumentUpload
  outline?: boolean
  onDocumentClick?: (document: DocumentUpload) => void
  onRemoveClick?: (document: DocumentUpload) => void
}

export default function DocumentTab({
  document,
  outline,
  onDocumentClick,
  onRemoveClick,
}: DocumentTabProps): JSX.Element {
  const [hovered, setHovered] = useState(false)

  const handleTabClick = () => {
    if (onDocumentClick) {
      onDocumentClick(document)
    }
  }

  const handleRemoveClick = () => {
    if (onRemoveClick) {
      onRemoveClick(document)
    }
  }

  const displayClose = hovered || outline

  return (
    <RootBox
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {displayClose ? (
        <CloseButton onClick={handleRemoveClick}>X</CloseButton>
      ) : undefined}
      <OverlayBox
        highlight={outline ?? false}
        role="button"
        tabIndex={0}
        onClick={handleTabClick}
      ></OverlayBox>
      <DocumentBox>
        <DocumentPreview document={document} />
      </DocumentBox>
      <input hidden type="file" name="file" />
    </RootBox>
  )
}

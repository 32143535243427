import { styled, Box, Grid, Typography } from '@mui/material'

import { colors } from '../../../theme/color'
import ApCopyTile from '../../ap-copy-tile/apCopyTile'

export const RootBox = styled(Box)({
  overflow: 'hidden',
})

export const ContentGrid = styled(Grid)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr auto',
  height: 'calc(100vh - 88px)',
  overflow: 'hidden',
  paddingTop: 68,
  paddingLeft: 56,
  paddingRight: 56,
})

export const InnerContent = styled(Box)({
  overflowY: 'scroll',
})

export const DocumentViewerBox = styled(Box)({
  position: 'relative',
  height: '100%',
})

export const documentViewerClass = {
  position: 'absolute',
  width: '100%',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
}

export const ActionsBox = styled(Box)({
  textAlign: 'end',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '1.25rem 0 1.75rem 0',
  width: '100%',
})

export const ExpiryTitle = styled(Typography)({
  display: 'flex',
  '& > *': {
    marginRight: '8px !important',
  },
})

export const ApCopyTileStyles = {
  marginRight: 2,
  width: 180,
  height: 80,
  marginTop: 2,
}

export const StudentName = styled(Typography)({
  color: colors.primary.A400,
})

// Indicated as Text3 on figma
export const Text3Typography = styled(Typography)({
  color: colors.primary.A50,
})

export const DocumentTitle = styled(Typography)({
  marginTop: 0,
  marginBottom: 4,
})

export const ValidationButton = styled(Typography)({
  marginRight: 20,
})

import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import RootConfirmSignUpPage from '../../pages/ConfirmSignUp'
import RootConfirmationPage from '../../pages/Confirmation'
import RootErrorPage from '../../pages/Error'
import RootForgotPasswordPage from '../../pages/ForgotPassword'
import RootLoginPage from '../../pages/Login/Login'
import RootUploadPrePage from '../../pages/PreUpload'
import RootResetPasswordPage from '../../pages/ResetPassword'
import RootResetPasswordExpiredPage from '../../pages/ResetPasswordExpired'
import RootSearchPage from '../../pages/Search'
import RootSettingsPage from '../../pages/Settings'
import RootUploadPage from '../../pages/Upload'

export default function App(): JSX.Element {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootLoginPage />} />
          <Route path="login" element={<RootLoginPage />} />
          <Route path="upload" element={<RootUploadPrePage />} />
          <Route path="upload/:documentType" element={<RootUploadPage />} />
          <Route path="confirmation/:duid" element={<RootConfirmationPage />} />
          <Route path="settings" element={<RootSettingsPage />} />
          <Route path="search" element={<RootSearchPage />} />
          <Route path="search/:duid" element={<RootConfirmationPage />} />
          <Route
            path="password/expired"
            element={<RootResetPasswordExpiredPage />}
          />
          <Route path="password/forgot" element={<RootForgotPasswordPage />} />
          <Route path="password/reset" element={<RootResetPasswordPage />} />
          <Route
            path="password/confirm-signup"
            element={<RootConfirmSignUpPage />}
          />
          <Route path="*" element={<RootErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

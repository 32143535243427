import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'

import { config } from '../../../config'
import { theme } from '../../../theme'
import ResetPasswordForm from '../reset-password-form'
import {
  ResetPasswordFormBox,
  RootContainer,
  IllustrationBox,
  Illustration,
} from './styles'

export default function ResetPasswordPage({
  confirmSignup = false,
}: {
  confirmSignup: boolean
}): JSX.Element {
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  return (
    <RootContainer maxWidth="xl">
      <Grid item container>
        <Grid item xs={12} lg={1}></Grid>
        <ResetPasswordFormBox item xs={12} md={3} lg={3}>
          <ResetPasswordForm confirmSignup={confirmSignup ?? false} />
        </ResetPasswordFormBox>
        <IllustrationBox item xs={12} md={6} lg={6}>
          {isMobile ? undefined : (
            <a href={config.applyproofUrl} target="_blank" rel="noreferrer">
              <Illustration
                alt="Applyproof illustration"
                src="/ApplyProof_illustration.svg"
                width={489}
                height={548}
              />
            </a>
          )}
        </IllustrationBox>
      </Grid>
    </RootContainer>
  )
}

import {
  styled,
  ButtonBase,
  Box,
  Typography,
  ButtonBaseProps,
} from '@mui/material'

import { colors } from '../../../theme/color'

type RootButtonBaseProps = ButtonBaseProps & { highlight: boolean }
export const RootButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<RootButtonBaseProps>(({ highlight }) => ({
  borderColor: highlight ? colors.primary.light : colors.secondary.A300,
  padding: '24px 32px',
  border: 1,
  backgroundColor: 'white',
  borderStyle: 'solid',
  borderRadius: 4,
  fontSize: 16,
  textAlign: 'start',
  '&:hover': {
    borderColor: colors.primary.light,
  },
  '&:focus': {
    borderColor: colors.primary.light,
  },
}))

export const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
})

export const Title = styled(Typography)({
  marginBottom: 4,
  fontWeight: 'bold',
})

export const IconFlexBox = styled(Box)({
  marginRight: '34px',
  minWidth: '38px',
  flexGrow: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { EventTracker } from '../../../lib/metrics'
import { CRYSTAL_COLORS } from '../../../theme'
import { DropZoneBox } from './styles'

const XLS_FILE_EXTENSIONS = ['xls', 'xlsx']
type FileUploadModalProps = {
  open: boolean
  handleClose: () => void
  handleGenerateResults: (file: File) => void
}
export function FileUploadModal(props: FileUploadModalProps) {
  const [file, setFile] = React.useState<File | undefined>(undefined)
  const [isHover, setIsHover] = React.useState(false)
  const [uploadError, setUploadError] = React.useState({
    showError: false,
    errorMessage: '',
  })
  const [generateResultsDisabled, setGenerateResultsDisabled] =
    React.useState(true)

  const handleRemoveFile = () => {
    EventTracker.trackIrccFileRemoved()

    setFile(undefined)
    setGenerateResultsDisabled(true)
    setUploadError({ showError: false, errorMessage: '' })
  }

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    const fileExtension = acceptedFiles[0].name.split('.').pop()
    if (!fileExtension || !XLS_FILE_EXTENSIONS.includes(fileExtension)) {
      EventTracker.trackIrccFileError(fileExtension)

      setUploadError({
        showError: true,
        errorMessage:
          'Unable to generate results with this file. Please ensure your are uploading the correct IRCC file format and try again.',
      })
    } else {
      EventTracker.trackIrccFileUploaded()
      setGenerateResultsDisabled(false)
    }

    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  const onClose = () => {
    EventTracker.trackIrccCancelButtonClicked()

    setFile(undefined)
    setGenerateResultsDisabled(true)
    props.handleClose()
  }

  const onGenerateResults = () => {
    EventTracker.trackIrccGenerateResultsButtonClicked()

    if (file === undefined) {
      EventTracker.trackIrccFileError(undefined)
      setUploadError({
        showError: true,
        errorMessage: 'Unable to load file. Please select a new file.',
      })
      setGenerateResultsDisabled(true)
      return
    }
    props.handleGenerateResults(file)
    setFile(undefined)
    setGenerateResultsDisabled(true)
  }

  const UploadedFileItem = (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: uploadError.showError
            ? CRYSTAL_COLORS.red.red21
            : CRYSTAL_COLORS.gray.gray21,
          padding: '12px 16px',
          borderRadius: '8px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Grid item xs={10} container alignItems="center">
          <Grid item xs={1}>
            <img
              src={
                uploadError.showError
                  ? '/Document_check_icon_red.svg'
                  : '/Document_check_icon_gray.svg'
              }
              alt="Document check icon"
              style={{ marginTop: '4px' }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: uploadError.showError
                  ? CRYSTAL_COLORS.red.red5
                  : CRYSTAL_COLORS.gray.gray5,
                fontWeight: '600',
              }}
            >
              {file ? file.name : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            {uploadError.showError ? (
              <ErrorIcon
                sx={{
                  width: '24px',
                  marginTop: '6px',
                  color: CRYSTAL_COLORS.red.red12,
                }}
              />
            ) : undefined}
          </Grid>
          <Grid item>
            <IconButton onClick={handleRemoveFile}>
              <CloseIcon
                sx={{
                  color: uploadError.showError ? CRYSTAL_COLORS.red.red5 : '',
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {uploadError.showError ? (
        <Typography sx={{ color: CRYSTAL_COLORS.red.red10 }}>
          {uploadError.errorMessage}
        </Typography>
      ) : undefined}
    </>
  )

  const FileUploadModalComponent = (
    <Modal open={props.open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
          maxHeight: '488px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '16px',
          padding: '18px',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: '2%', right: '5%' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          IRCC Matching Verification
        </Typography>
        <Divider />
        <Box margin="24px">
          <Typography sx={{ mt: 2 }}>
            Add the Excel sheet provided by IRCC below to verify against our
            records. Generated matching results can be found in your browser
            downloads for you to share with IRCC.
          </Typography>
          <br />
          <Typography sx={{ fontStyle: 'italic' }}>
            Please note that .xls and .xlsx are the only accepted file formats.
          </Typography>
          {file === undefined ? (
            <DropZoneBox
              {...getRootProps()}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              error={false}
              isDragActive={isDragActive}
              isHover={isHover}
            >
              <input {...getInputProps()} />
              <img
                alt="upload icon"
                width={85}
                height={69}
                src="/Upload_icon.svg"
              />
              Drag and drop, or
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: CRYSTAL_COLORS.blue.blue21,
                  color: CRYSTAL_COLORS.blue.blue09,
                  borderColor: CRYSTAL_COLORS.blue.blue15,
                  borderRadius: '8px',
                }}
              >
                Browse Files
              </Button>
            </DropZoneBox>
          ) : (
            <>{UploadedFileItem}</>
          )}
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing="2px"
        >
          <Grid item xs={6}>
            <Button
              sx={{
                width: '100%',
                backgroundColor: CRYSTAL_COLORS.gray.gray21,
                color: CRYSTAL_COLORS.gray.gray5,
                '&:hover': {
                  backgroundColor: CRYSTAL_COLORS.gray.gray18,
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{ width: '100%' }}
              disabled={generateResultsDisabled}
              onClick={onGenerateResults}
            >
              Generate Results
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )

  return FileUploadModalComponent
}

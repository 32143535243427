import { CognitoUserPool } from 'amazon-cognito-identity-js'

import { config } from '../../config'

const poolData = {
  UserPoolId: config.cognitoUserPool ?? '',
  ClientId: config.cognitoClientId ?? '',
}

export default new CognitoUserPool(poolData)

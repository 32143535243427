import { styled, Grid, TextField, Typography } from '@mui/material'

import { colors } from '../../../theme'

export const RootGrid = styled(Grid)({
  borderRadius: 8,
  paddingTop: '1.8rem',
  paddingBottom: '1.0rem',
  paddingLeft: '2.0rem',
  paddingRight: '2.0rem',
  backgroundColor: colors.background.white,
  boxShadow: `0px 3px 20px ${colors.boxShadow.default}`,
  color: colors.text.paragraph,
  padding: '1.3rem',
  border: 1,
  borderStyle: 'solid',
  borderColor: colors.border.solid,
})

export const LoginHeader = styled(Typography)({
  marginTop: 48,
  marginBottom: 8,
})

export const InputField = styled(TextField)({
  marginBottom: '0.6rem',
})

export const StyledGridItem = styled(Grid)({
  padding: '24px',
})

import * as ExcelJS from 'exceljs'
import { createSearchClient, StudentRecord } from '../../client/search-client'
import { FileError } from '../../errors/FileError'
import { CountStatus, ValidationStatus } from './types'

enum KEY_MAPPING {
  IrccIdentifier = 1,
  ValidationStatus = 2,
  ValidationDeadline = 3,
  StudentId = 4,
  LastName = 5,
  FirstName = 6,
  DateOfBirth = 7,
}

export async function read(
  data: ArrayBuffer
): Promise<{ worksheet: ExcelJS.Worksheet; workbook: ExcelJS.Workbook }> {
  const workbook = new ExcelJS.Workbook()
  await workbook.xlsx.load(data)

  const worksheet = workbook.getWorksheet(1)
  if (!worksheet) {
    throw new FileError('Unable to read worksheet.')
  }

  return { workbook, worksheet }
}

/** Reads every row from worksheet, sends it to /match endpoint to verify the record is valid, and updates the workbook */
export async function verify({
  workbook,
  worksheet,
}: {
  worksheet: ExcelJS.Worksheet
  workbook: ExcelJS.Workbook
}) {
  const currentStudentRecords: Array<StudentRecord> = []
  const validationCellsMap: { [key: string]: ExcelJS.Cell } = {}
  const searchClient = createSearchClient()
  // Skip first row because its the headers
  const rows = worksheet.getRows(2, worksheet.actualRowCount - 1)
  let rowNumber = 2

  if (!rows) {
    throw new FileError('Unable to read file. No rows found.')
  }

  for (const row of rows) {
    const validationStatusCell = row.getCell(KEY_MAPPING.ValidationStatus)
    const currentStatus = validationStatusCell.value as string

    const studentId = String(row.getCell(KEY_MAPPING.StudentId).value)
    const firstName = String(row.getCell(KEY_MAPPING.FirstName).value)
    const lastName = String(row.getCell(KEY_MAPPING.LastName).value)
    const dateOfBirthCA = row.getCell(KEY_MAPPING.DateOfBirth).value

    let dateOfBirth = ''
    if (dateOfBirthCA instanceof Date) {
      dateOfBirth = dateOfBirthCA.toLocaleDateString('en-CA', {
        timeZone: 'UTC',
      })
    } else if (typeof dateOfBirthCA === 'string') {
      dateOfBirth = dateOfBirthCA
    }

    currentStudentRecords.push({
      studentId,
      firstName,
      lastName,
      dateOfBirth,
      status: currentStatus,
    })
    validationCellsMap[rowNumber] = validationStatusCell

    rowNumber += 1
  }

  const newStudentRecords = await searchClient.match(currentStudentRecords)
  let newRecordsRowNumber = 2
  for (const studentRecord of newStudentRecords) {
    const cell = validationCellsMap[newRecordsRowNumber]
    cell.value = studentRecord.status
    newRecordsRowNumber += 1
  }

  return { workbook, worksheet }
}

/** Reads every row from worksheet, sends it to /match endpoint to verify the record is valid, and updates the workbook */
export function getStatusCounts(worksheet: ExcelJS.Worksheet): CountStatus {
  const countStatuses: CountStatus = {
    'Not Started': 0,
    'Verified – Matched': 0,
    'Verified – No Match': 0,
    Cancelled: 0,
  }
  // Skip first row because its the headers
  const rows = worksheet.getRows(2, worksheet.actualRowCount - 1)

  if (!rows) {
    throw new FileError('Unable to read file. No rows found.')
  }

  for (const row of rows) {
    const validationStatusCell = row.getCell(KEY_MAPPING.ValidationStatus)
    const currentStatus = validationStatusCell.value as ValidationStatus
    countStatuses[currentStatus]++
  }

  return countStatuses
}

export async function writeAndDownload(
  workbook: ExcelJS.Workbook,
  filename: string
) {
  try {
    const buffer = await workbook.xlsx.writeBuffer({ useStyles: true })

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = 'verified_results_' + filename

    document.body.appendChild(a)
    a.click()

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  } catch (err) {
    throw new FileError('Failed to write results file.')
  }
}

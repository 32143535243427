import { Box, Divider, Grid } from '@mui/material'
import { CancelTokenSource } from 'axios'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { useUser } from '../../../client/auth'
import { createOriginationClient } from '../../../client/origination-client'
import { DocumentUploadBody } from '../../../client/origination-client'
import {
  createSettingsClient,
  LegacyEmailSettings,
} from '../../../client/settings-client'
import { DocumentUpload } from '../../../models/document-upload'
import { StudentInfo } from '../../../models/student-info'
import { PdfExtractionValues } from '../../../services/pdf-extract'
import { colors } from '../../../theme'
import DocumentProgressDialog from '../document-progress-dialog'
import DocumentUploadPreview from '../document-upload-preview'
import UploadForm from '../upload-form'
import { RootBox } from './styles'

export type UploadPageProps = {
  documentType: string
}

export default function UploadPage({
  documentType,
}: UploadPageProps): JSX.Element {
  const user = useUser()
  const navigate = useNavigate()

  if (user === null) {
    navigate('/', { replace: true })
  }

  const [documents, setDocuments] = useState<Array<DocumentUpload>>([])
  const [documentsNotAttached, setDocumentsNotAttached] = useState(false)
  const [documentAttachedCount, setDocumentAttachedCount] = useState(0)

  const [emailSettings, setEmailSettings] = useState<
    LegacyEmailSettings | undefined
  >(undefined)

  const [uploadDialogError, setUploadDialogError] = useState('')
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false)
  const [uploadDialogProgress, setUploadDialogProgress] = useState(0)
  // const [uploadCancelToken, setUploadCancelToken] =
  //   useState<CancelTokenSource | null>(null)

  const [pdfExtractionValues, setPdfExtractionValues] = useState<
    PdfExtractionValues | undefined
  >(undefined)

  const handleDialogCancelled = (): void => {
    // uploadCancelToken?.cancel()
    setUploadDialogOpened(false)
    setUploadDialogProgress(0)
  }

  const handleAttemptSubmitWithoutDocuments = (): void => {
    setDocumentsNotAttached(true)
  }

  const key = user ? '/api/settings' : null
  const client = createSettingsClient()
  useSWR(
    key,
    async () => {
      if (user) {
        try {
          const settings = await client.getEmailSettings()
          setEmailSettings(settings)
        } catch (err) {
          console.log('no email settings for user')
        }
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const handleStudentDetailsSubmit = (studentInfo: StudentInfo): void => {
    try {
      if (user === undefined || documents.length === 0) {
        return
      }

      setDocumentsNotAttached(false)
      setUploadDialogOpened(true)

      if (studentInfo.lastName === undefined) {
        throw new Error(`Cannot create upload request without last name.`)
      }

      if (
        studentInfo.dateOfBirth === null ||
        studentInfo.dateOfBirth === undefined
      ) {
        throw new Error(`Cannot create upload request without date of birth.`)
      }

      const client = createOriginationClient()

      const actions: NonNullable<DocumentUploadBody['actions']> = []

      if (emailSettings && emailSettings.emailEnabled) {
        actions.push({
          actionType: 'EMAIL',
          to: [studentInfo.email],
          cc: studentInfo.emailCc,
          bcc: studentInfo.emailBcc,
          body: emailSettings.emailBody,
          subject: emailSettings.emailSubject,
        })
      }

      const dateOfBirth = dayjs(studentInfo.dateOfBirth).format('YYYY/MM/DD')

      const uploadRequest: DocumentUploadBody = {
        primaryRecipient: {
          firstName: studentInfo.firstName,
          lastName: studentInfo.lastName,
          email: studentInfo.email,
          dateOfBirth: dateOfBirth,
          id: studentInfo.studentId,
        },
        secondaryRecipients: [
          ...studentInfo.emailCc,
          ...studentInfo.emailBcc,
        ].map((e) => {
          return {
            email: e,
          }
        }),
        documentType: documentType,
        actions: actions,
        passcode: dateOfBirth,
      }
      if (studentInfo.expiryDate) {
        uploadRequest.expiry = dayjs(studentInfo.expiryDate).format(
          'YYYY/MM/DD'
        )
      }

      const documentBuffers: Array<Blob> = Array.from(
        documents
          .filter(({ fileUpload }) => fileUpload !== undefined)
          .map(({ fileUpload }) => fileUpload!)
      )

      if (user) {
        setUploadDialogError('')
        client
          .upload(
            uploadRequest,
            documentBuffers,
            (progressEvent: ProgressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadDialogProgress(progress)
            }
          )
          .then(({ duid }) => {
            navigate(`/confirmation/${duid}`)
          })
          .catch((err) => {
            console.error(err.stack)
            setUploadDialogError(err.message)
          })
      } else {
        navigate('/', { replace: true })
      }
    } catch (err) {
      setUploadDialogError(
        'Failure to process form. Please try again or contact ApplyProof support'
      )
    }
  }

  const handleDocumentsChanged = async (
    documents: Array<DocumentUpload>
  ): Promise<void> => {
    setDocuments(documents)
    setDocumentAttachedCount(documents.length)
  }

  const handleDocumentValuesExtracted = (values: PdfExtractionValues): void => {
    setPdfExtractionValues(values)
  }

  if (!user) {
    return <div></div>
  }

  return (
    <RootBox>
      <Grid container spacing={0}>
        <Grid item xs={6} sx={{ height: 'calc(100vh - 64px)' }}>
          <UploadForm
            documentAttachedCount={documentAttachedCount}
            emailSettings={emailSettings}
            onSubmit={handleStudentDetailsSubmit}
            onAttemptSubmitWithoutDocuments={
              handleAttemptSubmitWithoutDocuments
            }
            extractionValues={pdfExtractionValues}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: 'calc(100vh - 64px)' }}>
          <DocumentUploadPreview
            documentType={documentType}
            dropzoneError={documentsNotAttached}
            onDocumentsChanged={handleDocumentsChanged}
            onDocumentValuesExtracted={handleDocumentValuesExtracted}
          />
        </Grid>
      </Grid>
      <DocumentProgressDialog
        progress={uploadDialogProgress}
        open={uploadDialogOpened}
        error={Boolean(uploadDialogError)}
        errorText={uploadDialogError || ''}
        onCancelled={handleDialogCancelled}
      />
    </RootBox>
  )
}

import jwtDecode from 'jwt-decode'

export type OriginatorData = {
  email: string
  accountId: string
}

/**
 * Return type of decoded Cognito JWT from Portal user pool.
 */
export type CognitoJwt = {
  sub: string
  email_verified: boolean
  iss: string
  'cognito:username': string
  origin_jti: string
  aud: string
  event_id: string
  token_use: 'id'
  auth_time: number
  exp: number
  iat: number
  jti: string
  email: string
  'custom:accountId': string
  scope: string
}

export class CognitoService {
  static decodeCognitoJwt(jwt: string): CognitoJwt {
    let decoded: CognitoJwt
    try {
      decoded = jwtDecode(jwt)
    } catch (err) {
      console.warn({ err })
      throw new Error(`Could not decode jwt`)
    }

    if (decoded['custom:accountId'] === undefined) {
      console.warn({ decoded }, 'no schoolId found')
    }

    return decoded
  }

  static getOriginator(idToken: string): OriginatorData {
    const jwt = CognitoService.decodeCognitoJwt(idToken)

    return {
      email: jwt.email,
      accountId: jwt['custom:accountId'],
    }
  }
}

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'

import { ExpiryDateIcon, ExpiryChip } from './styles'

dayjs.extend(LocalizedFormat)

type ExpiryDateChipProps = {
  expiryDate: number
}

export default function ExpiryDateChip({
  expiryDate,
}: ExpiryDateChipProps): JSX.Element {
  return (
    <>
      <ExpiryChip
        icon={<ExpiryDateIcon alt="expiry icon" src="/expiry_icon.svg" />}
        label={`Expires on ${dayjs(expiryDate).format('ll')}`}
        variant="filled"
      />
    </>
  )
}

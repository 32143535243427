import { Box } from '@mui/material'
import React, { useRef } from 'react'

import { DocumentUpload } from '../../../models/document-upload'
import DocumentTab from '../document-tab'
import {
  DocumentTabBox,
  DocumentTabScrollBox,
  DocumentTabFlexBox,
  AddFileButton,
  AddSubtitle,
} from './styles'

export default function DocumentTabs({
  selectedDocument,
  documents,
  onDocumentAdded,
  onDocumentClicked,
  onDocumentRemoved,
}: {
  selectedDocument?: DocumentUpload
  documents: Array<DocumentUpload>
  onDocumentAdded: (document: DocumentUpload) => void
  onDocumentClicked: (document: DocumentUpload) => void
  onDocumentRemoved: (document: DocumentUpload) => void
}): JSX.Element {
  const fileInput = useRef<HTMLInputElement>(null)

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      for (const file of Array.from(e.target.files)) {
        onDocumentAdded(await DocumentUpload.fromFile(file))
      }
    }
  }

  const handleAddClick = async () => {
    const current = fileInput.current
    if (current) {
      current.click()
    }
  }

  const handleDocumentClick = (document: DocumentUpload) => {
    onDocumentClicked(document)
  }

  const handleRemoveClick = (document: DocumentUpload) => {
    onDocumentRemoved(document)
  }

  const documentTabs = documents.map((document, index) => {
    let additionalProps = {}
    if (document === selectedDocument) {
      additionalProps = { outline: true }
    }
    return (
      <DocumentTabBox key={index}>
        <DocumentTab
          document={document}
          onDocumentClick={handleDocumentClick}
          onRemoveClick={handleRemoveClick}
          {...additionalProps}
        />
      </DocumentTabBox>
    )
  })

  return (
    <DocumentTabScrollBox>
      <DocumentTabFlexBox>
        {documentTabs}
        <AddFileButton onClick={handleAddClick}>
          <Box>
            <input
              hidden
              type="file"
              accept="application/pdf"
              multiple
              ref={fileInput}
              onChange={handleFileSelected}
            />
            <img
              src="/add_icon24x24.svg"
              width={24}
              height={24}
              alt="add icon"
            />
            <AddSubtitle variant="subtitle2">Add</AddSubtitle>
          </Box>
        </AddFileButton>
      </DocumentTabFlexBox>
    </DocumentTabScrollBox>
  )
}

import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'

import { config } from '../../../config'
import { theme } from '../../../theme'
import ForgotPasswordForm from '../forgot-password-form'
import {
  RootContainer,
  LoginFormBox,
  IllustrationBox,
  Illustration,
} from './styles'

export default function LoginPage(): JSX.Element {
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  return (
    <RootContainer maxWidth="xl">
      <Grid item container>
        <Grid item xs={12} lg={1}></Grid>
        <LoginFormBox item xs={12} md={3} lg={3}>
          <ForgotPasswordForm />
        </LoginFormBox>
        <IllustrationBox item xs={12} md={6} lg={6}>
          {isMobile ? undefined : (
            <a href={config.applyproofUrl} target="_blank" rel="noreferrer">
              <Illustration
                alt="applyproof illustration"
                src="/ApplyProof_illustration.svg"
                width={489}
                height={548}
              />
            </a>
          )}
        </IllustrationBox>
      </Grid>
    </RootContainer>
  )
}

import { OpenSearchRequestBody, SearchRequestBody } from '.'

/**
 *  Flattened fields which are supported for searching.
 */
const SEARCHABLE_FLATTENED_FIELDS = [
  'duid',
  'originator.email',
  'primaryRecipient.email',
  'primaryRecipient.firstName',
  'primaryRecipient.id',
  'primaryRecipient.lastName',
] as const

/**
 *  Nested fields which are supported for searching.
 */
const SEARCHABLE_NESTED_FIELDS: Record<string, string> = {
  //path->name
  queryableActionStatuses: 'queryableActionStatuses.email.keyword',
  secondaryRecipients: 'secondaryRecipients.email.keyword',
}

type FullTextMatchRequest = {
  name: string
  value: unknown
}

type TermMatchRequest = {
  name: string
  value: unknown
}

type SimpleQueryStringRequest = {
  fields: readonly string[]
  value: unknown
}

type RangeMatchRequest = {
  name: string
  lowerBound: number
  upperBound: number
}

type NestedFieldMatch = {
  name: string
  path: string
  value: unknown
}

function constructRangeMatch({
  name,
  lowerBound,
  upperBound,
}: RangeMatchRequest): Record<string, unknown> {
  return {
    range: {
      [name]: {
        gte: lowerBound,
        lte: upperBound,
      },
    },
  }
}

function constructTermMatch({
  name,
  value,
}: TermMatchRequest): Record<string, unknown> {
  return {
    term: {
      [name]: value,
    },
  }
}

function constructFullTextMatch({
  name,
  value,
}: FullTextMatchRequest): Record<string, unknown> {
  return {
    match: {
      [name]: value,
    },
  }
}

function constructNestedFieldMatch({
  name,
  path,
  value,
}: NestedFieldMatch): Record<string, unknown> {
  return {
    nested: {
      query: {
        wildcard: {
          [name]: {
            value: value,
          },
        },
      },
      path: path,
    },
  }
}

/**
 * This function creates single query for each searchable nested field.
 * @param value Value to be searched for.
 * @returns A list of OpenSearch queries.
 */
function constructQueryForAllNestedFields(
  value: unknown
): Record<string, unknown>[] {
  const queries: Record<string, unknown>[] = []
  for (const [key, name] of Object.entries(SEARCHABLE_NESTED_FIELDS)) {
    queries.push(
      constructNestedFieldMatch({
        name: name,
        path: key,
        value: value,
      })
    )
  }
  return queries
}

function constructSimpleQueryString({
  fields,
  value,
}: SimpleQueryStringRequest): Record<string, unknown> {
  return {
    simple_query_string: {
      query: value,
      fields: fields,
      analyze_wildcard: true,
      default_operator: 'AND',
    },
  }
}

function constructApplyProofQuery(
  searchRequest: SearchRequestBody
): Record<string, unknown>[] {
  const queries: Record<string, unknown>[] = []

  if (searchRequest.documentStatus === 'VALIDATED') {
    queries.push(constructFullTextMatch({ name: 'invalidated', value: false }))
    queries.push(
      constructFullTextMatch({ name: 'documentStatus', value: 'COMPLETED' })
    )
  } else if (searchRequest.documentStatus === 'INVALIDATED') {
    queries.push(constructFullTextMatch({ name: 'invalidated', value: true }))
  }

  if (searchRequest.documentType !== undefined) {
    queries.push(
      constructFullTextMatch({
        name: 'documentType',
        value: searchRequest.documentType,
      })
    )
  }

  if (searchRequest.primaryRecipientEmailDeliveryStatus !== undefined) {
    queries.push(
      constructFullTextMatch({
        name: 'primaryRecipientEmailDeliveryStatus',
        value: searchRequest.primaryRecipientEmailDeliveryStatus,
      })
    )
  }

  if (
    searchRequest.validatedTimeRangeLower !== undefined &&
    searchRequest.validatedTimeRangeUpper !== undefined
  ) {
    queries.push(
      constructRangeMatch({
        name: 'createdAt',
        lowerBound: searchRequest.validatedTimeRangeLower,
        upperBound: searchRequest.validatedTimeRangeUpper,
      })
    )
  }

  return queries
}

/**
 * This function converts the request from the search page to OpenSearch proxy request.
 * @param searchRequest Request from the search page.
 * @returns OpenSearch proxy request.
 */
export function constructQuery(
  searchRequest: SearchRequestBody
): OpenSearchRequestBody {
  return {
    from: searchRequest.from === undefined ? 0 : searchRequest.from,
    size: searchRequest.size === undefined ? 10 : searchRequest.size,
    must: constructApplyProofQuery(searchRequest),
    must_not:
      searchRequest.documentStatus !== undefined &&
      searchRequest.documentStatus === 'FAILED'
        ? [
            //Term match Metadata with exact words. Full-text can return irrelevant Metadata.
            constructTermMatch({
              name: 'documentStatus.keyword',
              value: 'COMPLETED',
            }),
          ]
        : undefined,
    should:
      searchRequest.query !== undefined
        ? [
            ...constructQueryForAllNestedFields(`*${searchRequest.query}*`),
            constructSimpleQueryString({
              fields: SEARCHABLE_FLATTENED_FIELDS,
              value: `*${searchRequest.query}*`,
            }),
          ]
        : undefined,
    minimum_should_match: searchRequest.query ? 1 : undefined,
    sort: [
      {
        createdAt: {
          order: 'desc',
        },
      },
    ],
  }
}

import axios from 'axios'

import {
  DocumentType,
  DocumentTypes,
  EmailSettings,
  LegacyEmailSettings,
  PdfExtractionDefinitions,
  UpdateEmailSettingsBody,
} from '.'
import config from '../../config.json'
import { BaseClient } from '../base-client'

export interface SettingsClientProps {
  baseUrl: string
}

export class PortalClient extends BaseClient {
  private readonly baseUrl: string

  constructor(props: SettingsClientProps) {
    super()
    this.baseUrl = props.baseUrl
  }

  private settingsToLegacySettings(
    settings: EmailSettings
  ): LegacyEmailSettings {
    return {
      emailEnabled: settings.enabled,
      emailSubject: settings.subject,
      emailBody: settings.body,
      emailCc: settings.cc,
      emailBcc: settings.bcc,
    }
  }

  async getEmailSettings(): Promise<LegacyEmailSettings> {
    const idToken = await this.getIdToken()
    const response = await axios({
      validateStatus: () => true,
      url: `https://portal.api.${this.baseUrl}/v1/settings/email`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (response.status === 200) {
      const data: EmailSettings = response.data
      return this.settingsToLegacySettings(data)
    } else {
      // sane default on error
      return {
        emailEnabled: false,
        emailSubject: '',
        emailCc: [],
        emailBcc: [],
        emailBody: '',
      }
    }
  }

  async updateEmailSettings(
    emailSettings: UpdateEmailSettingsBody
  ): Promise<LegacyEmailSettings> {
    const idToken = await this.getIdToken()
    const response = await axios({
      url: `https://portal.api.${this.baseUrl}/v1/settings/email`,
      method: 'POST',
      data: emailSettings,
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    const data: EmailSettings = response.data
    return this.settingsToLegacySettings(data)
  }

  async getDocumentTypes(): Promise<DocumentTypes> {
    const idToken = await this.getIdToken()
    try {
      const response = await axios({
        url: `https://portal.api.${this.baseUrl}/v1/settings/document-types`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      const data: DocumentTypes = response.data
      return data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          return {
            pkey: '',
            documentTypes: ['POA'],
          }
        }
      }
      throw err
    }
  }

  async getPdfExtractionDefinitions(
    documentType: DocumentType
  ): Promise<PdfExtractionDefinitions> {
    const idToken = await this.getIdToken()
    try {
      const response = await axios({
        url: `https://portal.api.${this.baseUrl}/v1/settings/pdf-extraction-definitions/${documentType}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      const data: PdfExtractionDefinitions = response.data.extractions
      return data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          console.error(`No definitions found. ${err}`)
        }
      }
      return []
    }
  }
}

export function createSettingsClient() {
  return new PortalClient({ baseUrl: config.hostedZoneName })
}

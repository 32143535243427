import UAParser from 'ua-parser-js'

import { Label } from './constants'
import { EventSource } from './types'

export function getSourceFromUa(
  userAgentString: string | undefined
): EventSource {
  if (!userAgentString) {
    return { application: Label.Unknown }
  }

  const uaParser = new UAParser()
  uaParser.setUA(userAgentString)
  const { ua, device, browser, os } = uaParser.getResult()

  /**
   * UA Syntax: <product> / <product-version> <comment>
   * ex. PostmanRuntime/7.28.4
   */
  const uaProduct = ua.split('/')[0]

  return {
    application: browser.name ?? uaProduct,
    device: device.type ?? Label.Unknown,
    os: os.name ?? Label.Unknown,
  }
}

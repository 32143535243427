import * as yup from 'yup'

export type FormFields = {
  type: string
  query: string
}

export const validationSchema = yup.object().shape({
  type: yup.string().required(),
  query: yup.string().optional(),
})

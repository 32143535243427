import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'

import { GetMetadataResponseWithEmailStatuses } from '../../client/origination-client'
import { colors } from '../../theme'
import { StatusChip, FilledCircleChipIcon } from './styles'

export type InvalidatedChipProps = {
  status?: GetMetadataResponseWithEmailStatuses['documentStatus'] | 'PROCESSING'
  invalidated: GetMetadataResponseWithEmailStatuses['invalidated']
  createdAt: Date | undefined
}

dayjs.extend(LocalizedFormat)

export default function InvalidatedChip({
  status,
  invalidated,
  createdAt,
}: InvalidatedChipProps): JSX.Element {
  const statusComponent = (): JSX.Element | undefined => {
    if (invalidated) {
      return (
        <StatusChip
          icon={
            <FilledCircleChipIcon style={{ color: colors.secondary.A300 }} />
          }
          label="Invalidated"
          variant="filled"
          style={{ color: colors.secondary.A300 }}
        />
      )
    }

    switch (status) {
      case 'INITIATED':
      case 'RECEIVED':
      case 'SIGNED':
        return (
          <StatusChip
            icon={
              <FilledCircleChipIcon
                style={{ color: colors.accent.red.light }}
              />
            }
            label="Failed"
            variant="filled"
            style={{ color: colors.accent.red.light }}
          />
        )
      case 'COMPLETED':
        return (
          <StatusChip
            icon={
              <FilledCircleChipIcon
                style={{ color: colors.accent.green.dark }}
              />
            }
            label={
              createdAt
                ? `Validated on ${dayjs(createdAt).format('ll')}`
                : 'Validated'
            }
            variant="filled"
            style={{ color: colors.accent.green.dark }}
          />
        )
      case 'PROCESSING':
        return (
          <StatusChip
            icon={
              <FilledCircleChipIcon style={{ color: colors.secondary.A400 }} />
            }
            label={'Processing'}
            variant="filled"
            style={{ color: colors.secondary.A400 }}
          />
        )
      default:
        return undefined
    }
  }

  return <>{statusComponent()}</>
}

import { AxiosResponse, CancelTokenSource } from 'axios'

import { CognitoAuth } from '../auth'
import { ApplyProofUser } from '../auth/auth'

export type CancelResponse<T> = {
  response: AxiosResponse<T>
  cancelTokenSource: CancelTokenSource
}

export type ConfirmationStatus =
  | 'FORCE_CHANGE_PASSWORD'
  | 'RESET_REQUIRED'
  | 'UNKNOWN'
  | 'COMPROMISED'
  | 'ARCHIVED'
  | 'UNCONFIRMED'
  | 'CONFIRMED'
  | string

export type User = {
  username: string
  email: string
  phone?: string
  firstName: string
  lastName: string
  accountId: string
  enabled: boolean
  confirmationStatus: ConfirmationStatus
}

export type ConfirmUserParams = {
  username: User['username']
  temporaryPassword: string
  newPassword: string
}

export class PortalClient {
  async conditionallyRefreshSession(): Promise<void> {
    const auth = new CognitoAuth()
    await auth.refreshSessionIfExpired()
  }

  // TODO TALK TO ROUTE IN SETTINGS API
  // async getPdfExtractDefinitions(
  //   user: ApplyProofUser,
  //   documentType: string
  // ): Promise<AxiosResponse<PdfExtractionApiResponse>> {
  //   await this.conditionallyRefreshSession()
  //   return await Axios({
  //     method: 'GET',
  //     url: `/api/pdf-extract-definitions?documentType=${documentType}`,
  //     headers: headersFromUser(user),
  //   })
  // }

  async sendResetPasswordEmail(username: string): Promise<void> {
    const auth = new CognitoAuth()
    const cognitoUser = auth.currentUser(username)

    return new Promise((resolve, reject) =>
      cognitoUser.forgotPassword({
        onSuccess: function () {
          resolve()
        },
        onFailure: function (err: unknown) {
          reject()
        },
      })
    )
  }

  async confirmResetPassword({
    username,
    resetCode,
    newPassword,
  }: {
    username: string
    resetCode: string
    newPassword: string
  }): Promise<void> {
    const auth = new CognitoAuth()
    const cognitoUser = auth.currentUser(username)

    return new Promise((resolve, reject) =>
      cognitoUser.confirmPassword(resetCode, newPassword, {
        onSuccess: function () {
          resolve()
        },
        onFailure: function (err: unknown) {
          reject(err)
        },
      })
    )
  }
}

function headersFromUser(user: ApplyProofUser): Record<string, string> {
  return {
    'x-id-token': user.idToken,
    'x-access-token': user.accessToken,
  }
}

import { LoadingButton } from '@mui/lab'
import { styled, Grid, Typography, TextField } from '@mui/material'
import React from 'react'

import { colors } from '../../../theme/'

export const RootGrid = styled(Grid)({
  borderRadius: 8,
  padding: '1.8rem 2.0rem 1.0rem',
  backgroundColor: colors.background.white,
  boxShadow: `0px 3px 20px ${colors.boxShadow.default}`,
  color: colors.text.paragraph,
  border: 1,
  borderStyle: 'solid',
  borderColor: colors.border.solid,
})

export const StyledGridItem = styled(Grid)({
  padding: '24px',
})

export const LoginHeader = styled(Typography)({
  marginTop: '48px',
  marginBottom: '8px',
})

export const InputField = styled(TextField)({
  marginBottom: '0.6rem',
})

export const SubmitButton = styled(LoadingButton)({
  marginTop: '40px',
})
export const passwordStrengthSatisfiedClass: React.CSSProperties = {
  color: '#46B082 !important',
}

export const passwordStrengthNotSatisfiedClass: React.CSSProperties = {
  color: '#FF5F57 !important',
}

export const passwordStrengthNotSatisfiedWhiteClass: React.CSSProperties = {
  color: '#FFFFFF !important',
}

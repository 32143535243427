export const CRYSTAL_COLORS = {
  blue: {
    blue01: '#000F33',
    blue07: '#0044AB',
    blue09: '#0A5ADA',
    blue10: '#0254F0',
    blue15: '#83B2FE',
    blue21: '#F5F8FF',
  },
  white: '#FFFFFF',
  gray: {
    gray5: '#363B51',
    gray8: '#4F5875',
    gray10: '#616D92',
    gray12: '#7682A1',
    gray15: '#A9B1CC',
    gray18: '#D2D6E1',
    gray21: '#F6F8FE',
  },
  red: {
    red5: '#76132D',
    red10: '#D32252',
    red12: '#FA3E70',
    red21: '#FEF6F8',
  },
}

export const colors = {
  primary: {
    light: CRYSTAL_COLORS.blue.blue10,
    dark: '#1B224B',
    A50: '#000F33',
    A400: '#0448CB',
  },
  secondary: {
    light: '#E9EBF0',
    dark: '#7682A1',
    A300: '#A3A8B5',
    A400: '#C4C9D7',
    A500: '#F2F5F8',
  },
  accent: {
    red: {
      light: '#E32645',
      dark: '#E12852',
    },
    yellow: {
      dark: '#E27600',
    },
    green: {
      light: '#46B082',
      dark: '#3B936A',
    },
  },
  boxShadow: {
    default: '#00000029',
  },
  login: {
    background: '#004DC0',
    tooltip: '#000F33',
  },
  font: {
    default: {
      header: '#000F33',
      body: '#565F76',
    },
  },
  translucent: {
    blue: '#0064E127',
    blue20: '#DFECFF',
  },
  text: {
    white: '#FFFFFF',
    black: '#000000',
    paragraph: '#6B7592',
    noclue: '#152546',
  },
  background: {
    white: '#FFFFFF',
    primary: '#0056D5',
    gray: '#F5F7FA',
    lightblue: '#F2F6FC',
    translucentRed: 'rgba(251, 217, 219, 0.35)',
  },
  border: {
    solid: '#C4C9D7',
  },
}

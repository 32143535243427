import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './components/app/App'
import { theme } from './theme'

const container = document.getElementById('root')
if (container === null) throw new Error('Root container missing in index.html')
const root = createRoot(container)
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
)

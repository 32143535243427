import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import { SimplifiedEmailStatus } from '../../client/origination-client'
import { SearchResponseBodyWithEmailStatuses } from '../../client/search-client'
import EmailStatusChip from '../email-status-chip'
import { EmailsBox, EmailFieldHeader } from './styles'

type EmailRecipientsType = {
  searchResults:
    | SearchResponseBodyWithEmailStatuses['matchedMetaData'][number]
    | undefined
}
export default function EmailRecipients({
  searchResults: result,
}: EmailRecipientsType): JSX.Element {
  const getEmailAndStatuses = (emailArray: Array<SimplifiedEmailStatus>) => {
    return emailArray.map((e) => {
      return (
        <Grid item xs={12} key={e.email}>
          <EmailStatusChip
            primaryRecipient={e.email}
            primaryRecipientEmailStatus={e.status}
          />
        </Grid>
      )
    })
  }

  return (
    <>
      <Box>
        <Typography variant="h3" component="h3">
          Recipient Emails
        </Typography>
      </Box>
      <EmailsBox style={{ marginTop: 4 }}>
        <Grid container>
          <Grid item xs={12}>
            <EmailFieldHeader>To:</EmailFieldHeader>
          </Grid>
          <Grid container>
            {result && result?.emailTo.length !== 0 ? (
              getEmailAndStatuses(result.emailTo)
            ) : (
              <Typography>N/A</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <EmailFieldHeader>CC:</EmailFieldHeader>
          </Grid>
          <Grid container>
            {result && result?.emailCc.length !== 0 ? (
              getEmailAndStatuses(result.emailCc)
            ) : (
              <Typography>N/A</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <EmailFieldHeader>BCC:</EmailFieldHeader>
          </Grid>
          <Grid container>
            {result && result?.emailBcc.length !== 0 ? (
              getEmailAndStatuses(result.emailBcc)
            ) : (
              <Typography>N/A</Typography>
            )}
          </Grid>
        </Grid>
      </EmailsBox>
    </>
  )
}

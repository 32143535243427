import { CognitoService } from '../../lib/cognito/CognitoService'
import { EventTracker } from '../../lib/metrics'
import { CognitoAuth } from '../auth'

export class BaseClient {
  constructor(private readonly auth = new CognitoAuth()) {}

  async getIdToken(): Promise<string> {
    await this.auth.refreshSessionIfExpired()

    const session = this.auth.currentSession()
    if (!session) {
      throw new Error('Could not get token, could not get session')
    }

    const idToken = session.current.getIdToken()
    if (!idToken) {
      throw new Error('Could not get token, could not get idToken')
    }

    const jwt = idToken.getJwtToken()

    const decodedToken = CognitoService.decodeCognitoJwt(jwt)
    EventTracker.accountId = decodedToken['custom:accountId']

    return jwt
  }
}

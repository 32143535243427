import {
  styled,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from '@mui/material'

import { colors } from '../../../theme/color'

type DropZoneBoxProps = BoxProps & {
  error: boolean
  isDragActive: boolean
  isHover: boolean
}

type TitleProps = TypographyProps & { error: boolean }

export const RootBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  maxHeight: '100vh',
})

export const DropZoneBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<DropZoneBoxProps>(({ error, isDragActive, isHover }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30rem',
  height: '30rem',
  padding: '6rem',
  textAlign: 'center',
  backgroundColor:
    isDragActive || isHover ? colors.secondary.A500 : colors.background.gray,
  borderRadius: '100%',

  '&:hover': {
    backgroundColor: colors.secondary.A500,
  },
  borderWidth: error ? 1 : '',
  borderStyle: error ? 'solid' : '',
  borderColor: error ? colors.accent.red.light : '',
}))

export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'error',
})<TitleProps>(({ error }) => ({
  color: error ? colors.accent.red.light : colors.primary.A50,
  marginTop: 16,
  marginBottom: 40,
}))

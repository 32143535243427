import React, { useEffect } from 'react'

import ForgotPasswordPage from '../../components/reset-password/forgot-password-page'
import { ForgetPasswordBox } from './styles'

export default function RootForgotPasswordPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Forgot Password'
  }, [])
  return (
    <ForgetPasswordBox>
      <ForgotPasswordPage />
    </ForgetPasswordBox>
  )
}

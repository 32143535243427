import { Grid } from '@mui/material'
import React from 'react'

import { config } from '../../../config'
import LoginForm from '../login-form'
import { Root, LoginFormBox, IllustrationBox, Illustration } from './styles'

export default function LoginPage(): JSX.Element {
  return (
    <Root maxWidth="xl">
      <Grid item container>
        <Grid item xs={12} lg={1}></Grid>
        <LoginFormBox item xs={12} md={3} lg={3}>
          <LoginForm />
        </LoginFormBox>
        <IllustrationBox item xs={12} md={6} lg={6}>
          <a href={config.applyproofUrl} target="_blank" rel="noreferrer">
            <Illustration
              src="/ApplyProof_illustration.svg"
              alt="Applyproof illustration"
            />
          </a>
        </IllustrationBox>
      </Grid>
    </Root>
  )
}

import { OpenInNew } from '@mui/icons-material'
import { Box, Button, Divider, Drawer, Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

import { SearchResponseBodyWithEmailStatuses } from '../../../client/search-client'
import { ApAlertEventHandler } from '../../ap-alert/apAlert'
import ApCopyTile from '../../ap-copy-tile'
import DocumentTypeChip from '../../document-type-chip'
import EmailRecipients from '../../email-recipients'
import ExpiryDateChip from '../../expiry-date-chip'
import InvalidatedChip from '../../invalidated-chip'
import ValidatedByChip from '../../validated-by-chip'
import {
  PasscodeTileStyle,
  DocumentIdTileStyle,
  NameHeader,
  ContentGrid,
  ActionsBox,
  DrawerPaper,
  ResultsDrawer,
  DocumentCodeTilesBox,
  DrawerDivider,
} from './styles'

type ResultPreviewDrawerProps = {
  open?: boolean
  searchResult?:
    | SearchResponseBodyWithEmailStatuses['matchedMetaData'][number]
    | undefined
  onCopyAlert?: ApAlertEventHandler
  onCloseDrawer: () => void
  contentStyling?: React.CSSProperties
}

export default function ResultPreviewDrawer({
  open,
  searchResult: result,
  onCopyAlert,
  onCloseDrawer,
  contentStyling,
}: ResultPreviewDrawerProps): JSX.Element {
  const handleCopy = (message: string) => {
    if (onCopyAlert) {
      onCopyAlert({ message })
    }
  }

  const content = result ? (
    <ContentGrid
      sx={contentStyling}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Box style={{ textAlign: 'center', marginTop: 8 }}>
          <NameHeader variant="h2">
            {`${result.primaryRecipient.firstName} ${result.primaryRecipient.lastName}`}
          </NameHeader>
          <Typography variant="body1" component="p">
            {`${result.primaryRecipient.id}`}
          </Typography>
          <Typography variant="body1" component="p">
            {result.primaryRecipient.email}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DrawerDivider />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Box>
            <Typography variant="h3" component="h3">
              Document Information
            </Typography>
          </Box>
          <DocumentTypeChip documentType={result.documentType} />
          <DocumentCodeTilesBox>
            <Grid container>
              <Grid item xs={5}>
                <ApCopyTile
                  title={'APPLYPROOF CODE'}
                  value={result.duid}
                  onCopy={handleCopy}
                  styling={DocumentIdTileStyle}
                />
              </Grid>
              <Grid item xs={5}>
                <ApCopyTile
                  title={'PASSCODE'}
                  value={result.passcode}
                  color="green"
                  onCopy={handleCopy}
                  styling={PasscodeTileStyle}
                />
              </Grid>
            </Grid>
          </DocumentCodeTilesBox>
          <Box>
            <Grid container>
              <Grid item xs={5}>
                <InvalidatedChip
                  invalidated={result.invalidated}
                  createdAt={dayjs(result.createdAt).toDate()}
                  status={result.documentStatus}
                />
              </Grid>
              <Grid item xs={5}>
                <ExpiryDateChip expiryDate={result.expiry} />
              </Grid>
            </Grid>
          </Box>
          <Box>
            {result.originator.email === '' ||
            result.originator.email.startsWith('apiKey') ? undefined : (
              <ValidatedByChip originatorEmail={result.originator.email} />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DrawerDivider />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <EmailRecipients searchResults={result} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ActionsBox>
          <Button
            variant="outlined"
            href={`/search/${result.duid}`}
            target="_blank"
            rel="noreferrer"
          >
            View Document
          </Button>
          <Button
            href={`/upload/${result.documentType}`}
            variant="text"
            style={{ marginLeft: 20 }}
          >
            New Upload
          </Button>
        </ActionsBox>
      </Grid>
    </ContentGrid>
  ) : (
    <Box>Nothing here yet...</Box>
  )

  return (
    <ResultsDrawer
      open={open}
      onClose={onCloseDrawer}
      PaperProps={{ sx: { DrawerPaper } }}
      anchor="right"
    >
      {content}
    </ResultsDrawer>
  )
}

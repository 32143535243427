import {
  Box,
  Divider,
  Grid,
  Input,
  Skeleton,
  TablePagination,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { useUser } from '../../../client/auth'
import { DocumentType } from '../../../client/origination-client'
import { SearchApiResult } from '../../../client/portal-client'
import {
  createSearchClient,
  SearchResponseBodyWithEmailStatuses,
  SimplifiedDocumentStatus,
  SimplifiedEmailDeliveryStatus,
} from '../../../client/search-client'
import { config } from '../../../config'
import ApAlert from '../../ap-alert'
import { ApAlertEvent, ApAlertEventHandler } from '../../ap-alert/apAlert'
import GenerateIRCCReportButton from '../../generate-ircc-report-button'
import DocumentCard from '../document-card'
import SearchFilterDropdown from '../search-filter-dropdown'
import ResultPreviewDrawer from '../search-result-drawer'
import SearchTextField from '../search-text-field'
import {
  ClearAllButton,
  Content,
  ExportButton,
  LoadErrorBox,
  RootBox,
  SearchBox,
  SearchTypeToggleButtonGroup,
  StudentCount,
  StudentTitle,
} from './styles'
import { FormFields, validationSchema } from './validate'

const SIZE = 10

export default function SearchPage(): JSX.Element {
  const user = useUser()
  const navigate = useNavigate()
  const [searchType, setSearchType] = useState('all')
  const [documentStatus, setDocumentStatus] = useState<
    SimplifiedDocumentStatus | undefined
  >(undefined)
  const [emailStatus, setEmailStatus] = useState<
    SimplifiedEmailDeliveryStatus | undefined
  >(undefined)
  const [documentType, setDocumentType] = useState<DocumentType | undefined>(
    undefined
  )
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [selected, setSelected] = useState<SearchApiResult | undefined>(
    undefined
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const searchClient = createSearchClient()

  if (user === null) {
    navigate('/login', { replace: true })
  }

  const key = `/api/search?q=${query}&page=${page}&status=${documentStatus}&emailStatus=${emailStatus}&documentType=${documentType}`
  const { data, error } = useSWR(
    user ? key : null,
    async () => {
      if (user) {
        const from = page * SIZE

        return await searchClient.search({
          from,
          size: SIZE,
          documentType,
          documentStatus,
          primaryRecipientEmailDeliveryStatus: emailStatus,
          query,
        })
      } else {
        navigate('/login', { replace: true })
      }
    },
    {
      revalidateOnFocus: false,
    }
  )

  const handleDocumentCardActionsClick = (
    result: SearchResponseBodyWithEmailStatuses
  ) => {
    setSelected(result)
  }

  const handleSearchTypeChanged = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newSearchType: string
  ) => {
    setSearchType(newSearchType)
  }

  const handleCopyAlert: ApAlertEventHandler = (e: ApAlertEvent) => {
    setAlertOpen(true)
    setAlertMessage(`Copied "${e.message}" to Clipboard!`)
  }

  const handleClosed = () => {
    setAlertOpen(false)
  }

  const handleStatusChanged = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLTextAreaElement).value
    setPage(0)
    if (value) {
      setDocumentStatus(value as SimplifiedDocumentStatus)
    } else {
      setDocumentStatus(undefined)
    }
  }

  const handleRemoveDocumentStatusFilter = () => {
    setDocumentStatus(undefined)
  }

  const handleEmailStatusChanged = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLTextAreaElement).value
    setPage(0)
    if (value) {
      setEmailStatus(value as SimplifiedEmailDeliveryStatus)
    } else {
      setEmailStatus(undefined)
    }
  }

  const handleRemoveEmailStatusFilter = () => {
    setEmailStatus(undefined)
  }

  const handleDocumentTypeChanged = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLTextAreaElement).value
    setPage(0)
    if (value) {
      setDocumentType(value as DocumentType)
    } else {
      setDocumentType(undefined)
    }
  }

  const handleRemoveDocumentTypeFilter = () => {
    setDocumentType(undefined)
  }

  const handleInvalidate = async (result: SearchApiResult): Promise<void> => {
    // try {
    //   client
    //     .invalidate(user!, result.documentCode)
    //     .catch((err) => {
    //       console.error(err, err.stack)
    //       rowError.set(result.id, 'Failure to invalidate')
    //       setRowError(new Map(rowError.entries()))
    //     })
    //     .finally(() => {
    //       invalidating.delete(result.id)
    //       setInvalidating(
    //         new Set<string>([...invalidating])
    //       )
    //     })
    // } catch (err) {
    //   console.error(err.stack)
    // }
    // setInvalidating(
    //   new Set<string>([result.id, ...invalidating])
    // )
  }

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setPage(page)
  }

  const onSubmit = async (formFields: FormFields): Promise<void> => {
    if (user === undefined) {
      return
    } else if (user === null) {
      return Promise.reject('no user')
    } else {
      setSelected(undefined)
      setPage(0)
      setQuery(formFields.query)
    }
  }

  const formik = useFormik({
    initialValues: {
      type: 'all',
    } as FormFields,
    validationSchema,
    onSubmit,
    isInitialValid: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  })

  const searchInputButtonGroup = (
    <Fragment>
      <SearchTypeToggleButtonGroup
        value={searchType}
        exclusive
        onChange={handleSearchTypeChanged}
        aria-label="text alignment"
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        <ToggleButton value="all" aria-label="left aligned">
          All
        </ToggleButton>
        <ToggleButton value="name" aria-label="centered">
          Name
        </ToggleButton>
        <ToggleButton value="id" aria-label="centered">
          ID
        </ToggleButton>
        <ToggleButton value="email" aria-label="centered">
          Email
        </ToggleButton>
        <ToggleButton value="code" aria-label="centered">
          ApplyProof Code
        </ToggleButton>
        <ToggleButton value="passcode" aria-label="centered">
          PassCode
        </ToggleButton>
        <ToggleButton value="daterange" aria-label="centered">
          Date Range
        </ToggleButton>
      </SearchTypeToggleButtonGroup>
    </Fragment>
  )

  const documentStatusDropdownItems = [
    { label: 'Validated', value: 'VALIDATED' },
    { label: 'Invalidated', value: 'INVALIDATED' },
    { label: 'Failed', value: 'FAILED' },
    // { label: 'Processing', value: 'FAILED' },
  ]

  const statusButtonGroup = (
    <Fragment>
      <SearchFilterDropdown
        label={'Document status'}
        value={documentStatus}
        handleChange={handleStatusChanged}
        dropdownItems={documentStatusDropdownItems}
        handleRemove={handleRemoveDocumentStatusFilter}
        styling={{ width: '11rem' }}
      />
    </Fragment>
  )

  const emailStatusDropdownItems = [
    { label: 'Delivered to student', value: 'DELIVERED' },
    { label: 'Not delivered to student', value: 'UNDELIVERED' },
  ]

  const emailStatusButtonGroup = (
    <Fragment>
      <SearchFilterDropdown
        label={'Email delivery status'}
        value={emailStatus}
        handleChange={handleEmailStatusChanged}
        dropdownItems={emailStatusDropdownItems}
        handleRemove={handleRemoveEmailStatusFilter}
        styling={{ width: '13rem' }}
      />
    </Fragment>
  )

  const documentTypeDropdownItems = [
    { label: 'Proof of Acceptance', value: 'POA' },
    { label: 'Provincial Attestation Letter', value: 'PAL' },
    { label: 'Proof of Financial Support', value: 'POFS' },
    { label: 'Proof of Payment', value: 'POP' },
    { label: 'Proof of Language Proficiency', value: 'POLP' },
    { label: 'Proof of Enrollment', value: 'POE' },
    { label: 'Conditional Letter of Acceptance', value: 'CLOA' },
    { label: 'Proof of Travel Support', value: 'POTS' },
    { label: 'Proof of Quarantine Plan', value: 'POQP' },
  ]

  const documentTypeButtonGroup = (
    <Fragment>
      <SearchFilterDropdown
        label={'Document type'}
        value={documentType}
        handleChange={handleDocumentTypeChanged}
        dropdownItems={documentTypeDropdownItems}
        handleRemove={handleRemoveDocumentTypeFilter}
        styling={{ width: '10rem' }}
      />
    </Fragment>
  )

  const clearAll = () => {
    setDocumentStatus(undefined)
    setEmailStatus(undefined)
    setDocumentType(undefined)
  }
  const clearAllButton = (
    <Fragment>
      <ClearAllButton
        variant={'text'}
        onClick={clearAll}
        disabled={!(documentStatus || emailStatus || documentType)}
      >
        Clear All
      </ClearAllButton>
    </Fragment>
  )

  let tableContent: JSX.Element | undefined = undefined
  if (data?.matchedMetaData) {
    if (data.matchedMetaData.length > 0) {
      tableContent = (
        <Fragment>
          {data.matchedMetaData.map((result, i) => (
            <DocumentCard
              key={i}
              result={result}
              onActionClick={handleDocumentCardActionsClick}
              onCopyAlert={handleCopyAlert}
              onInvalidate={handleInvalidate}
            />
          ))}
        </Fragment>
      )
    } else {
      tableContent = (
        <Fragment>
          <LoadErrorBox>
            <Typography variant="body1" component="p" style={{ marginTop: 8 }}>
              Nothing here yet...
            </Typography>
          </LoadErrorBox>
        </Fragment>
      )
    }
  } else {
    if (error) {
      tableContent = (
        <LoadErrorBox>
          <img alt="error icon" src="/Error_icon.svg" width={24} height={24} />
          <Typography variant="body1" component="p" style={{ marginTop: 8 }}>
            An error occurred while retrieving document data, please try again
            later.
          </Typography>
        </LoadErrorBox>
      )
    } else {
      tableContent = (
        <Fragment>
          {new Array(3).fill(0).map((val, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              animation="wave"
              width="100%"
              height={220}
              style={{ marginTop: 16 }}
            />
          ))}
        </Fragment>
      )
    }
  }

  const total = data ? data.total : undefined
  return (
    <RootBox>
      <Content maxWidth="xl">
        <ApAlert
          open={alertOpen}
          message={alertMessage}
          onClose={handleClosed}
        />
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <SearchBox>
                <Input type="hidden" name="type" id="type" value={searchType} />
                {searchInputButtonGroup}
                <Box>
                  <SearchTextField
                    searchQuery={formik.values.query}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </Box>
              </SearchBox>
              <StudentTitle variant="h2">ApplyProof Documents</StudentTitle>
              {total === undefined ? (
                <Skeleton style={{ width: 120 }} animation="wave" />
              ) : (
                <StudentCount variant="h4">{total}&nbsp;Students</StudentCount>
              )}
              <Grid container justifyContent="space-between">
                <Grid item container xs={6}>
                  {config.enableDocumentTypeFilter ? (
                    <Grid item>{documentTypeButtonGroup}</Grid>
                  ) : undefined}
                  <Grid item>{statusButtonGroup}</Grid>
                  <Grid item>{emailStatusButtonGroup}</Grid>
                  <Grid item>{clearAllButton}</Grid>
                </Grid>
                <Grid item>
                  <ExportButton variant="text">Export as CSV</ExportButton>
                  {user?.canGenerateIrccReport ? (
                    <GenerateIRCCReportButton />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 24, marginBottom: 24 }} />
              {tableContent}
              {total ? (
                <TablePagination
                  component="div"
                  count={total}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPageOptions={[]}
                  rowsPerPage={10}
                />
              ) : undefined}
            </Grid>
          </Grid>
        </form>
      </Content>
      <ResultPreviewDrawer
        open={Boolean(selected)}
        searchResult={selected}
        onCopyAlert={handleCopyAlert}
        onCloseDrawer={() => setSelected(undefined)}
      />
    </RootBox>
  )
}

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Typography, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import React from 'react'

/**
 * A ListItem containing a symbol (check or X) and some text. It is used to indicate
 * the completion of a password strength requirement.
 *
 * @param fieldSatisfied a boolean value to determine if the item is complete or not
 * @param fieldText the text to show for the item
 * @param fieldSatisfiedClassName used for styling when fieldSatisfied is true
 * @param fieldNotSatisfiedClassName used for styling when fieldSatisfied is false
 * @param paddingLeft used to indent nested list items
 * @returns
 */
export default function PasswordStrengthItem({
  fieldSatisfied,
  fieldText,
  fieldSatisfiedStyles,
  fieldNotSatisfiedStyles,
  paddingLeft,
}: {
  fieldSatisfied: boolean
  fieldText: string
  fieldSatisfiedStyles: React.CSSProperties
  fieldNotSatisfiedStyles: React.CSSProperties
  paddingLeft?: string | number
}): JSX.Element {
  return (
    <ListItem style={{ paddingLeft: paddingLeft, paddingBottom: 0 }}>
      <ListItemIcon
        sx={fieldSatisfied ? fieldSatisfiedStyles : fieldNotSatisfiedStyles}
      >
        {fieldSatisfied ? <CheckCircleIcon /> : <CancelIcon />}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography
            sx={fieldSatisfied ? fieldSatisfiedStyles : fieldNotSatisfiedStyles}
          >
            {fieldText}
          </Typography>
        }
      ></ListItemText>
    </ListItem>
  )
}

import cognitoConfig from './config.json'

export const config = getEnvironmentConfiguration()

type Configuration = {
  cognitoRegion: string
  cognitoUserPool: string
  cognitoClientId: string
  applyproofCookieDomain: string
  applyproofCookieOnly: string
  applyproofUrl: string
  maximumExpiryYears: string
  enableDocumentTypeFilter: boolean
  enableRevalidateButton: boolean
  metricsApiUrl: string
}

function getEnvironmentConfiguration(): Configuration {
  return {
    cognitoRegion: 'ca-central-1',
    cognitoUserPool: cognitoConfig.cognitoUserPoolId,
    cognitoClientId: cognitoConfig.cognitoUserPoolClientId,
    applyproofCookieDomain: `.${cognitoConfig.hostedZoneName}`,
    applyproofUrl: `https://${cognitoConfig.hostedZoneName}`,
    applyproofCookieOnly: 'false',
    maximumExpiryYears: '3',
    enableDocumentTypeFilter: false,
    enableRevalidateButton: false,
    metricsApiUrl: `https://metrics.api.${cognitoConfig.hostedZoneName}`,
  }
}

import { styled, TextField, Autocomplete, Box, Typography } from '@mui/material'

import { colors } from '../../theme/color'

export const EmailSubjectTextField = styled(TextField)({
  marginTop: 22,
  marginBottom: 32,
})

export const EmailCcAutocomplete = styled(Autocomplete)({
  marginBottom: 36,
})

export const EmailBccAutocomplete = styled(Autocomplete)({
  marginBottom: 36,
})

export const EmailBodyTextField = styled(TextField)({
  marginBottom: 36,
})

export const RootBox = styled(Box)({
  height: 'calc(100vh - 64px)',
  fallbacks: [
    '-moz-calc(100vh - 64px)',
    '-webkit-calc(100vh - 64px)',
    '-o-calc(100vh - 64px)',
  ],
  backgroundColor: colors.background.gray,
  overflowY: 'auto',
})

export const SettingsTitle = styled(Typography)({
  marginBottom: 30,
  paddingTop: 60,
})

export const EmailConfigurationTitle = styled(Typography)({
  marginBottom: 12,
})

export const SubmitBox = styled(Box)({
  textAlign: 'end',
  marginBottom: 32,
})

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
  Typography,
  Link,
} from '@mui/material'
import { FormikHelpers, useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUser } from '../../client/auth'
import { createSettingsClient } from '../../client/settings-client'
import ApAlert from '../ap-alert'
import { autoCompleteChangeHandler } from '../formik-autocomplete/change-handler'
import {
  EmailConfigurationTitle,
  SettingsTitle,
  RootBox,
  EmailBodyTextField,
  EmailBccAutocomplete,
  EmailCcAutocomplete,
  EmailSubjectTextField,
  SubmitBox,
} from './styles'
import { FormFields, validationSchema } from './validate'

export default function SettingsPage(): JSX.Element {
  const user = useUser()
  const navigate = useNavigate()
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  })
  const [loading, setLoading] = useState(true)

  if (user === null) {
    navigate('/login', { replace: true })
  }

  const initialValues: FormFields = {
    emailEnabled: false,
    emailCc: [],
    emailBcc: [],
    emailBody: ``,
    emailSubject: '',
  }

  const onSubmit = async (
    formFields: FormFields,
    { setSubmitting }: FormikHelpers<FormFields>
  ): Promise<void> => {
    try {
      setAlert({
        open: true,
        message: 'Saving...',
        type: 'info',
      })
      if (user) {
        await client.updateEmailSettings({
          enabled: formFields.emailEnabled,
          cc: formFields.emailCc,
          bcc: formFields.emailBcc,
          subject: formFields.emailSubject,
          body: formFields.emailBody,
        })
      } else {
        throw new Error('')
      }
      setTimeout(() => {
        setAlert({
          open: true,
          message: 'Successfully Saved!',
          type: 'success',
        })
      })
    } catch (err) {
      setTimeout(() => {
        setAlert({
          open: true,
          message: 'Failure to save settings',
          type: 'error',
        })
      })
    } finally {
      setSubmitting(false)
    }
  }

  const handleAlertClose = () => {
    setAlert({
      open: false,
      message: alert.message,
      type: alert.type,
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  })

  const client = createSettingsClient()

  const loadSettings = async () => {
    if (user) {
      try {
        setLoading(true)
        const response = await client.getEmailSettings()
        formik.setValues(response)
        return response
      } catch (err) {
        const defaultSettings = {
          emailEnabled: false,
          emailSubject: '',
          emailCc: [],
          emailBcc: [],
          emailBody: '',
        }
        formik.setValues(defaultSettings)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    loadSettings()
  }, [user])

  const emailConfiguration = (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            id="emailEnabled"
            name="emailEnabled"
            checked={formik.values.emailEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        }
        label="Send an email to the student with the document link when a document is applyproofed"
      />
      <EmailSubjectTextField
        id="emailSubject"
        name="emailSubject"
        type="text"
        label="Email Subject"
        fullWidth
        error={Boolean(formik.errors.emailSubject)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.emailSubject}
        disabled={!formik.values.emailEnabled}
      />
      {formik.errors.emailSubject ? (
        <div>{formik.errors.emailSubject}</div>
      ) : null}
      <EmailCcAutocomplete
        multiple
        disableClearable
        freeSolo
        options={[]}
        id="emailCc"
        fullWidth
        onChange={autoCompleteChangeHandler(formik, 'emailCc')}
        onBlur={formik.handleBlur}
        value={formik.values.emailCc}
        disabled={!formik.values.emailEnabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(formik.errors.emailCc)}
            variant="outlined"
            label="Email CC"
          />
        )}
      />
      {formik.errors.emailCc ? <div>{formik.errors.emailCc}</div> : null}
      <EmailBccAutocomplete
        multiple
        disableClearable
        freeSolo
        options={[]}
        id="emailBcc"
        fullWidth
        onChange={autoCompleteChangeHandler(formik, 'emailBcc')}
        onBlur={formik.handleBlur}
        value={formik.values.emailBcc}
        disabled={!formik.values.emailEnabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(formik.errors.emailBcc)}
            variant="outlined"
            label="Email BCC"
          />
        )}
      />
      {formik.errors.emailBcc ? <div>{formik.errors.emailBcc}</div> : null}
      <EmailBodyTextField
        id="emailBody"
        name="emailBody"
        type="text"
        label="Email Body"
        fullWidth
        multiline
        minRows={10}
        error={Boolean(formik.errors.emailBody)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.emailBody}
        disabled={!formik.values.emailEnabled}
      />
      {formik.errors.emailBody ? <div>{formik.errors.emailBody}</div> : null}
      <SubmitBox>
        <Button
          variant="text"
          onClick={() => loadSettings()}
          disabled={formik.isSubmitting}
        >
          Discard Changes
        </Button>
        &nbsp;&nbsp;
        <Button type="submit" disabled={formik.isSubmitting}>
          Save
        </Button>
      </SubmitBox>
    </Fragment>
  )

  return (
    <RootBox>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          style={{ minHeight: 'calc(100vh - 88px)' }}
        >
          <Grid item xs={5}>
            <SettingsTitle variant="h2">Settings</SettingsTitle>
            <Typography
              component="p"
              variant="body1"
              style={{ marginTop: 8, marginBottom: 24 }}
            >
              Need help with your account? Contact{' '}
              <Link href="mailto:support@applyproof.com?subject=Account Support - ApplyProof Portal">
                support@applyproof.com
              </Link>{' '}
            </Typography>
            {/* <ChangePassword /> */}
            <EmailConfigurationTitle variant="h4">
              Email Configuration
            </EmailConfigurationTitle>
            {loading ? (
              <Fragment>
                <Skeleton
                  variant="circular"
                  width={32}
                  height={32}
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                    marginTop: 16,
                  }}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={32}
                  style={{ display: 'inline-block', marginLeft: 16 }}
                />
                <br />
                <Skeleton variant="text" height={60} />
                <Skeleton variant="text" height={60} />
                <Skeleton variant="rectangular" height="40vh" />
                <Box style={{ textAlign: 'end', marginTop: 16 }}>
                  <Skeleton
                    variant="rectangular"
                    width={128}
                    height={40}
                    style={{
                      display: 'inline-block',
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={128}
                    height={40}
                    style={{
                      display: 'inline-block',
                      marginLeft: 16,
                    }}
                  />
                </Box>
              </Fragment>
            ) : (
              emailConfiguration
            )}
          </Grid>
        </Grid>
        <ApAlert
          open={alert.open}
          message={alert.message}
          type={alert.type}
          onClose={handleAlertClose}
        />
      </form>
    </RootBox>
  )
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import React from 'react'

import { ConfirmButton } from './styles'

type RevalidateDialogProps = {
  open: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

export default function RevalidateDialog({
  open,
  onConfirm,
  onCancel,
}: RevalidateDialogProps): JSX.Element {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <img alt="verify icon" width={50} height={62} src="/Verify_icon.svg" />
        <DialogContentText id="alert-dialog-description">
          Revalidating will grant access to the document on ApplyProof
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <ConfirmButton onClick={onConfirm} autoFocus>
          Yes, Revalidate
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
}

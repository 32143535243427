import {
  styled,
  Box,
  CircularProgress,
  Typography,
  Divider,
  Grid,
  Paper,
} from '@mui/material'
import { PaperProps } from '@mui/material'

import { colors } from '../../../theme/theme'

export const DocumentDrawerButton = styled(Box)({
  marginLeft: 20,
  position: 'absolute',
  top: 28,
  right: 2,
})

export const LoadingBox = styled(Box)({
  backgroundColor: '#FFFFFFaa',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
})

export const LoadingIndicator = styled(CircularProgress)({
  margin: 'auto',
})

export const Name = styled(Typography)({
  color: colors.primary.light,
  fontSize: 18,
  wordBreak: 'break-word',
})

export const StudentId = styled(Typography)({
  color: colors.primary.dark,
  fontSize: 16,
  wordBreak: 'break-all',
})

export const DocumentCodeTileBox = styled(Box)({
  marginTop: '16px',
  marginRight: '16px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  alignItems: 'end',
})

export const DocumentCodeTile = {
  flexShrink: 0,
  flexGrow: 0,
  height: 66,
}

export const PassCodeTile = {
  flexShrink: 0,
  flexGrow: 0,
  height: 66,
  marginLeft: '16px',
}

export const ContentDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
})

export const ViewButtonBox = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  justifyContent: 'flex-end',
  alignItems: 'end',
  marginTop: '-16px',
  marginRight: '16px',
})

export const Email = styled(Typography)({
  fontSize: 16,
})

type RootPaperProps = PaperProps & { outline: boolean }
export const RootPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'outline',
})<RootPaperProps>(({ outline }) => ({
  position: 'relative',
  display: 'block',
  backgroundColor: 'white',
  padding: '1rem 1.5rem 0.5rem 1.5rem',
  marginBottom: 16,
  borderRadius: 4,
  border: 1,
  borderStyle: 'solid',
  borderColor: outline ? colors.primary.A400 : 'transparent',
}))

export const RecipientEmailHeader = styled(Typography)({
  fontSize: 12,
  marginTop: 16,
  marginBottom: 0,
})

export const RecipientEmails = styled(Typography)({
  fontSize: 12,
  wordBreak: 'break-word',
})

export const Header = styled(Typography)({
  fontSize: 12,
  marginTop: 16,
  marginBottom: 4,
})

import { Grid } from '@mui/material'
import React from 'react'

import {
  ApIconButtonBase,
  IconBox,
  TextBox,
  Underline,
  ButtonText,
} from './styles'

export type ApIconButtonProps = {
  icon: JSX.Element
  href?: string
  target?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: JSX.Element | string | undefined
  selected?: boolean
}

export default function ApIconButton({
  icon,
  href,
  target,
  onClick,
  children,
  selected,
}: ApIconButtonProps): JSX.Element {
  const props = href ? { href, target } : { onClick }
  return (
    <ApIconButtonBase
      disableRipple
      disableTouchRipple
      focusRipple={false}
      {...props}
    >
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={1}
      >
        <Grid item>
          <IconBox>{icon}</IconBox>
        </Grid>
        <Grid item>
          <TextBox>
            <ButtonText selected={selected ?? false}>{children}</ButtonText>
          </TextBox>
        </Grid>
      </Grid>
      {selected ? <Underline /> : undefined}
    </ApIconButtonBase>
  )
}

import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CognitoAuth } from '../../../client/auth'
import ApIconButton from '../ap-icon-button'
import {
  Spacer,
  LogoNavLink,
  NavLink,
  SignOutButton,
  RootBox,
  StyledAppBar,
} from './styles'

type MiniDrawerProps = {
  selected: 'upload' | 'search' | 'settings'
}

export default function NavBar({ selected }: MiniDrawerProps): JSX.Element {
  const navigate = useNavigate()

  const handleLogoutClicked = () => {
    const auth = new CognitoAuth()
    auth.logout().then(() => {
      navigate('/')
    })
  }

  return (
    <RootBox>
      <StyledAppBar position="sticky">
        <Toolbar>
          <LogoNavLink to={'/upload'}>
            <ApIconButton
              target="_blank"
              icon={
                <img
                  src="/ApplyProof_logov2.svg"
                  alt="applyproof-logo"
                  width="150"
                  height="29"
                />
              }
            />
          </LogoNavLink>
          <Spacer sx={{ flexGrow: 0.9 }} />
          <NavLink to="/upload">
            <ApIconButton
              icon={
                <img
                  src={
                    selected === 'upload'
                      ? '/Verify_icon_selected.svg'
                      : '/Verify_icon.svg'
                  }
                  width={35}
                  height={41}
                  alt="upload icon"
                />
              }
              selected={selected === 'upload'}
            >
              Upload
            </ApIconButton>
          </NavLink>
          <NavLink to="/search">
            <ApIconButton
              icon={
                <img
                  src={
                    selected === 'search'
                      ? '/Search_icon_selected.svg'
                      : '/Dashboard.svg'
                  }
                  width={35}
                  height={41}
                  alt="search icon"
                />
              }
              selected={selected === 'search'}
            >
              Search
            </ApIconButton>
          </NavLink>
          <NavLink to="/settings">
            <ApIconButton
              icon={
                <img
                  src={
                    selected === 'settings'
                      ? '/Settings_icon_selected.svg'
                      : '/settings.svg'
                  }
                  width={35}
                  height={41}
                  alt="settings icon"
                />
              }
              selected={selected === 'settings'}
            >
              Settings
            </ApIconButton>
          </NavLink>
          <SignOutButton
            variant="text"
            onClick={handleLogoutClicked}
            size="small"
          >
            Sign out
          </SignOutButton>
        </Toolbar>
      </StyledAppBar>
    </RootBox>
  )
}

import { styled, Box, Typography } from '@mui/material'

export const LoadingBox = styled(Box)({
  borderLeft: '1px solid #C4C9D7',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  justifyItems: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100vh',
})

export const RootBox = styled(Box)({
  position: 'relative',
  borderLeft: '1px solid #C4C9D7',
  height: '100%',
})

export const LoadingText = styled(Typography)({
  paddingTop: 46,
  maxWidth: 350,
})

export const Embed = styled('embed')({
  height: '100%',
  width: '100%',
})

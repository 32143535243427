import { LinearProgress } from '@mui/material'
import React from 'react'

import { DocumentUpload } from '../../models/document-upload'
import DocumentFailedViewer from '../document-failed-viewer'
import { RootBox, LoadingBox, LoadingText, Embed } from './styles'

export type DocumentViewerProps = {
  url?: string
  document?: DocumentUpload
  fullHeight?: boolean
  documentStatus?: string
}

export default function DocumentViewer({
  url,
  document,
  fullHeight = false,
  documentStatus = 'COMPLETED',
}: DocumentViewerProps): JSX.Element {
  const loading = documentStatus === 'PROCESSING'
  if (loading || (!url && documentStatus !== 'FAILED' && !document)) {
    return (
      <LoadingBox>
        <img
          alt="applyproof loading icon"
          width={52}
          height={64}
          src="/ApplyProofing_icon.svg"
        />
        <LoadingText variant="body1">
          The ApplyProof Document is still processing, you can leave this page
          and view the status of the document on your dashboard.
        </LoadingText>

        {loading ? (
          <LinearProgress style={{ marginTop: 40, width: '350px' }} />
        ) : undefined}
      </LoadingBox>
    )
  }

  // TODO: Make more failure-tolerant?
  return (
    <RootBox>
      {url &&
      (documentStatus === 'COMPLETED' || documentStatus === 'INVALIDATED') ? (
        <Embed
          style={fullHeight ? { height: '100vh' } : undefined}
          key={url}
          src={url}
        />
      ) : document ? (
        <Embed
          style={fullHeight ? { height: '100vh' } : undefined}
          key={document.name}
          src={document.objectUrl}
        />
      ) : (
        <DocumentFailedViewer></DocumentFailedViewer>
      )}
    </RootBox>
  )
}

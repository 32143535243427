import React from 'react'

import { StyledTooltip } from './styles'

type PasswordRequirementsTooltipProps = {
  title: JSX.Element
  open: boolean
  children: JSX.Element
}

export default function PasswordRequirementsTooltip({
  title,
  open,
  children,
}: PasswordRequirementsTooltipProps): JSX.Element {
  return (
    <>
      <StyledTooltip title={title} placement="right-start" open={open} arrow>
        {children}
      </StyledTooltip>
    </>
  )
}

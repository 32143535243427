import React, { useEffect } from 'react'

import NavBar from '../../components/nav/nav-bar'
import SearchPage from '../../components/search-page/search-page'

export default function RootSearchPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Search'
  }, [])
  return (
    <>
      <NavBar selected="search" />
      <SearchPage />
    </>
  )
}

import { Box, BoxProps, styled } from '@mui/material'

import { CRYSTAL_COLORS, colors } from '../../../theme'

type DropZoneBoxProps = BoxProps & {
  error: boolean
  isDragActive: boolean
  isHover: boolean
}

export const DropZoneBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<DropZoneBoxProps>(({ error, isDragActive, isHover }) => ({
  backgroundColor:
    isDragActive || isHover
      ? CRYSTAL_COLORS.blue.blue21
      : colors.background.white,
  cursor: 'pointer',
  borderRadius: '8px',
  border: '1px dashed',
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
  marginTop: '16px',

  '&:hover': {
    backgroundColor: CRYSTAL_COLORS.blue.blue21,
  },
  borderWidth: error ? 1 : '',
  borderStyle: error ? 'solid' : '',
  borderColor: isHover
    ? CRYSTAL_COLORS.blue.blue15
    : error
    ? colors.accent.red.light
    : CRYSTAL_COLORS.gray.gray10,
}))

import React, { useRef, KeyboardEventHandler } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { RootBox, Content, Title, Subtitle, ColoredButton } from './styles'

export type ApCopyTileProps = {
  title: string
  value: string
  color?: 'blue' | 'yellow' | 'green'
  onCopy?: (message: string) => void
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  styling?: React.CSSProperties
}

export default function ApCopyTile({
  title,
  value,
  color = 'blue',
  onCopy = () => undefined,
  onChange,
  styling,
}: ApCopyTileProps): JSX.Element {
  const valueRef = useRef<HTMLHeadingElement>(null)

  const handleCopied = () => {
    onCopy(value)
  }

  const handleClick = () => {
    const node = valueRef.current
    if (node) {
      const range = document.createRange()
      range.selectNode(node)
      window.getSelection()?.removeAllRanges()
      window.getSelection()?.addRange(range)
      setTimeout(() => {
        window.getSelection()?.removeAllRanges()
      }, 1000)
    }
  }

  const handleEnter: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'enter') {
      handleClick()
    }
  }

  return (
    <CopyToClipboard text={value} onCopy={handleCopied}>
      <RootBox sx={styling}>
        <input type="hidden" value={value} onChange={onChange} />
        <ColoredButton
          role="button"
          onClick={handleClick}
          onKeyDown={handleEnter}
          tabIndex={0}
          buttonColor={color}
        >
          <Content>
            <Title variant="body1">{title}</Title>
            <Subtitle ref={valueRef} variant="body1">
              {value}
            </Subtitle>
          </Content>
        </ColoredButton>
      </RootBox>
    </CopyToClipboard>
  )
}

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import NavBar from '../../components/nav/nav-bar'
import UploadPage from '../../components/upload-page/upload-page'

export default function RootUploadPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Upload'
  }, [])
  const { documentType } = useParams()
  return (
    <>
      <NavBar selected="upload" />
      <UploadPage documentType={documentType ?? 'POA'} />
    </>
  )
}

import CryptoJS from 'crypto-js'
import sha256 from 'crypto-js/sha256'
import { publicIpv4 } from 'public-ip'

export async function getDistinctId(): Promise<string> {
  const ipV4 = await publicIpv4()

  const hashed = sha256(ipV4)
  return hashed.toString(CryptoJS.enc.Hex)
}

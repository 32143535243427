import axios from 'axios'

import {
  DocumentUploadBody,
  DocumentUploadResponse,
  GetMetadataResponse,
  GetMetadataResponseWithEmailStatuses,
  PresignedUrlResponse,
} from '.'
import config from '../../config.json'
import { BaseClient } from '../base-client'
import { addEmailStatusToMetadata } from '../shared-utils/addEmailStatusToMetadata'

/**
 * API Gateway has a request limit size of 10 MB
 * https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html
 *
 * Lambdas have an invocation limit of 6MB
 * https://docs.aws.amazon.com/lambda/latest/dg/gettingstarted-limits.html
 *
 * Therefore we need to cap the request payload size to no more than 5MB
 */
export const TOTAL_UPLOAD_MAX_SIZE = 5242880

export interface OriginationClientProps {
  baseUrl: string
}

export class OriginationClient extends BaseClient {
  private readonly baseUrl: string
  constructor(props: OriginationClientProps) {
    super()
    this.baseUrl = props.baseUrl
  }

  async upload(
    documentUploadBody: DocumentUploadBody,
    files: Array<Blob>,
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<DocumentUploadResponse> {
    const idToken = await this.getIdToken()
    const formData = new FormData()
    formData.append('body', JSON.stringify(documentUploadBody))
    for (const file of files) {
      formData.append('files', file)
    }

    const response = await axios({
      validateStatus: () => true,
      url: `https://origination.api.${this.baseUrl}/v1/document`,
      method: 'POST',
      data: formData,
      headers: {
        authorization: `Bearer ${idToken}`,
      },
      onUploadProgress: onUploadProgress,
    })

    if (response.status === 200) {
      const data: DocumentUploadResponse = response.data
      return data
    } else if (response.status === 413) {
      throw new Error('Document upload exceeded size limit')
    } else {
      throw new Error('Failure to upload documents')
    }
  }

  async getPresignedUrl(duid: string): Promise<PresignedUrlResponse> {
    const idToken = await this.getIdToken()
    const response = await axios({
      url: `https://origination.api.${this.baseUrl}/v1/presigned-url/${duid}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (response.status === 200) {
      const data: PresignedUrlResponse = response.data
      return data
    } else {
      throw new Error('Document not found')
    }
  }

  async download(duid: string): Promise<Blob> {
    const idToken = await this.getIdToken()
    const response = await axios({
      url: `https://origination.api.${this.baseUrl}/v1/document/${duid}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })

    const data: Blob = response.data
    return data
  }

  async getMetadata(
    duid: string
  ): Promise<GetMetadataResponseWithEmailStatuses> {
    const idToken = await this.getIdToken()
    const url = `https://origination.api.${this.baseUrl}/v1/metadata/${duid}`
    const response = await axios({
      url: url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    const data: GetMetadataResponse = response.data

    return addEmailStatusToMetadata(data)
  }

  private async setDocumentInvalidated(
    duid: string,
    invalidated: boolean
  ): Promise<GetMetadataResponse> {
    const idToken = await this.getIdToken()
    const url = `https://origination.api.${this.baseUrl}/v1/metadata/${duid}/invalidate`
    const response = await axios({
      url: url,
      method: 'PATCH',
      data: {
        invalidated: invalidated,
      },
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    const data: GetMetadataResponse = response.data
    return data
  }

  async invalidateDocument(duid: string): Promise<GetMetadataResponse> {
    return await this.setDocumentInvalidated(duid, true)
  }

  async revalidateDocument(duid: string): Promise<GetMetadataResponse> {
    return await this.setDocumentInvalidated(duid, false)
  }
}

export function createOriginationClient() {
  return new OriginationClient({ baseUrl: config.hostedZoneName })
}

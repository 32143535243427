import { styled, Box, Typography } from '@mui/material'

export const EmailsBox = styled(Box)({
  overflow: 'auto',
  height: '100%',
  maxHeight: '30vh',
})

export const EmailFieldHeader = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '16px',
})

import axios, { AxiosResponse } from 'axios'

import {
  constructQuery,
  MatchRequestBody,
  MatchResponseBody,
  OpenSearchRequestBody,
  SearchClient,
  SearchRequestBody,
  SearchResponseBody,
  SearchResponseBodyWithEmailStatuses,
} from '.'
import config from '../../config.json'
import { BaseClient } from '../base-client'
import { addEmailStatusToMetadata } from '../shared-utils/addEmailStatusToMetadata'

export interface SearchClientProps {
  baseUrl: string
}

export class DefaultSearchClient extends BaseClient implements SearchClient {
  private readonly baseUrl: string
  constructor(props: SearchClientProps) {
    super()
    this.baseUrl = props.baseUrl
  }

  async match(body: MatchRequestBody): Promise<MatchResponseBody> {
    const idToken = await this.getIdToken()
    const response: AxiosResponse<MatchRequestBody> = await axios({
      url: `https://search.api.${this.baseUrl}/v1/match`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: body,
    })

    if (response.status !== 200) {
      throw new Error('Failed to match Metadata with request: ' + body)
    }

    const data: MatchRequestBody = response.data

    return data
  }

  async export(body: SearchRequestBody): Promise<void> {
    const idToken = await this.getIdToken()
    this.validateRequest(body)
    const query: OpenSearchRequestBody = constructQuery(body)

    const response = await axios({
      url: `https://search.api.${this.baseUrl}/v1/export`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: query,
    })

    if (response.status !== 200) {
      throw new Error('Failed to export Metadata with request: ' + body)
    }
  }

  async search(
    body: SearchRequestBody
  ): Promise<SearchResponseBodyWithEmailStatuses> {
    const idToken = await this.getIdToken()
    this.validateRequest(body)
    const query: OpenSearchRequestBody = constructQuery(body)

    const response: AxiosResponse<SearchResponseBody> = await axios({
      url: `https://search.api.${this.baseUrl}/v1/search`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: query,
    })

    if (response.status !== 200) {
      throw new Error('Failed to search Metadata with request: ' + body)
    }

    const data: SearchResponseBody = response.data

    // add fields to returned metadata on the fly
    const result: SearchResponseBodyWithEmailStatuses = {
      matchedMetaData: data.matchedMetaData.map((e) =>
        addEmailStatusToMetadata(e)
      ),
      to: data.from,
      total: data.total,
    }
    return result
  }

  private validateRequest(searchRequest: SearchRequestBody) {
    if (Object.keys(searchRequest).length === 0) {
      throw new Error('Empty search request is not allowed.')
    }

    if (
      (searchRequest.validatedTimeRangeLower === undefined &&
        searchRequest.validatedTimeRangeUpper !== undefined) ||
      (searchRequest.validatedTimeRangeLower !== undefined &&
        searchRequest.validatedTimeRangeUpper === undefined)
    ) {
      throw new Error(
        '`validatedTimeRangeLower` and `validatedTimeRangeUpper` must be both presented or undefined.'
      )
    }

    if (
      searchRequest.validatedTimeRangeLower !== undefined &&
      searchRequest.validatedTimeRangeUpper !== undefined &&
      searchRequest.validatedTimeRangeLower >
        searchRequest.validatedTimeRangeUpper
    ) {
      throw new Error(
        '`validatedTimeRangeLower` must not be greater than `validatedTimeRangeUpper`.'
      )
    }

    if (
      (searchRequest.from === undefined && searchRequest.size !== undefined) ||
      (searchRequest.from !== undefined && searchRequest.size === undefined)
    ) {
      throw new Error('`from` and `size` must be both presented or undefined.')
    }

    if (searchRequest.from !== undefined && searchRequest.from < 0) {
      throw new Error('`from` can`t be negative.')
    }

    if (searchRequest.size !== undefined && searchRequest.size <= 0) {
      throw new Error('`size` can`t be less than or equal to 0.')
    }
  }
}

export function createSearchClient(): SearchClient {
  return new DefaultSearchClient({ baseUrl: config.hostedZoneName })
}

import React, { useEffect } from 'react'

import NavBar from '../../components/nav/nav-bar'
import UploadPrePage from '../../components/upload-pre-page/upload-pre-page'

export default function RootUploadPrePage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Document Types'
  }, [])
  return (
    <>
      <NavBar selected="upload" />
      <UploadPrePage />
    </>
  )
}

import { CircularProgress, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { useUser } from '../../client/auth'
import { createOriginationClient } from '../../client/origination-client'
import ConfirmationPage, {
  getDocumentStatus,
} from '../../components/confirmation-page/confirmation-page'
import NavBar from '../../components/nav/nav-bar'

export default function RootConfirmationPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Confirmation'
  }, [])
  const navigate = useNavigate()
  const { duid } = useParams()
  const user = useUser()

  if (user === null) {
    navigate('/', { replace: true })
  }
  const [refreshInterval, setRefreshInterval] = useState(500)
  const documentCode = Array.isArray(duid) ? duid[0] : duid || ''

  const metadataClient = createOriginationClient()

  const key = user ? `/api/metadata/${documentCode}` : null
  const { data, error } = useSWR(
    key,
    async () => {
      if (user) {
        if (!documentCode) {
          console.log('missing document code')
          throw error
        }
        const metadata = await metadataClient.getMetadata(documentCode)
        const documentStatus = getDocumentStatus(metadata)

        return { metadata, documentStatus }
      } else {
        navigate('/', { replace: true })
      }
    },
    {
      revalidateOnFocus: true,
      refreshInterval: refreshInterval,
      dedupingInterval: 0,
    }
  )

  if (
    data !== undefined &&
    data.documentStatus !== 'PROCESSING' &&
    refreshInterval !== 0
  ) {
    setRefreshInterval(0)
  }

  return (
    <>
      <NavBar selected="upload" />
      {data ? (
        <ConfirmationPage
          metadata={data.metadata}
          documentStatus={data.documentStatus}
        />
      ) : (
        <Box
          display="flex"
          minHeight="80vh"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress size={64} />
        </Box>
      )}
    </>
  )
}

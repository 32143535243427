import {
  styled,
  ToggleButtonGroup,
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from '@mui/material'

import { colors, theme } from '../../../theme/theme'

export const SearchTypeToggleButtonGroup = styled(ToggleButtonGroup)({
  display: 'none',
})

export const LoadErrorBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  height: '400px',
  flexDirection: 'column',
})

export const RootBox = styled(Box)({
  height: 'calc(100vh - 64px)',
  fallbacks: [
    '-moz-calc(100vh - 64px)',
    '-webkit-calc(100vh - 64px)',
    '-o-calc(100vh - 64px)',
  ],
  backgroundColor: colors.background.gray,
  paddingLeft: '10%',
  paddingRight: '10%',
  overflowY: 'auto',
})

export const Content = styled(Container)({
  [theme.breakpoints.down('xl')]: {
    marginLeft: 0,
  },
  paddingTop: 20,
  paddingLeft: 40,
  paddingRight: 40,
})

export const SearchBox = styled(Box)({})

export const StudentTitle = styled(Typography)({
  marginTop: 64,
})

export const StudentCount = styled(Typography)({
  marginTop: 8,
  color: colors.primary.light,
  marginBottom: 0,
})

export const ExportButton = styled(Button)({
  display: 'none',
})

export const ClearAllButton = styled(Button)({
  paddingTop: '10px',
  color: colors.text.paragraph,
})

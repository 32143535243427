import { styled, Box, Button, Grid, Typography } from '@mui/material'

import { colors } from '../../theme'

export const RootBox = styled(Box)({
  boxShadow: `0px 3px 20px ${colors.boxShadow.default}`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '10vh',
})

export const LogoGrid = styled(Grid)({
  position: 'relative',
  left: '16%',
})

export const Content = styled(Grid)({
  marginTop: 40,
  minHeight: '80vh',
})

export const Title = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: 36,
  alignContent: 'center',
  color: colors.primary.light,
  marginTop: 40,
})

export const Body = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: 18,
  alignContent: 'center',
  color: '#000000',
  marginTop: 15,
})

export const SendAnotherLinkButton = styled(Button)({
  marginTop: 40,
})

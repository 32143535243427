import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUser } from '../../client/auth'
import LoginPage from '../../components/login/login-page'
import { LoginBox } from './styles'

export default function RootLoginPage(): JSX.Element {
  const navigate = useNavigate()
  const user = useUser()

  useEffect(() => {
    document.title = 'ApplyProof Portal - Sign In'
    if (user) {
      navigate('../upload', { replace: true })
    }
  }, [user])

  // User is explicitly set to null after confirming there is no active session
  return user === null ? (
    <LoginBox>
      <LoginPage />
    </LoginBox>
  ) : (
    <></>
  )
}

import {
  styled,
  Box,
  Divider,
  FormHelperText,
  FormControl,
  Alert,
  Typography,
} from '@mui/material'

import { colors } from '../../../theme/color'
import { theme } from '../../../theme/theme'

const headerHeight = '10vh'
const contentHeight = '70vh'
const actionsHeight = '10vh'

export const HeaderBox = styled(Box)({
  paddingTop: 40,
  marginRight: 64,
  paddingLeft: 60,
  // height: headerHeight,
})

export const HeaderDivider = styled(Divider)({
  marginTop: 16,
})

export const ActionsDivider = styled(Divider)({
  width: '100%',
})

export const ActionsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '1.5rem 2rem 1.55rem 60px',
  justifyItems: 'center',
  justifyContent: 'center',
  alignItem: 'center',
  position: 'absolute',
  bottom: 0,
  width: '50%',
})

export const FooterBox = styled(Box)({
  height: '100px',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
})

export const SubmitButtonBox = styled(Box)({
  marginTop: 20,
})

export const Content = styled(Box)({
  paddingLeft: 60,
  flexBasis: 'auto',
  flex: 2,
  paddingRight: 48,
  paddingBottom: 16,
})

export const Preview = styled(Box)({
  backgroundColor: colors.background.white,
  [theme.breakpoints.up('md')]: {
    preview: {
      gridArea: '1 / 2 / last-line / 3',
    },
  },
})

export const Actions = styled(Box)({
  paddingLeft: 60,
  // height: actionsHeight,
})

export const FormErrorText = styled(FormHelperText)({
  position: 'relative',
  top: 0,
  height: 0,
})

export const DocumentAttachedError = styled(FormHelperText)({
  marginTop: 8,
  textAlign: 'end',
  color: colors.accent.red.light,
})

export const ReviewDocumentsCheckbox = styled(FormControl)({
  marginTop: 20,
  minWidth: '20ch',
  flexBasis: '50%',
  flex: 2,
})

export const DisabledEmailAlert = styled(Alert)({
  backgroundColor: colors.translucent.blue20,
  width: '80%',
  fontSize: 12,
  marginLeft: 60,
  marginTop: 8,
})

export const EmailInfoTypography = styled(Typography)({
  fontStyle: 'italic',
  fontSize: '10px',
  marginLeft: '12px',
  marginTop: '5px',
})

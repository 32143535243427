export type ResetPasswordFormFields = {
  password: string
  confirmPassword: string
}

export type PasswordStrengthRequirements = {
  digit: boolean
  lowercase: boolean
  uppercase: boolean
  specialCharacter: boolean
  minLength: boolean
}

/**
 *
 * @param password The password to validate against
 * @returns An object with fields for each requirement,
 * set to true/false depending on if they are matched.
 */
export function validatePasswordStrength(
  password: string
): PasswordStrengthRequirements {
  const digit = /[0-9]/
  const lowercase = /[a-z]/
  const uppercase = /[A-Z]/
  const specialCharacter = /[*.!#@$%^&(){}[\]:;<>,.?/~_+\-=|]/
  const minLength =
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!#@$%^&(){}[\]:;<>,.?/~_+-=|]).{12,256}/

  const result: PasswordStrengthRequirements = {
    digit: Boolean(password.match(digit)),
    lowercase: Boolean(password.match(lowercase)),
    uppercase: Boolean(password.match(uppercase)),
    specialCharacter: Boolean(password.match(specialCharacter)),
    minLength: Boolean(password.match(minLength)),
  }

  return result
}

export const validatePasswordStrengthMessages: Record<
  keyof PasswordStrengthRequirements,
  string
> = {
  digit: 'Number (0-9)',
  lowercase: 'Lower case letter (a-z)',
  uppercase: 'Upper case letter (A-Z)',
  specialCharacter: 'Special character (e.g. $, #, @)',
  minLength: 'Between 12 and 256 characters',
}

/**
 * Only validates if passwords are entered and that they match.
 */
export const validate = (
  values: ResetPasswordFormFields
): ResetPasswordFormFields => {
  const errors = {} as ResetPasswordFormFields
  if (values.password !== undefined) {
    if (!values.password) {
      errors.password = 'Enter New Password'
    } else if (values.password.match(/\s/)) {
      errors.password = 'Password cannot contain spaces'
    }
  }

  const passwordStrengthRequirementsSatisfied = validatePasswordStrength(
    values.password
  )
  if (
    !(
      passwordStrengthRequirementsSatisfied.digit &&
      passwordStrengthRequirementsSatisfied.lowercase &&
      passwordStrengthRequirementsSatisfied.uppercase &&
      passwordStrengthRequirementsSatisfied.specialCharacter &&
      passwordStrengthRequirementsSatisfied.minLength
    )
  ) {
    errors.password = 'Password must meet strength requirements.'
  }

  if (values.confirmPassword !== undefined) {
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Re-enter Password'
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match'
    }
  }

  return errors
}

import { styled, Box } from '@mui/material'

import { colors } from '../../../theme'

export const RootBox = styled(Box)({
  // display: 'flex',
  // flexDirection: 'column',
  // maxHeight: '100vh',
  // height: '100%',
})

export const DocumentTabsBox = styled(Box)({
  position: 'absolute',
  bottom: 0,
  width: '50%',
  backgroundColor: colors.background.white,
})

export const DocumentBox = styled(Box)({
  height: '100%',
  backgroundColor: colors.background.white,
  paddingBottom: '100px',
})

import React, { useEffect } from 'react'

import ResetPasswordPage from '../../components/reset-password/reset-password-page'
import { ConfirmPasswordBox } from './styles'

export default function RootConfirmSignUpPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Confirm Sign up'
  }, [])
  return (
    <ConfirmPasswordBox>
      <ResetPasswordPage confirmSignup={true} />
    </ConfirmPasswordBox>
  )
}

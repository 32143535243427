import { styled, DialogContent, Box, LinearProgress } from '@mui/material'

export const DocumentDialogContent = styled(DialogContent)({
  minWidth: '450px',
  maxWidth: '600px',
  height: '100vh',
  display: 'flex',
  justifyItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
})

export const ContentBox = styled(Box)({
  minWidth: '450px',
  maxWidth: '600px',
  height: '100vh',
  display: 'flex',
  justifyItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
})

export const MessageBox = styled(Box)({
  marginTop: 58,
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
})

export const DocumentLoadingIndicator = styled(LinearProgress)({
  width: '100%',
  marginTop: 44,
  marginBottom: 64,
})

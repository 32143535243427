import React, { useEffect } from 'react'

import NavBar from '../../components/nav/nav-bar'
import SettingsPage from '../../components/settings-page'

export default function RootSettingsPage(): JSX.Element {
  useEffect(() => {
    document.title = 'ApplyProof Portal - Settings'
  }, [])
  return (
    <>
      <NavBar selected="settings" />
      <SettingsPage />
    </>
  )
}

import validator from 'email-validator'

export type ForgotPasswordFormFields = {
  username: string
}

export const validate = (
  values: ForgotPasswordFormFields
): ForgotPasswordFormFields => {
  const errors = {} as ForgotPasswordFormFields
  if (!values.username) {
    errors.username = 'Email is required'
  } else if (values.username.length > 256) {
    errors.username = 'Email must be 256 characters or less'
  } else if (!validator.validate(values.username)) {
    errors.username = 'Please enter a valid email address'
  }

  return errors
}

import React from 'react'

import { ValidatedChip, OpenCircleChipIcon } from './styles'

type ValidatedByChipProps = {
  originatorEmail: string
}
export default function ValidatedByChip({
  originatorEmail,
}: ValidatedByChipProps): JSX.Element {
  return (
    <ValidatedChip
      icon={<OpenCircleChipIcon />}
      label={`Validated by ${originatorEmail}`}
      variant="filled"
    />
  )
}

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'

import {
  DocumentDialogContent,
  ContentBox,
  MessageBox,
  DocumentLoadingIndicator,
} from './styles'

export type DocumentProgressDialogProps = {
  progress: number
  error: boolean
  errorText: string
  open: boolean
  onCancelled: () => void
}

export default function DocumentProgressDialog({
  progress,
  error,
  errorText,
  open,
  onCancelled,
}: DocumentProgressDialogProps): JSX.Element {
  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={onCancelled}
        aria-labelledby="form-dialog-title"
      >
        <DocumentDialogContent>
          <DialogTitle id="form-dialog-title" style={{ display: 'none' }}>
            ApplyProof Document
          </DialogTitle>
          <ContentBox>
            <Box>
              <img
                alt="applyproof logo"
                width={196}
                height={37}
                src="/ApplyProof_logo.svg"
              />
            </Box>
            <MessageBox>
              <img
                alt="applyproof upload icon"
                width={89}
                height={105}
                src="/ApplyProof_upload_icon.svg"
              />
              <Typography variant="body1" component="p">
                Please wait while we upload your documents to ApplyProof. Please
                do not refresh your browser
              </Typography>
            </MessageBox>
            <Box>
              {error ? (
                <Alert severity="error">Error: {errorText}</Alert>
              ) : null}
            </Box>
            <DocumentLoadingIndicator variant="determinate" value={progress} />
            <Box>
              <Button onClick={onCancelled} color="primary" variant="outlined">
                Cancel
              </Button>
            </Box>
          </ContentBox>
        </DocumentDialogContent>
      </Dialog>
    </Box>
  )
}

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CookieStorage,
  ICognitoStorage,
} from 'amazon-cognito-identity-js'

import { config } from '../../config'
import {
  AuthResult,
  AuthenticationFields,
  ChangePasswordParams,
  NewPasswordChallengeParams,
  NewPasswordRequiredResult,
  UserSession,
} from './auth'
import userPool from './user-pool'

export * from './use-user'

/**
 * TODO: Rewrite this entire class
 */
export class CognitoAuth {
  public user?: CognitoUser
  private username: string
  private session?: UserSession | undefined
  private storage: ICognitoStorage

  constructor() {
    this.user = undefined
    this.username = ''
    this.session = undefined
    this.storage = new CookieStorage({
      domain: config.applyproofCookieDomain,
    })
  }

  currentSession(): UserSession | undefined {
    return this.session
  }

  async refreshSessionIfExpired(): Promise<void> {
    try {
      this.session = await this.createSession()
    } catch (err) {
      throw new Error('failure to get session')
    }

    if (this.session) {
      const user = userPool.getCurrentUser()
      if (user === null) {
        throw new Error('user not logged in')
      }

      const expiredSession =
        this.session &&
        Date.now() >
          this.session.current.getAccessToken().getExpiration() * 1000
      if (expiredSession) {
        const refreshToken = this.session.current.getRefreshToken()
        await new Promise((resolve, reject) => {
          user.refreshSession(refreshToken, (err: any, session: any) => {
            if (err) {
              reject(err)
            } else {
              resolve(session)
            }
          })
        })
      }
    } else {
      throw new Error('failure to refresh session')
    }
  }

  currentUser(username: string): CognitoUser {
    if (!this.user || this.username !== username) {
      this.username = username
      const userOptions =
        config.applyproofCookieOnly === 'true'
          ? {
              Username: username,
              Pool: userPool,
              Storage: this.storage,
            }
          : {
              Username: username,
              Pool: userPool,
            }
      this.user = new CognitoUser(userOptions)
    }
    return this.user
  }

  async authenticate({
    username,
    password,
  }: AuthenticationFields): Promise<AuthResult> {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const cognitoUser = this.currentUser(username)
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session: CognitoUserSession) => {
          const accessToken = session.getAccessToken().getJwtToken()
          const jwtToken = session.getIdToken().getJwtToken()
          const refreshToken = session.getRefreshToken().getToken()
          // console.log(jwtToken)

          resolve({
            access: accessToken,
            jwt: jwtToken,
            refresh: refreshToken,
            newPasswordRequired: false,
          })
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFailure: (err: any) => reject(err),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
          resolve({
            userAttributes,
            requiredAttributes,
            newPasswordRequired: true,
          })
        },
      })
    })
  }

  private async getJwtFromCurrentSession() {
    await this.refreshSessionIfExpired()
    const session = this.session
    if (!session) {
      throw new Error('No session')
    }

    const idToken = session.current.getIdToken()
    if (!idToken) {
      throw new Error('No idtoken in session')
    }

    return idToken.getJwtToken()
  }
  async isAuthorized(): Promise<boolean> {
    const jwtToken = await this.getJwtFromCurrentSession()
    const originatorHubScopes = await this.getOriginatorHubScopes(jwtToken)
    return originatorHubScopes.length !== 0
  }

  async canGenerateIrccReport(): Promise<boolean> {
    const jwtToken = await this.getJwtFromCurrentSession()
    const originatorHubScopes = await this.getOriginatorHubScopes(jwtToken)
    return originatorHubScopes.includes('originatorhub:execute:report')
  }

  private async getOriginatorHubScopes(
    jwtToken: string
  ): Promise<Array<string>> {
    const payload = parseJwt(jwtToken)
    if (!payload.scope) {
      throw new Error('Failed to authorize user. Missing scopes')
    }
    const scopes: Array<string> = payload.scope.split(' ')
    const originatorHubScopes = scopes.filter((scope) =>
      scope.includes('originatorhub')
    )
    return originatorHubScopes
  }

  async hasPermission(scope: string) {
    const jwtToken = await this.getJwtFromCurrentSession()
    const originatorHubScopes = await this.getOriginatorHubScopes(jwtToken)
    return originatorHubScopes.includes(scope)
  }

  async hasInvalidateScope() {
    return this.hasPermission('originatorhub:invalidate:document')
  }

  async hasReadDocumentScope() {
    return this.hasPermission('originatorhub:read:document')
  }

  async hasWriteDocumentScope() {
    return this.hasPermission('originatorhub:write:document')
  }

  async hasWriteActionScope() {
    return this.hasPermission('originatorhub:write:action')
  }

  async completeNewPasswordChallenge({
    username,
    password,
    newPassword,
  }: NewPasswordChallengeParams): Promise<any> {
    let user = this.currentUser(username)
    if (user.getUsername() !== username) {
      const result: NewPasswordRequiredResult = await this.authenticate({
        username,
        password,
      })
      if (!result.newPasswordRequired) {
        throw new Error('New Password Required')
      }
    }

    user = this.currentUser(username)

    return new Promise((resolve, reject) => {
      /*
       * The API for completeNewPasswordChallenge is poorly documented.
       * The second parameters provides writeable attributes to update on
       * password challenged completed. There is no use case where we want to
       * this. Beyond that, this will throw an exception if unwriteable attribute
       * is provided. There are a couple of attributes we never want to update.
       *
       * TLDR; do not provide attributes to the second parameter of completeNewPasswordChallenge
       * unless you are certain they can be updated by the user.
       */
      const updateAttributes = undefined
      user.completeNewPasswordChallenge(newPassword, updateAttributes, {
        onSuccess: () => {
          resolve(undefined)
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFailure: (err: any) => {
          reject(err)
        },
      })
    })
  }

  async changePassword({
    user,
    password,
    newPassword,
  }: ChangePasswordParams): Promise<void> {
    const cognitoUser = this.currentUser(user.username)
    await new Promise((resolve, reject) => {
      cognitoUser.changePassword(
        password,
        newPassword,
        (err?: Error | undefined, result?: 'SUCCESS' | undefined) => {
          if (err) {
            return reject(err)
          }
          resolve(result)
        }
      )
    })
  }

  async logout(): Promise<void> {
    const user = userPool.getCurrentUser()
    if (user === null) {
      throw new Error('user not logged in')
    }
    user.signOut()
    this.storage.clear()
  }

  private async createSession(): Promise<UserSession> {
    const details: UserSession = await new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser()
      if (user === null) {
        return reject('user not logged in')
      }

      user.getSession(
        async (err: Error | null, session: CognitoUserSession | null) => {
          if (err !== null) {
            reject(err)
          } else if (session !== null) {
            const decoded = session.getAccessToken().decodePayload()
            const username = decoded.username
            resolve({
              current: session,
              username,
            })
          } else {
            reject(new Error('no session provided'))
          }
        }
      )
    })

    return details
  }
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

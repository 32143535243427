import { styled, TextField, InputAdornment, Button } from '@mui/material'

export const SearchTextInput = styled(TextField)({
  width: '100%',
  maxWidth: '45rem',
  borderRadius: 8,
  // add Mobile condition
})

export const SearchTextFieldIcon = styled(InputAdornment)({
  marginRight: 20,
})

export const SearchButton = styled(Button)({
  padding: '1rem 2.5rem',
})

import { Tooltip } from '@mui/material'
import React from 'react'

import {
  SimplifiedEmailStatus,
  EmailActionStatus,
} from '../../client/origination-client'
import { Email, EmailStatusIcon, EmailStatusBox } from './styles'

type EmailStatusType = {
  primaryRecipient: string
  primaryRecipientEmailStatus: EmailActionStatus['status'] | undefined
  emailTo?: Array<SimplifiedEmailStatus>
  emailCc?: Array<SimplifiedEmailStatus>
  emailBcc?: Array<SimplifiedEmailStatus>
}
export default function EmailStatusChip({
  primaryRecipient,
  primaryRecipientEmailStatus,
  emailTo,
  emailCc,
  emailBcc,
}: EmailStatusType): JSX.Element {
  const checkUndeliveredEmail = (
    emailArray: Array<SimplifiedEmailStatus> | undefined
  ): boolean => {
    if (emailArray === undefined) {
      return false
    }
    return emailArray.some(
      (simplifiedEmailStatus) => simplifiedEmailStatus.status !== 'delivered'
    )
  }

  const emailToWithoutPrimaryRecipient = emailTo
    ? emailTo?.filter((e: SimplifiedEmailStatus) => {
        e.email !== primaryRecipient
      })
    : undefined

  const recipientEmailStatus = () => {
    return (
      checkUndeliveredEmail(emailToWithoutPrimaryRecipient) ||
      checkUndeliveredEmail(emailCc) ||
      checkUndeliveredEmail(emailBcc)
    )
  }

  const getStatusIcon = (
    emailStatus: EmailActionStatus['status']
  ): JSX.Element => {
    switch (emailStatus) {
      case 'delivered':
        return (
          <Tooltip title="Delivered">
            <EmailStatusIcon
              src={'/Email_delivered_icon.svg'}
              alt="Delivered icon"
            />
          </Tooltip>
        )
      case 'failed':
        return (
          <Tooltip title="Not delivered">
            <EmailStatusIcon
              src={'/Email_failed_icon.svg'}
              alt="Not delivered icon"
            />
          </Tooltip>
        )
      default:
        return <></>
    }
  }

  const primaryEmailStatusIcon: JSX.Element | undefined =
    primaryRecipientEmailStatus
      ? getStatusIcon(primaryRecipientEmailStatus)
      : undefined

  const recipientEmailStatusIcon: JSX.Element | undefined =
    recipientEmailStatus() ? (
      <Tooltip title="Not delivered to other recipient(s)">
        <EmailStatusIcon
          src={'/Recipients_failed_icon.svg'}
          alt="Not delivered to other recipient(s) icon"
        />
      </Tooltip>
    ) : undefined

  return (
    <EmailStatusBox>
      <Email>{primaryRecipient}</Email>
      {primaryEmailStatusIcon}
      {recipientEmailStatusIcon}
    </EmailStatusBox>
  )
}
